import { useEffect, useState } from 'react';
import { runtimeConfig } from '../../config';
import { APIProvider } from '@vis.gl/react-google-maps';
import { PlaceAutocomplete } from './components/PlaceAutocomplete';



const addressKeys = {
    city: { type: ['locality', 'administrative_area_level_3', 'administrative_area_level_2'], name: 'long_name' },
    zip_code: { type: ['postal_code'], name: 'long_name' },
    country_name: { type: ['country'], name: 'long_name' },
    country_code: { type: ['country'], name: 'short_name' },
    state_code: { type: ['administrative_area_level_1'], name: 'short_name' }
};

const extractAddressComponents = (place) => {

    const addressObject = {
        country_name: '',
        city: '',
        zip_code: '',
        country_code: '',
        state_code: ''
    };

    if (place) {
        addressObject.raw_address = place.formatted_address;
        addressObject.address = place.formatted_address;

        const foundComponents = {
            city: false,
            zip_code: false,
            country_name: false,
            country_code: false,
            state_code: false
        };

        place.address_components?.forEach(component => {
            Object.keys(addressKeys).forEach(key => {
                if (!foundComponents[key] && addressKeys[key].type.find(type => component.types.includes(type))) {
                    const name = addressKeys[key].name;
                    addressObject[key] = name === 'short_name' ? component.short_name : component.long_name;
                    foundComponents[key] = true;
                }
            });
        });
    }

    return addressObject;
};

const AutoCompleteAddress = ({ onChange, onBlur, defaultValue, error, name, label, placeholder }) => {

    const [place, setPlace] = useState(null);

    const handlePlaceChanged = () => {
        const address = place ? extractAddressComponents(place) : undefined;
        onChange(address);
    };

    useEffect(() => {
        handlePlaceChanged();
    }, [place]);
    return  <>
        <APIProvider apiKey={runtimeConfig.googleMapsApiKey || ''} language="en">

            <PlaceAutocomplete
                onPlaceSelect={setPlace}
                onBlur={onBlur}
                error={error}
                name={name}
                defaultValue={defaultValue}
                label={label}
                placeholder={placeholder}
            />
        </APIProvider>
    </>
}



export default AutoCompleteAddress;
