import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Button from "../../../../../components/Button"
import { getAvailableNamespaces } from "../../../../../translations";

const useStyles = createUseStyles((theme) => ({
    root: {
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 10px 0px",
        borderRadius: "14px",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    },
    achievementsTitle: {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "28px",
        margin: "16px 0 16px 0",
    },
    imageContent: {
        width: "100%",
        paddingBottom: theme.spacing * 1.25,
        height: "100px",
        borderRadius: 4,
        background: "linear-gradient(135deg, #808080, #3d3d3d)",
        "& img": {
            width: "100%",
            borderRadius: 4,
            objectFit: "cover",
            maxHeight: "100px",
        },
    },
    infoContainer: {
        display: "grid",
        gap: "8px",
        gridTemplateColumns: '1fr 1fr',
        alignItems: "center",
        fontSize: '12px',
        whiteSpace: "nowrap",
    },
    infoBox: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing / 2,
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "#8649D1",
    },
    button: {
        width: "100%",
        maxWidth: "100%",
        display: "flex",
        border: "1px solid #8649D1 !important",
        color: "#8649D1 !important",
        "& span": {
            display: "flex",
            width: '100%',
            alignItems: "center",
            justifyContent: "center",
        },
    },
    infoTitle: {
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 600,
        color: '#000000',
        paddingBottom: theme.spacing * 1.25,
    }
}));

export const MorgisPackage = ({ content, onSubmit = () => { } }) => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    return (
        <div>
            <div>
                <div className={classes.achievementsTitle}>
                    {t('rookie_profile_page.choose_support_modal.morgis_package')}
                </div>
                <div className={classes.root}>
                    <div className={classes.imageContent}>
                       {!!content?.group?.url && <img src={content.group.url} />}
                    </div>
                    <div className={classes.infoTitle}>
                        {t('rookie_profile_page.choose_support_modal.one_time_payment')}
                    </div>
                    <div className={classes.infoContainer}>
                        <div className={classes.infoBox}>
                            <div className={classes.circle} />
                            {t('rookie_profile_page.choose_support_modal.advantages.chat')}
                        </div>
                        <div className={classes.infoBox}>
                            <div className={classes.circle} />
                            {t('rookie_profile_page.choose_support_modal.advantages.goals')}
                        </div>
                        <div className={classes.infoBox}>
                            <div className={classes.circle} />
                            {t('rookie_profile_page.choose_support_modal.advantages.exclusive_content')}
                        </div>
                    </div>
                    <Button
                        data-outlined
                        className={classes.button}
                        onClick={onSubmit}
                    >
                        {t('rookie_profile_page.choose_support_modal.all_packages')}
                    </Button>
                </div>
            </div>
        </div>
    )
}