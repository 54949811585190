import { useTranslation } from "react-i18next";
import { createUseStyles, useTheme } from "react-jss";
import Slider from "react-slick";
import Media from "../../components/Media";
import { getAvailableNamespaces } from "../../translations";
import morgisLogo from "../../assets/images/morgisLogo3x.png";
import {
  dataAccordingToPersonaForLeader,
  dataAccordingToPersonaForRookie,
  dataForMobile,
} from "./dataAccordingToPersona";
import { useRef } from "react";
import { Arrow } from "../../assets/icons/icons";
import cx from "classnames";
import { useViewportSize } from "../../hooks/useViewportSize";

const useStyles = createUseStyles((theme) => ({
  slider: {
    width: "100%",
    alignSelf: "start",
    display: "flex",
    minWidth: "0px",
    justifyContent: "center",
    [theme.mUp]: {
      overflowY: "unset",
      marginBottom: "50px",
      marginTop: 48,
    },
    //Fix for React-Slick , without the slide width grow esponentially
    "& *": {
      minHeight: "0px",
      minWidth: "0px",
    },
    "& .slick-list": {
      minHeight: "300px",
    },
    "& .slick-dots": {
      position: "relative",
      bottom: 0,
      [theme.mUp]: {
        width: "fit-content",
        padding: 0,
        display: "flex",
      },
    },
    "& .slick-dots li": {
      width: "auto",
    },
    "& .slick-dots li button": {
      width: "auto",
      padding: 3,
    },
  },
  slide: {
    textAlign: "center",
    display: "flex!important",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
    whiteSpace: "pre-line",
    gap: 24,
    [theme.mUp]: {
      flexDirection: ({ role }) => (role === "leader" ? "row-reverse" : "row"),
      height: "420px",
    },
  },
  slideImage: {
    position: "relative",
    marginLeft: 1,
    "& > img": {
      objectFit: "contain",
      width: "100%",
      height: "290px",
      ["@media only screen and (max-height: 610px)"]: {
        height: "200px",
      },
      [theme.mUp]: {
        width: "auto",
        height: "415px",
      },
    },
    [theme.mUp]: {
      width: "50%",
      display: "flex",
      justifyContent: "end",
    },
  },
  slideDescription: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 3,
    marginTop: "-23px",
    [theme.mUp]: {
      width: "50%",
      marginTop: "0",
      alignItems: "start",
    },
  },
  slideDescriptionTop: {
    zIndex: 3,
    marginBottom: "15px",
    [theme.mUp]: {
      width: "50%",
      alignItems: "start",
    },
  },
  logoBox: {
    display: "flex",
    justifyContent: "center",
    height: "71px",
    width: "156px",
    background: theme.colors.white,
    borderRadius: "18px",
    [theme.mUp]: {
      width: "auto",
    },
  },
  logo: {
    width: "105px",
    padding: "13px",
  },
  text: {
    width: "100%",
    margin: "0 auto",
    textAlign: "center",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
    letterSpacing: "-0.05em",
    color: theme.colors.darkPurple,
    height: "100%",
    maxWidth: "250px",
    [theme.mUp]: {
      fontWeight: 700,
      fontSize: "44px",
      lineHeight: "53px",
      textAlign: "start",
      paddingRight: "20px",
      maxWidth: "100%"
    },
  },
  rightArrow: {
    display: "none",
    cursor: "pointer",
    marginLeft: "20px",
    [theme.mUp]: {
      display: "block",
    },
  },
  leftArrow: {
    display: "none",
    cursor: "pointer",
    marginRight: "7px",
    transform: "scaleX(-1)",
    [theme.mUp]: {
      display: "block",
    },
  },
}));

const AnonymousTourSlider = ({ persona, role }) => {
  const [t, i18n] = useTranslation(getAvailableNamespaces(), {
    useSuspense: false,
  });
  const {breakPoints} = useTheme()
  const {vw} = useViewportSize()
  const screenWidth = vw * 100
  const isMobile = screenWidth < breakPoints.s

  const dataAccordingToPersona = !isMobile ?
   ( role === "leader"
      ? dataAccordingToPersonaForLeader
      : dataAccordingToPersonaForRookie) : dataForMobile;
  const classes = useStyles({ role });
  const sliderRef = useRef();
  const lang = i18n.language.toUpperCase().split("_")[0];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    adaptiveHeight: true,
    appendDots: (dots) => (
      <div>
        <span
          className={cx(classes.arrow, classes.leftArrow)}
          onClick={() => {
            sliderRef?.current?.slickPrev();
          }}
        >
          <Arrow />
        </span>
        <ul style={{ margin: "0px", padding: 0 }}> {dots} </ul>
        <span
          className={classes.rightArrow}
          onClick={() => {
            sliderRef?.current?.slickNext();
          }}
        >
          <Arrow />
        </span>
      </div>
    ),
  }

  return (
    <div className={classes.slider}>
      <Slider ref={sliderRef} {...settings}>
        {dataAccordingToPersona[persona]?.map((image, index) => {
          return (
            <div className={classes.slide} key={Date.now() + index}>
              {role === "rookie" && (
                <div className={classes.slideDescriptionTop}>
                  <p className={classes.text}>
                    {t([
                      `anonymous_tour.persona_text.rookie.${persona}.text_${index}`,
                      `anonymous_tour.persona_text.rookie.0.text_${index}`,
                    ])}
                  </p>
                </div>
              )}
              <div className={classes.slideImage}>
                <img src={image[lang] ?? image.PT} />
              </div>
              {role === "leader" && (
                <div className={classes.slideDescription}>
                  <div className={classes.logoBox}>
                    <Media
                      variant={"contain"}
                      image={morgisLogo}
                      className={classes.logo}
                    />
                  </div>
                  <p className={classes.text}>
                    {t([
                      `anonymous_tour.persona_text.leader.${persona}.text_${index}`,
                      `anonymous_tour.persona_text.leader.0.text_${index}`,
                    ])}
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default AnonymousTourSlider;
