import axios from "../utilities/axios";

export const httpFetchRookie = username => {
    return axios.get(`public/rookies/${username}`);
}

export const httpFetchRookieThankYous = userId => {
    return axios.get(`/rookie/${userId}/thanks-yous`);
}

export const httpFetchGoalSupportPackages = () => {
    return axios.get('/thanks-yous/amounts')
}