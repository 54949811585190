import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAvailableNamespaces } from '../../translations';

function SessionExpireTimer({ minutes, onComplete, isReInitTimer = false }) {
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const [timeLeft, setTimeLeft] = useState(minutes * 60);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(prevTimeLeft => {
                if (prevTimeLeft < 1) {
                    if (onComplete) onComplete();
                    if (isReInitTimer) return minutes * 60;
                    clearInterval(interval);
                    return 0;
                }
                return prevTimeLeft - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [minutes, onComplete]);

    const formatTime = (seconds) => {
        const m = Math.floor(seconds / 60);
        const s = seconds % 60;
        return `${t('morgis_iframe_modal.session_will_expire')} ${m}:${s < 10 ? '0' : ''}${s}`;
    };

    return <span>{formatTime(timeLeft)}</span>;
}

export default SessionExpireTimer;