import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    expandableText: ({ isExpanded, shouldTruncate, maxLines }) => ({
        display: isExpanded || !shouldTruncate ? 'block' : '-webkit-box',
        WebkitLineClamp: shouldTruncate && !isExpanded ? maxLines : 'unset',
        WebkitBoxOrient: 'vertical',
        overflow: shouldTruncate && !isExpanded ? 'hidden' : 'visible',
    }),
    trigger: {
        marginTop: 8,
        cursor: 'pointer',
        background: 'none',
        border: 'none',
        textDecoration: 'underline',
        padding: 0
    },
});

const ExpandableText = ({ children, maxLines = 1 }) => {
    const textRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [shouldTruncate, setShouldTruncate] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles({ isExpanded, shouldTruncate, maxLines });

    useEffect(() => {
        if (textRef.current) {
            const styles = window.getComputedStyle(textRef.current);
            const computedLineHeight = parseFloat(styles.lineHeight);
            const elementHeight = textRef.current.getBoundingClientRect().height;
            const calculatedLines = Math.round(elementHeight / computedLineHeight);

            if (calculatedLines > maxLines) setShouldTruncate(true);
        }
    }, [children, maxLines]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div>
            <div ref={textRef} className={classes.expandableText}>
                {children}
            </div>
            {shouldTruncate && (
                <button className={classes.trigger} onClick={toggleExpand}>
                    {isExpanded ? t('common:show_less') : t('common:show_more')}
                </button>
            )}
        </div>
    );
};

export default ExpandableText;
