import React, {useEffect, useCallback, useState} from 'react';
import PropTypes from 'prop-types';

const Hidden = props => {
    const { children, up, down, between} = props;

    const checkIfHidden = useCallback(width => {
        if(up) {
            if(width > up) {
                return true;
            } else {
                return false;
            }
        } else if (down) {
            if(width < down) {
                return true;
            } else {
                return false;
            }
        } else if (between) {
            if(width > between[0] && width < between[1]) {
                return true;
            } else {
                return false;
            }
        }
    }, [up, down, between]);

    const [isHidden, setIsHidden] = useState(); // checkIfHidden(window.innerWidth)

    const handleResize = useCallback(() => {
        const width = window.innerWidth;

        const shouldBeHidden = checkIfHidden(width);

        if(shouldBeHidden && !isHidden) setIsHidden(true);
        if(!shouldBeHidden && isHidden) setIsHidden(false);

    }, [setIsHidden, isHidden, up, down, between, checkIfHidden]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    return !isHidden ? children : null;
};

Hidden.defaultProps = {
    up: null,
    down: null,
    between: null,
}

Hidden.propTypes = {
    up: PropTypes.number,
    down: PropTypes.number,
    between: PropTypes.arrayOf(PropTypes.number),
}

export default Hidden;
