import React, {useEffect} from 'react';
import { Switch } from 'react-router-dom';
import routes from "./routes";
import './index.css';

const App = () => {

  return (
      <Switch>
        {routes.map(({routeType: RouteComponent, ...rest}, i) => (
            <RouteComponent
                {...rest}
                key={i}
            />
        ))}
      </Switch>
  )
};

export default App;
