import pages from "./pages.json";
import validation from "./validation.json";
import common from "./common.json"

const translations = {
    pages,
    validation,
    common
};

export default translations;