import { createUseStyles } from "react-jss";
import cx from 'classnames'
import { MicromorgiIcon } from "../assets/icons/icons"
import { Trans, useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../translations";
import { goalCardDefoultContent, strokeColor } from "../utilities/constants/goalTypes";
import { calculatePercentage } from "../utilities/helpers";
import Media from "./Media";
import CircularProgress from "./CircularProgress";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTE_GOAL_PAGE } from "../utilities/constants";

const useStyles = createUseStyles(theme => ({
    goalCardContent: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridColumnGap: theme.spacing * 2,
        alignItems: 'center',
        backgroundColor: theme.colors.white,
        minHeight: '89px',
        borderRadius: 10,
        boxShadow: '0px 4px 10px 0px rgba(0,0,0,0.15)',
        width: '100%',
        padding: theme.spacing * 2,
        maxWidth: '440px',
        cursor: 'pointer',
    },
    goalCardTitleWrapper: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridColumnGap: theme.spacing,
        alignItems: 'center',
    },
    goalCardTitle: {
        display: 'block',
        marginBottom: '5px',
        fontSize: '12px',
        fontWeight: 700,
    },
    goalCardTypeIcon: {
        '& > svg': {
            height: '16px',
            width: '16px',
        },
    },
    goalName: {
        fontSize: '14px',
        color: theme.colors.darkPurple,
        marginBottom: '5px',
    },
    children: {
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing * 2,
        gridTemplateColumns: '1fr auto',
    },
    percentageClassName: {
        zIndex: '-1',
        left: '1px',
        bottom: '1px',
        fontWeight: 700,
        height: '95%',
        width: '95%',
        borderRadius: '50%',
        border: `1px solid ${theme.colors.lightGrey}`,
    },
    goalInfo: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '0fr 0fr 0fr auto',
        gridColumnGap: theme.spacing,
    },
    micromorgiIcon: {
        height: '14px',
        width: '14px',
    },
    micromorgiAmount: {
        fontWeight: 700,
        fontSize: '10px',
    },
    timerClassName: {
        display: 'flex',
        fontSize: '10px',
        color: theme.colors.primary,
    },
    progressClassName: {
        '&:after': {
            content: '""',
            position: 'relative',
            display: 'block',
            width: '2px',
            height: '6px',
            background: '#20BF55',
            borderRadius: '1px',
            top: '-100%',
            left: '50%',
        },
    },
    percentageZero: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: 60,
        height: 60,
        borderRadius: '50%',
        border: `1px solid rgb(0,0,0, 0.2)`,
        fontWeight: 700,
    },
    forWhomSection: {
        marginTop: '8px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '7px',
    },
    forWhomSectionLabel: {
        padding: '5px',
        borderRadius: '7px',
        color: theme.colors.white,
        background: theme.colors.purpleGradient,
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '15px',
        whiteSpace: 'nowrap',
        maxWidth: '150px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    goalCardImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 57,
        height: 57,
        borderRadius: 10,
        border: `1px solid ${theme.colors.lightGrey}`,
        backgroundSize: 'cover',
    },
    goalCardVideo: {
        objectFit: 'cover',
    },
}))

const GoalCard = ({goal, onClick}) => {
    const [t] = useTranslation(getAvailableNamespaces(), {useSuspense: false});
    const classes = useStyles();
    const history = useHistory()

    const {
        name,
        target_amount,
        type_id,
        donations_count,
        is_for_others,
        radiobox,
        media,
        status,
        slug,
        id,
    } = goal

    const goalSize = type_id
    const percentage = calculatePercentage(donations_count, target_amount)
    const goalMedia = !!media && !!media.length ? media[0] : ''

    const handleGoalCardClick = () => {
        history.push(ROUTE_GOAL_PAGE.replace(':slug', `${slug}-${id}`))
    };

    return (
        <div className={classes.goalCardContent} onClick={handleGoalCardClick}>
            <div>
                {!!goalMedia && (
                    goalMedia?.type === 'image' ? (
                        <Media
                            image={goalMedia?.url}
                            className={classes.goalCardImage}
                        />
                    ) : (
                        <video
                            preload="metadata"
                            className={cx(
                                classes.goalCardImage,
                                classes.goalCardVideo
                            )}
                        >
                            <source src={`${goalMedia?.url}#t=0.001`} />
                        </video>
                    )
                )}
            </div>
            <div className={classes.children}>
                <div>
                    <div className={classes.goalCardTitleWrapper}>
                        <span className={classes.goalCardTypeIcon}>
                            {goalCardDefoultContent[goalSize].icon}
                        </span>
                        <span className={classes.goalCardTitle}>
                            {
                                <Trans
                                    t={t}
                                    i18nKey={'goal_card.goal_type'}
                                    values={{
                                        context:
                                            goalCardDefoultContent[goalSize]
                                                .type,
                                    }}
                                />
                            }
                        </span>
                    </div>
                    <div className={classes.goalName}>{name}</div>
                    <div className={classes.goalInfo}>
                        <MicromorgiIcon
                            className={classes.micromorgiIcon}
                        />
                        <span className={classes.micromorgiAmount}>
                            {+target_amount}
                        </span>
                    </div>
                    <div className={classes.forWhomSection}>
                        <span className={classes.forWhomSectionLabel}>
                            {is_for_others
                                ? t('goal_page.for_whom_section.others')
                                : t('goal_page.for_whom_section.myself')}
                        </span>{' '}
                        {is_for_others && (
                            <span className={classes.forWhomSectionLabel}>
                                {radiobox}
                            </span>
                        )}
                    </div>
                </div>
                {percentage === 0 ? (
                    <span className={classes.percentageZero}>
                        <span>{percentage}%</span>
                    </span>
                ) : (
                    <CircularProgress
                        percentage={percentage > 100 ? 100 : percentage}
                        className={
                            percentage < 75 &&
                            classes.progressClassName
                        }
                        strokeColor={strokeColor[status]}
                        percentageClassName={classes.percentageClassName}
                    />
                )}
            </div>
        </div>
    )
}

export default GoalCard