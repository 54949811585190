import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import cx from "classnames";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "relative",
  },
  select: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 13px",
    fontSize: "14px",
    lineHeight: 1.8,
    backgroundColor: "hsl(0, 0%, 100%)",
    border: "1px solid hsl(0, 0%, 80%)",
    borderRadius: "4px",
  },
  options: {
    position: "absolute",
    zIndex: 1033,
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow:
      "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
    marginBottom: "8px",
    marginTop: "8px",
    width: "100%",
    padding: "0 0 10px 13px",
    fontWeight: 700,
    color: "#00063D",
    maxHeight: "400px",
    overflowY: "auto",
  },
  arrowIcon: {
    width: 0,
    height: 0,
    borderLeft: "4px solid transparent",
    borderRight: "4px solid transparent",
    borderTop: "4px solid #00063D",
  },
  optionsItem: {},
}));

const Select = ({
  options,
  defaultValue = null,
  onChange,
  placeholder = "select item...",
  className,
}) => {
  const classes = useStyles({});
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);

  const onValueChangeHandle = (item) => {
    setSelectedValue(item);
    onChange(item.value);
    setIsOpen(false);
  };

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.select} onClick={() => setIsOpen(!isOpen)}>
        {selectedValue ? (
          <span className={classes.selectedVlue}>{selectedValue.name}</span>
        ) : (
          <span className={classes.placeholder}>{placeholder}</span>
        )}
        <span className={classes.arrowIcon}></span>
      </div>
      {isOpen && (
        <div className={classes.options}>
          {options.map((item) => {
            return (
              <p
                className={classes.optionsItem}
                onClick={() => onValueChangeHandle(item)}
              >
                {item.name}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Select;
