import axios from '../utilities/axios'

export const httpLoginFacebookUser = (data) => {
    const url = '/auth/signup/facebook'
    return axios.post(url, data)
}

export const httpLoginGoogleUser = (data) => {
    const url = '/auth/signup/google'
    return axios.post(url, data)
}

export const httpAttachFacebookUser = (data, userID) => {
    const url = `/auth/attach/${userID}/facebook`
    return axios.post(url, data)
}

export const httpAttachGoogleUser = (data, userID) => {
    const url = `/auth/attach/${userID}/google`
    return axios.post(url, data)
}