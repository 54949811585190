import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit'
import {httpLoginFacebookUser, httpLoginGoogleUser} from "../httpRequests/user.http";
import {KNOWN_COOKIES} from "../utilities/constants/user";
import Cookies from "js-cookie";

export const loginSocial = createAsyncThunk(
    'user/login',
    async (authData, { rejectWithValue }) => {
        try {
            const { data: userData } =
                authData?.type === 'facebook'
                    ? await httpLoginFacebookUser(authData?.authResponse)
                    : await httpLoginGoogleUser(authData?.authResponse)
            Cookies.set(KNOWN_COOKIES.authCookie, 'true', {
                domain: KNOWN_COOKIES.mainDomain,
            })
            return userData
        } catch (err) {
            return rejectWithValue({
                status: err?.response?.status,
                data: err?.response?.data,
            })
        }
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState: {
        persona: 0,
        usePersonaAsDefault: true // set to "false" to use A/B group strings as defaults when persona is 0
    },
    reducers: {
        setPersona: (state, action) => {
            state.persona = action.payload
        }
    }
})

const selectSelf = (state) => state.user;
export const selectPersona = createSelector(selectSelf, state => state.persona)
export const selectPersonaDefault = createSelector(selectSelf, state => state.usePersonaAsDefault)

export const {setPersona} = userSlice.actions
export default userSlice.reducer
