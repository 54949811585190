import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getAvailableNamespaces } from '../translations'
import { InputCheckIcon, InputWarningIcon } from '../assets/icons/icons'

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'inline-block',
        width: '100%',
        'grid-auto-flow': 'row',
        position: 'relative',
    },
    wrapper: {
        marginBottom: 8,
        width: ({ fullWidth }) => (fullWidth ? '100%' : undefined),
    },
    inputWrapper: {
        position: 'relative',
    },
    label: {
        ...theme.control.label,
        marginBottom: theme.spacing,
        display: 'inline-block',
    },
    icons: {
        ...theme.gridUtils.centered,
        gridTemplateColumns: 'auto auto',
        gridColumnGap: theme.spacing,
        position: 'absolute',
        top: '50%',
        right: theme.spacing * 2,
        transform: 'translateY(-50%)',
    },
    icon: {
        display: 'flex',
        '& > svg': {
            fill: '#394A64',
        },
    },
    statusIcon: {
        gridColumnStart: 2,
    },
    input: {
        ...theme.control.input,
        paddingRight: ({ touched }) =>
            touched ? theme.spacing * 6 : theme.spacing * 2,
    },
    inputError: theme.control.inputError,
    inputTouched: {
        paddingRight: theme.spacing * 6,
    },
    error: {
        color: theme.colors.red,
        fontWeight: 400,
    },
    hint: {
        color: theme.colors.darkPurpleMidOpacity,
        fontWeight: 500,
        marginTop: theme.spacing,
    },
}))

const InputText = forwardRef(function InputText(
    {
        className,
        inputClassName,
        label,
        hint,
        errors,
        touched = false,
        rootProps,
        icon,
        type = 'text',
        fullWidth = false,
        iconCallback,
        ...props
    },
    ref
) {
    const classes = useStyles({ errors: !!errors, fullWidth, touched })
    const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false })

    return (
        <div className={classes.wrapper}>
            <label
                data-control
                id={props.name}
                className={cx(classes.root, className)}
            >
                {!!label && <span className={classes.label}>{label}</span>}
                <div className={classes.inputWrapper}>
                    <input
                        type={type}
                        className={cx(
                            classes.input,
                            inputClassName,
                            !!errors && classes.inputError,
                            (touched || icon) && classes.inputTouched
                        )}
                        ref={ref}
                        {...props}
                        {...rootProps}
                    />
                    <span className={classes.icons}>
                        {icon && (
                            <span
                                className={classes.icon}
                                {...(iconCallback && { onClick: iconCallback })}
                            >
                                {icon}
                            </span>
                        )}
                        {!!errors ? (
                            <InputWarningIcon className={classes.statusIcon} />
                        ) : (
                            touched && (
                                <InputCheckIcon
                                    className={classes.statusIcon}
                                />
                            )
                        )}
                    </span>
                </div>
                {!errors && hint && (
                    <span className={classes.hint}>{t(hint)}</span>
                )}
                {!!errors && !props?.disabled && (
                    <small className={classes.error}>
                        {errors.type !== 'backend'
                            ? t(errors.message)
                            : errors.message}
                    </small>
                )}
            </label>
        </div>
    )
})

export default InputText

InputText.propTypes = {
    rootProps: PropTypes.any,
    className: PropTypes.any,
    label: PropTypes.string,
    type: PropTypes.oneOf(['email', 'password', 'text', 'number']),
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    isSmall: PropTypes.bool,
    fullWidth: PropTypes.bool,
}
