import React, {useState, useEffect, useRef} from 'react';
import {createUseStyles, useTheme} from 'react-jss';
import cx from "classnames";
import PropTypes from "prop-types";
import {ArrowDownIcon} from "../assets/icons/icons";
import {toReactElement} from "../utilities/utils";
import flattenChildren from "react-keyed-flatten-children";

const useStyles = createUseStyles(theme => ({
	root: {
		position: 'relative'
	},
	header: {
		cursor: 'pointer',
		'-webkit-tap-highlight-color': 'rgba(255, 255, 255, 0)',
		display: 'grid',
		gridTemplateColumns: ({collapsible}) => `${theme.spacing * 4}px 1fr ${theme.spacing * 6}px ${collapsible ? theme.spacing * 2 : 0}px`,
		gridTemplateRows: theme.spacing * 4,
		padding: [theme.spacing * 2, 0, theme.spacing * 2, theme.spacing * 2],
		marginBottom: 0,
		listStyle: 'none',
		alignItems: 'center',
		fontWeight: 700,
		gridColumnGap: theme.spacing / 2,
		borderLeft: `3px solid transparent`,
		[theme.mUp]: {
			padding: [theme.spacing * 2, 0, theme.spacing * 2, theme.spacing * 2],
			gridColumnGap: theme.spacing,
			gridTemplateColumns: ({collapsible}) => `${theme.spacing * 4}px 1fr ${theme.spacing * 6}px ${collapsible ? theme.spacing * 2 : 0}px`,
		}
	},
	prefix: {
		padding: [theme.spacing / 2, theme.spacing],
		fontWeight: 400
	},
	suffix: {
		padding: [theme.spacing / 2, theme.spacing],
		backgroundColor: theme.colors.primary,
		borderRadius: theme.spacing * 3,
		textAlign: 'center',
		fontWeight: 400,
		fontSize: 12,
		color: theme.colors.white
	},
	arrow: {
		...theme.flexUtils.centered,
		gridColumnStart: 4,
		[theme.mUp]: {
			paddingRight: '1px',
		},
		transform: ({open}) => open && 'rotate(-180deg)',
		transition:' all .25s ease-in',
	},
	list: {
		display: 'grid',
		overflow: ({collapsible, open}) => ((collapsible && open) || !collapsible) ? 'visible' : 'hidden',
		maxHeight: ({collapsible, open, listHeight}) => ((collapsible && open) || !collapsible) ? listHeight : '0',
		height: 'auto',
		opacity: ({collapsible, open}) => ((collapsible && open) || !collapsible) ? '1': '0',
		transition: 'all .15s ease-in-out',
		width: '100%',
		gridAutoColumns: '1fr',
		gridAutoFlow: 'row',
		alignItems: 'center',
		justifyContent: 'start',
		listStyle: 'none',
		padding: 0
	},
	item: {
		position: 'relative',
	},
	title: {
		gridColumnStart: 2
	},
	icon: {
		...theme.flexUtils.centered
	},
}));


const SubMenu = ({
					 className,
					 children,
					 icon = null,
					 suffix = null,
					 title = '',
					 collapsible = false,
					 defaultOpen = false,
					 menuOpen,
					 ...props
}) => {

	const theme = useTheme();
	const [open, setOpen] = useState(defaultOpen);
	const listRef = useRef();
	const listHeight = (listRef.current && listRef.current.scrollHeight) || 0;
	const classes = useStyles({theme, collapsible, open, listHeight});

	useEffect(() => {
		setOpen(false);
	}, [menuOpen])

	return (<>
			<span
				{...props}
				className={cx(classes.header, className)}
				onClick={() => setOpen(!open)}
			>
				{icon && <span aria-hidden="true" className={classes.icon}>{toReactElement(icon)}️</span>}
				<span className={classes.title}>{title}</span>
				{suffix && <span className={classes.suffix}>{suffix}</span>}
				{collapsible && <span className={classes.arrow}>
					<ArrowDownIcon/>
				</span>}
			</span>
			<ul className={classes.list} ref={listRef}>
				{flattenChildren(children).map((child, i) =>
					child && <li className={classes.item} key={i}>
						{child}
					</li>
				)}
			</ul>
		</>
	);
};

SubMenu.propTypes = {
	className: PropTypes.string,
	menuOpen: PropTypes.bool,
	suffix: PropTypes.any,
	icon: PropTypes.any,
	title: PropTypes.string,
	collapsible: PropTypes.bool,
	defaultOpen: PropTypes.bool
};

export default SubMenu;
