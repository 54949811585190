import React from 'react';
import { InputHelpText } from '@/components/ui/input-help-text/InputHelpText';
import { Label } from '@/components/ui/label/Label';
import AutoCompleteAddress from './AutoCompleteAddress';
import { Controller, useFormContext } from 'react-hook-form';

const AutoCompleteAddressController = ({ name, label, helpText, rules, innerFields }) => {
  const {
    control,
    formState: { errors, defaultValues },
    trigger,
    clearErrors
  } = useFormContext();

  const error = errors[name]?.message
    ? errors[name]
    : errors[name] && Object.values(errors[name])[0];
  const keyOfInnerError = innerFields?.find(fieldName => errors[fieldName]);
  const innerError = errors[keyOfInnerError];
  const defaultValue = defaultValues?.[name];

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={(field) => (
              <AutoCompleteAddress
                name={name}
                defaultValue={defaultValue}
                error={error?.message.message || innerError?.message}
                onChange={address => {
                    console.log(field.value)
                  if (address === field.value) return;
                  field.onChange(address);
                  clearErrors(innerFields);
                  trigger(name);
                }}
                onBlur={() => {
                  trigger(name);
                }}
              />
        )}
      />
    </>
  );
};

export default AutoCompleteAddressController;