import React, {useEffect, useRef} from 'react';
import PublicLayout from '../containers/PublicLayout.js';
import {Route} from 'react-router-dom';
import {toggleThemeMode} from "../store/settingSlice";
import {useDispatch} from "react-redux";
import Cookies from "js-cookie";
import {KNOWN_COOKIES} from "../utilities/constants/user";
import {getAppLink} from "../utilities/utils";
import {setRefreshCarousel} from "../store/carouselSlice";

const PublicRoute = ({isDarkTheme = false, title, meta, lockCarousel, ...rest}) => {
    const dispatch = useDispatch();
    const isDarkThemeRef = useRef();


    useEffect(
        () => {
            if (typeof window !== `undefined` && Cookies.get(KNOWN_COOKIES.authCookie)) {
                window.location.href = getAppLink(rest.location.pathname)
            }
            dispatch(setRefreshCarousel(!lockCarousel))
        },
        []
    )

    if (isDarkThemeRef.current !== isDarkTheme) {
        dispatch(toggleThemeMode({themeMode: isDarkTheme ? 'dark' : 'light'}));
        isDarkThemeRef.current = isDarkTheme;
    }

    return <PublicLayout title={title} meta={meta}>
        <Route {...rest} />
    </PublicLayout>
}

export default PublicRoute;
