import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Button from "../../../components/Button";
import Popover from "../../../components/Popover";
import { getAvailableNamespaces } from "../../../translations";
import PlansList from "./components/PlansList/PlansList";
import MorgisCheckoutModal, { paymentTypes } from "../MorgisCheckoutModal/MorgisCheckoutModal";

const useStyles = createUseStyles({
    modalRoot: {
        width: '392px',
    },
    bodyClassName: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 8px 8px',
        gap: '16px',
        margin: '0 auto'
    },

    description: {
        color: '#000',
        textAlign: 'center',
        fontSize: '15px',
        lineHeight: '140%' /* 21px */,
        display: 'flex',
        marginBottom: 8
    },
    submit: {

    },
    '@media (max-width: 768px)': {
        modalRoot: {
            width: '100%',
            maxHeight: '100vh',
        },
    }
})

const SubscriptionsPlansModal = ({ hostName = 'hostName1', onClose = () => { }, rookieId }) => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });
    const [selectedPlan, setSelectedPlan] = useState()
    const [showMorgisCheckoutModal, setShowMorgisCheckoutModal] = useState(false);

    const onSubmit = () => {
        setShowMorgisCheckoutModal(true)
    }

    return <>
        {!showMorgisCheckoutModal && <Popover
            title={t('configure_membership_plans_modal.title', { hostName })}
            withHeader
            onClose={onClose}
            className={classes.modalRoot}
            bodyClassName={classes.bodyClassName}
        >
            <span className={classes.description}>
                {t('configure_membership_plans_modal.description')}
            </span>
            <PlansList
                rookieId={rookieId}
                selectedPlan={selectedPlan}
                onSelectPlanHandler={(plan) => { setSelectedPlan(plan) }}
            />
            <Button
                disabled={!selectedPlan}
                className={classes.submit}
                onClick={onSubmit}
                data-primary
            >
                {t('configure_membership_plans_modal.submit')}
            </Button>
        </Popover>
        }

        {showMorgisCheckoutModal &&
            <MorgisCheckoutModal
                onClose={() => { setShowMorgisCheckoutModal(false) }}
                subscriptionPlan={selectedPlan}
                paymentType={paymentTypes.subscription}
                rookieId={rookieId}
                rookieName={hostName}
            />
        }
    </>


}
export default SubscriptionsPlansModal