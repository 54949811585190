import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import Slider from "react-slick";
import { CloseIcon } from "../assets/icons/icons";
import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";
import { getAvailableNamespaces } from "../translations";
import Social from "../assets/images/social.svg";
import Premium from "../assets/images/premium.svg";
import Chat from "../assets/images/chat.svg";
import Gift from "../assets/images/gift.svg";
import Button from "./Button";
import { getAppLink } from "../utilities/utils";
import {
  APP_ROUTE_SIGNUP_LEADER_SOCIAL,
  APP_ROUTE_SIGNUP_ROOKIE_EMAIL,
} from "../utilities/constants/appRouteNames";
import {
  PARAM_USER_GROUP,
  ROUTE_PRIVACY_POLICY,
  ROUTE_TOS_LEADER,
} from "../utilities/constants";
import { getUserGroup } from "../utilities/constants/user";
import { LinkWithQuery } from "./LinkWithQuery";

const useStyles = createUseStyles((theme) => ({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    zIndex: 1033,
  },
  wrapper: {
    width: "95%",
    height: "95%",
    backgroundColor: theme.colors.white,
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    overflow: "auto",
    overflowY: "hidden",
    zIndex: 1033,
    borderRadius: 10,
    display: "grid",
    gridTemplateRows: "auto 1fr 1fr",
    gridTemplateColumns: "1fr",
    maxHeight: "584px",
    [theme.mUp]: {
      maxWidth: 600,
      maxHeight: 700,
      gridTemplateRows: "auto 1fr auto",
      padding: [0, 16],
    },
  },
  cardHeader: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    fontWeight: 700,
    padding: 16,
    [theme.mUp]: {
      fontSize: 18,
    },
  },
  slider: {
    width: "100%",
    alignSelf: "start",
    display: "flex",
    minWidth: "0px",
    alignItems: "center",
    justifyContent: "center",
    //Fix for React-Slick , without the slide width grow esponentially
    "& *": {
      minHeight: "0px",
      minWidth: "0px",
    },
    "& .slick-list": {
      minHeight: "318px",
      [theme.mUp]: {
        minHeight: "400px",
      },
    },
    "& .dots": {
      height: 6,
      width: "100%",
      backgroundColor: "#CCCDD8",
      borderRadius: 4,
      position: "relative",
    },
    "& .slick-dots": {
      position: "relative",
      bottom: 0,
      "& li": {
        width: 33,
      },
    },
    "& .slick-active .dots": {
      backgroundColor: "#00063D",
    },
    [theme.mUp]: {
      alignSelf: "center",
      "& p": {
        fontSize: 16,
      },
    },
  },
  slide: {
    textAlign: "center",
    display: "flex!important",
    justifyContent: "center",
    padding: [0, 20],
    alignItems: "center",
    marginBottom: 10,
    whiteSpace: "pre-line",
    [theme.sUp]: {
      marginBottom: 50,
    },
    "& img": {
      width: 100,
      height: 100,
      margin: "28px auto 56px auto",
      [theme.sUp]: {
        margin: "48px auto",
      },
      [theme.mUp]: {
        width: 150,
        height: 150,
      },
    },
    [theme.mUp]: {
      padding: [0, 40],
    },
  },
  buttonSection: {
    padding: [0, 16],
    "& p": {
      textAlign: "center",
      marginBottom: 15,
      color: theme.colors.darkPurple,
      [theme.mUp]: {
        fontSize: 16,
        marginBottom: 25,
      },
    },
    "& p:nth-child(3)": {
      fontSize: 12,
      lineHeight: "16.8px",
      fontWeight: 400,
      color: theme.colors.darkPurpleMidOpacity,
      whiteSpace: "pre-wrap",
    },
  },
  cta: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    columnGap: 10,
    justifyItems: "center",
    "& a": {
      textDecoration: "none",
      width: "100%",
      maxWidth: 200,
    },
  },
  ctaMessage: {
    fontSize: 12,
  },
  closeIcon: {
    cursor: "pointer",
  },
}));

const AboutUsTour = ({ closeCallback }) => {
  const classes = useStyles();
  const [t] = useTranslation(getAvailableNamespaces(), {
    react: { useSuspense: false },
  });

  useEffect(() => {
    window.document.body.style["overflow-y"] = "hidden"; // lock body scroll
    return () => (window.document.body.style["overflow-y"] = "auto"); // unlock body scroll;
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    adaptiveHeight: true,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => <div className="dots" />,
    arrows: false,
    prevArrow: null,
    nextArrow: null
  };

  const handleClose = () => {
    closeCallback && closeCallback();
  };

  const messages = [
    {
      message: "about_us.first_step_message",
      icon: Social,
    },
    {
      message: "about_us.second_step_message",
      icon: Chat,
    },
    {
      message: "about_us.third_step_message",
      icon: Gift,
    },
    {
      message: "about_us.fourth_step_message",
      icon: Premium,
    },
  ];

  return (
    <>
      <div className={classes.overlay} />
      <div className={classes.wrapper}>
        <div className={classes.cardHeader}>
          <span>{t("about_us.title")}</span>
          <span className={classes.closeIcon} onClick={handleClose}>
            <CloseIcon />
          </span>
        </div>
        <div className={classes.slider}>
          <Slider {...settings}>
            {messages.map(({ message, icon }, index) => (
              <div className={classes.slide} key={index}>
                <div>
                  <img src={icon} />
                  <p>
                    <Trans
                      t={t}
                      i18nKey={message}
                      components={{ bold: <strong /> }}
                    />
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className={classes.buttonSection}>
          <p className={classes.ctaMessage}>{t("about_us.cta_message")}</p>
          <div className={classes.cta}>
            <a
              href={getAppLink(APP_ROUTE_SIGNUP_ROOKIE_EMAIL, [
                {
                  name: PARAM_USER_GROUP,
                  value: getUserGroup(),
                },
              ])}
            >
              <Button width={"100%"} data-primary>
                {t("common:rookie")}
              </Button>
            </a>
            <a
              href={getAppLink(APP_ROUTE_SIGNUP_LEADER_SOCIAL, [
                {
                  name: PARAM_USER_GROUP,
                  value: getUserGroup(),
                },
              ])}
            >
              <Button width={"100%"} data-primary>
                {t("common:friend")}
              </Button>
            </a>
          </div>
          <p>
            <Trans
              t={t}
              i18nKey="about_us.disclaimer"
              components={{
                a1: <LinkWithQuery to={ROUTE_TOS_LEADER} />,
                a2: <LinkWithQuery to={ROUTE_PRIVACY_POLICY} />,
              }}
            />
          </p>
        </div>
      </div>
    </>
  );
};

AboutUsTour.propTypes = {
  avatarImage: PropTypes.string,
  coins: PropTypes.string,
  avatarSentence: PropTypes.string,
};

export default AboutUsTour;
