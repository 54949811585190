import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { MegaphoneIcon, MicromorgiIcon, SurprisesIcon } from '../../../../../assets/icons/icons';
import { getAvailableNamespaces } from '../../../../../translations';


// Mocking functions
const convertToRoman = (number) => `Roman-${number}`; // Mocked
const replaceThousands = (amount) => amount; // Mocked

const useStyles = createUseStyles(
    {
        planOfSubscription: ({ isHasOnClick }) => Object.assign({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px',
            position: 'relative',
            '&.isSelected': {
                borderRadius: '4px',
                borderColor: '#6a0dad',
                borderBottom: '0.5px solid',
                borderLeft: '0.5px solid',
                borderRight: '0.5px solid',
                background: 'rgba(134, 75, 210, 0.2)',
                transition: 'all 0.5s'
            },
            '&.disabled': {
                opacity: '0.5'
            }
        }, isHasOnClick ? {
            cursor: 'pointer',
            '&:hover': {
                borderRadius: '4px',
                border: 'solid #6a0dad 0.5px',
                background: 'rgba(134, 75, 210, 0.2)',
                transition: 'all 0.5s'
            },
        } : {}),
        planOfSubscriptionContent: {
            display: 'flex',
            width: '342px',
            height: '79px',
            alignItems: 'center'
        },
        planOfSubscriptionPartOfContent: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
            flexDirection: 'column',
            gap: '8px',
            textWrap: 'pretty',
            '& div': {
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                '& h1': {
                    fontSize: '1.5rem',
                    color: '#000000',
                    fontWeight: '700',
                    letterSpacing: '-1.3px',
                    margin: 'auto 0'
                },
                '& h2': {
                    fontSize: '14px',
                    color: '#00063D',
                    textAlign: 'center',
                    lineHeight: '140%',
                    fontWeight: '700',
                    margin: 0
                },
                '& p': {
                    fontSize: '14px',
                    color: '#00063D',
                    textAlign: 'center',
                    lineHeight: '140%',
                    margin: 0,
                    textAlign: 'left',
                },
                '& svg': {
                    fill: 'none !important'
                },
            },

            '& span': {
                fontSize: '0.875rem',
                color: '#00063D',
                textAlign: 'center',
                lineHeight: '140%',
                opacity: '0.5',
                padding: '0 8px'
            }
        },
        micromorgiContainer: {
            justifyContent: 'center'
        }
    }
);

const PlanOfSubscription = ({
    plan,
    isSelected = false,
    currencySymbol,
    onSelectPlanHandler,
    disabled = false,
    renderMegaphoneText = (plan, t) => (
        <> {t?.('configure_membership_plans_modal.set_group_name', { NUMBER: convertToRoman(+plan.id) })}</>
    )
}) => {
    const classes = useStyles({ isHasOnClick: Boolean(onSelectPlanHandler) });
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    return (
        <div
            className={`${classes.planOfSubscription} ${isSelected ? 'isSelected' : ''} ${disabled ? 'disabled' : ''}`}
            onClick={!disabled && onSelectPlanHandler ? onSelectPlanHandler : undefined}
        >
            <div className={classes.planOfSubscriptionContent}>
                <div className={classes.planOfSubscriptionPartOfContent}>
                    <div className={classes.micromorgiContainer}>
                        <MicromorgiIcon height={31} width={32} />
                        <h1>{plan.amount}</h1>
                    </div>
                    <span>
                        {t('configure_membership_plans_modal.plan_price_details', {
                            CURRENCY: currencySymbol,
                            AMOUNT: replaceThousands(plan.dollar_amount),
                            MORGI: replaceThousands(plan.amount)
                        })}
                    </span>
                </div>
                <div className={classes.planOfSubscriptionPartOfContent}>
                    <div>
                        <SurprisesIcon height={20} width={21} />
                        <h2>{plan.surprises_count}</h2>
                        <p>{t(`configure_membership_plans_modal.surprises`)}</p>
                    </div>
                    <div>
                        <MegaphoneIcon height={21} width={20} stroke={'#433C92'} />
                        <p>{plan.group.name}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlanOfSubscription;
