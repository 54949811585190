import React from 'react';
import Accordion, {AccordionItem} from '../../components/Accordion';
import {createUseStyles} from 'react-jss';
import {Trans, useTranslation} from "react-i18next";
import {getAvailableNamespaces} from "../../translations";
import {FAQ_TABLE} from "../../utilities/utils";
import Table from "../../components/Table";


const useStyles = createUseStyles(theme => ({
    wrapper: {
        padding: 15
    },
    heading: {
        textAlign: 'center',
        fontSize: 22,
        fontWeight: 400,
        margin: '15px 0px',
        lineHeight: '27px'
    },
    title: {
        ...theme.pageStyle.title
    },
    subtitle: {
        ...theme.pageStyle.subtitle
    },
    titleWrapper: {
        ...theme.pageStyle.titleWrapper,
        padding: [16, 0, 0, 0]
    },
    accordion: {
        margin: '15px 0px',
        '& div:nth-child(1)': {
            padding: 0,
            border: 0,
            margin: '30px 0px',
            '& h4': {
                fontSize: 14,
                fontWeight: 'normal',

            },
            '& button': {
                fontWeight: 'bold'
            },
            '& li': {
                marginBottom: 16
            }
        }
    },
    subAccordion: {
        margin: '15px 0px 15px 15px !important',
        '& div:nth-child(1)': {
            padding: 0,
            border: 0,
            margin: '30px 0px',
            '& h4': {
                fontSize: 14,
                fontWeight: 400,

            },
            '& button': {
                fontWeight: 400
            },
            '& li': {
                marginBottom: 16
            },
            '& p': {
                paddingRight: "15px"
            }
        }
    }
}));


const FaqRookie = () => {

    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {react: {useSuspense: false}});

    return (
        <>
            <div className={classes.titleWrapper}>
                <h2 className={classes.title}>
                    {t('static_pages.faq_rookie.1.title')}
                </h2>
            </div>

            <div className={classes.wrapper}>
                <Accordion isMain className={classes.accordion}>
                    <AccordionItem
                        label={t('static_pages.faq_rookie.1.text1')}
                        index="1"
                    >
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.1.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.1.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.1.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.1.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.1.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.1.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.1.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.1.section3.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.1.section3.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.1.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.1.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.1.section4.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.1.section4.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.1.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.1.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.1.section5.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.1.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.1.section6.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.1.section6.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.1.section6.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem label={t('static_pages.faq_rookie.2.title')}>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section1.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section1.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section3.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section4.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section4.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section5.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section6.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section7.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section7.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section7.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section7.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section8.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section8.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section8.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section9.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section9.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section9.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section10.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section10.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section10.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section11.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section11.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section11.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section12.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section12.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section12.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section13.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section13.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section13.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section14.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section14.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section14.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section14.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section15.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section15.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section15.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section15.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section16.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section16.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section16.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section17.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section17.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section17.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section17.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section18.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section18.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section18.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.2.section19.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section19.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.2.section19.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem label={t('static_pages.faq_rookie.3.title')}>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section6.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section7.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section7.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section8.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section8.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section9.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section9.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section10.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section10.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section11.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section11.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section12.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section12.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section13.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section13.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section14.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section14.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section15.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section15.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section16.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section16.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section17.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section17.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section18.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section18.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section19.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section19.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section20.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section20.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section21.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section21.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section22.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section22.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section23.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section23.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section24.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section24.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section25.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section25.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section26.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section26.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section27.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section27.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section28.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section28.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.3.section29.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.3.section29.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem
                        label={t('static_pages.faq_rookie.18.title')}
                    >
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.18.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.18.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.18.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.18.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.18.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.18.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.18.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.18.section3.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.18.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.18.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.18.section4.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem label={t('static_pages.faq_rookie.4.title')}>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.4.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.4.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.4.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.4.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section4.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.4.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.4.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section6.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.4.section7.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section7.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.4.section8.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section8.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section8.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.4.section9.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section9.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.4.section10.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section10.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.4.section11.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section11.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.4.section12.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section12.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section12.list1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section12.list2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section12.list3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section12.list4"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section12.list5"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section12.list6"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section12.list7"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.4.section12.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem label={t('static_pages.faq_rookie.5.title')}>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.5.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.5.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.5.section1.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.5.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.5.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.5.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.5.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.5.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.5.section3.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.5.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.5.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem label={t('static_pages.faq_rookie.6.title')}>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.6.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.6.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.6.section1.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.6.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.6.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.6.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.6.section2.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.6.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.6.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.6.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.6.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.6.section5.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.6.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.6.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem
                        label={t('static_pages.faq_rookie.17.title')}
                    >
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section1.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section2.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section3.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section3.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section4.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section4.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section5.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section6.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section6.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section6.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section7.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section7.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section8.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section8.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section8.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section8.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section9.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section9.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section9.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section9.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section10.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section10.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section11.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section11.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section11.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section12.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section12.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section12.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section12.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section12.text4"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section13.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section13.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section13.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section14.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section14.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section15.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section15.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section15.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section15.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section16.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section16.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section16.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section17.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section17.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section18.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section18.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section19.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section19.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>{' '}
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section19.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section20.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section20.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section20.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section21.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section21.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section22.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section22.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section22.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section22.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.17.section23.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.17.section23.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                {/* MONETIZATION IN MORGI */}
                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem
                        label={t('static_pages.faq_rookie.19.title')}
                    >
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section1.list1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section1.list2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section1.list3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section1.list4"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section1.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section3.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section3.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <Table tableContent={FAQ_TABLE.paymentPeriod} />
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section5.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section5.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section6.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section7.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section7.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section8.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section8.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section8.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section9.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section9.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section9.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section9.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section9.text4"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section10.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section10.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section10.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section10.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section11.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section11.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section11.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section11.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section11.text4"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section12.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section12.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section12.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section13.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section13.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section14.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section14.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section15.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section15.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.19.section16.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.19.section16.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                {/* TRANSACTION HISTORY */}
                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem label={t('static_pages.faq_rookie.9.title')}>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.9.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section1.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.9.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.9.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    1.
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section3.list1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    2.
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section3.list3"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    3.
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section3.list3"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    4.
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section3.list4"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    5.
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section3.list5"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    6.
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section3.list6"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    7.
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section3.list7"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    8.
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section3.list8"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    9.
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section3.list9"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.9.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.9.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>

                                <Table tableContent={FAQ_TABLE.paymentPeriod} />
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.9.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.9.section6.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                {/* PAYMENT DAY PROCESS */}
                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem label={t('static_pages.faq_rookie.7.title')}>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.7.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.7.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.7.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.7.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.7.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <Table tableContent={FAQ_TABLE.paymentPeriod} />
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.7.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.7.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                {/* TELEGRAM BOT */}
                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem
                        label={t('static_pages.faq_rookie.10.title')}
                    >
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.faq_rookie.10.text"
                                components={{ underline: <u /> }}
                            />
                        </p>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.10.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.10.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.10.section1.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.10.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.10.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.10.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.10.section2.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.10.section2.text4"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.10.section2.text5"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.10.section2.text6"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.10.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.10.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.10.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.10.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.10.section4.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.10.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.10.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>
                {/* MORGI ACCOUNT */}
                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem
                        label={t('static_pages.faq_rookie.12.title')}
                    >
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.faq_rookie.12.text"
                                components={{ strong: <strong /> }}
                            />
                        </p>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.12.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.12.section1.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.12.section1.text2"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.12.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.12.section2.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.12.section2.text2"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.12.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.12.section3.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.12.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.12.section4.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.12.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.12.section5.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.12.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.12.section6.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>
                {/* MESSAGE CENTER */}
                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem
                        label={t('static_pages.faq_rookie.13.title')}
                    >
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.13.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section1.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section1.text2"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section1.text3"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section1.text4"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.13.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section4.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section4.text2"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section4.text3"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section4.text4"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section4.text5"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section4.text6"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section4.text7"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.13.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section5.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section5.text2"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section5.text3"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section5.text4"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section5.text5"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section5.text6"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section5.text7"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section5.text8"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.13.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section6.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.13.section7.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section7.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section7.text2"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.13.section8.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section8.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.13.section9.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section9.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section9.text2"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.13.section10.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section10.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.13.section11.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section11.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section11.text2"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section11.text3"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.13.section12.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section12.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section12.text2"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.13.section13.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section13.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.13.section14.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section14.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.13.section15.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.13.section15.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>
                {/* TERMS */}
                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem
                        label={t('static_pages.faq_rookie.16.title')}
                    >
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.16.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.16.section1.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.16.section1.text2"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.16.section1.text3"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.16.section1.text4"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_rookie.16.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_rookie.16.section2.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>
            </div>
        </>
    )
}


export default FaqRookie;
