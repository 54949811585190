import React from 'react';
import { createUseStyles } from 'react-jss';
import {useLocation} from "react-router-dom";
import {
    ROUTE_A_WORD_FROM_PRESIDENT, ROUTE_COMMUNITY_GUILDINES, ROUTE_COOKIE_POLICY,
    ROUTE_OUR_MISSION, ROUTE_PRIVACY_POLICY, ROUTE_TOS_LEADER, ROUTE_TOS_ROOKIE,
    ROUTE_WHAT_IS_MORGI,
    ROUTE_WHO_WE_ARE,
} from "../../utilities/constants";
import {Trans, useTranslation} from "react-i18next";
import {getAvailableNamespaces} from "../../translations";
import quoteOpen from "../../assets/images/staticPages/quote-open.svg"
import quoteClose from "../../assets/images/staticPages/quote-close.svg"
import signature from "../../assets/images/staticPages/muly_litvak.svg"
import CookiePolicy from "./CookiePolicy";
import PrivacyPolicy from "./PrivacyPolicy";
// import ToS from "./ToS";
import ToSRookie from "./ToSRookie"
import CommunityGuidlines from "./CommunityGuildines";
import ToSLeader from "./ToSLeader";

export const useStyles = createUseStyles(theme => ({
    wrapper: {
        padding: [0, 16, 32, 16],
        '& p, & li': {
            color: theme.colors.darkPurpleMidOpacity,
            whiteSpace: 'pre-wrap',
            marginTop: theme.spacing * 3,
            [theme.mUp]: {
                fontSize: 16,
            }
        },
        '& table td, & table th': {
            verticalAlign: 'top',
            padding: theme.spacing,
            backgroundColor: '#F2F2F2'
        },
        '& table th': {
            backgroundColor: '#BFBFBF'
        }
    },
    title: {
        ...theme.pageStyle.title
    },
    subtitle: {
        ...theme.pageStyle.subtitle
    },
    titleWrapper: {
        ...theme.pageStyle.titleWrapper,
        padding: [16, 0 ,0,0]
    },
    image: {
        ...theme.flexUtils.centered,
        flexDirection: 'column',
        margin: 24,
        gap: 20,
        '& img': {
            maxWidth: 280
        }
    },
    content: {
        margin: [24, 0]
    }
}));



const WhatIsMorgi = () => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), { react: { useSuspense: false } });
    return <>

        <div className={classes.titleWrapper}>
            <h2 className={classes.title}>{t('static_pages.what_is_morgi.title')}</h2>
        </div>

        <div className={classes.wrapper}>
            <div className={'row'}>
                <div className={'col col-md-10 offset-md-1 col-lg-8 offset-lg-2'}>
                    <p>
                        <Trans t={t}
                               i18nKey="static_pages.what_is_morgi.first"
                               components={{ bold: <strong /> }}
                        />
                    </p>
                    <p>
                        <Trans t={t}
                               i18nKey="static_pages.what_is_morgi.second"
                               components={{ bold: <strong /> }}
                        />
                    </p>
                    <p>
                        <Trans t={t}
                               i18nKey="static_pages.what_is_morgi.third"
                               components={{ bold: <strong /> }}
                        />
                    </p>
                         <p>
                        <Trans t={t}
                               i18nKey="static_pages.what_is_morgi.fourth"
                               components={{ bold: <strong /> }}
                        />
                    </p>
                         <p>
                        <Trans t={t}
                               i18nKey="static_pages.what_is_morgi.fifth"
                               components={{ bold: <strong /> }}
                        />
                    </p>
                         <p>
                        <Trans t={t}
                               i18nKey="static_pages.what_is_morgi.sixth"
                               components={{ bold: <strong /> }}
                        />
                    </p>
                </div>
            </div>
        </div>
    </>
}

const AWordFromPresident = () => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), { react: { useSuspense: false } });
    return <>

        <div className={classes.titleWrapper}>
            <h2 className={classes.title}>{t('static_pages.a_word_from_president.title')}</h2>
        </div>

        <div className={classes.wrapper}>
            <div className={'row'}>
                <div className={'col col-md-10 offset-md-1 col-lg-8 offset-lg-2'}>
                    <div className={classes.image}>
                        <img src={quoteOpen}/>
                    </div>
                    <p>
                        <Trans t={t}
                               i18nKey="static_pages.a_word_from_president.first"
                               components={{ bold: <strong /> }}
                        />
                    </p>
                    <p>
                        <Trans t={t}
                               i18nKey="static_pages.a_word_from_president.second"
                               components={{ bold: <strong /> }}
                        />
                    </p>
                    <p>
                        <Trans t={t}
                               i18nKey="static_pages.a_word_from_president.third"
                               components={{ bold: <strong /> }}
                        />
                    </p>
                    <p>
                        <Trans t={t}
                               i18nKey="static_pages.a_word_from_president.fourth"
                               components={{ bold: <strong /> }}
                        />
                    </p>
                    <p>
                        <Trans t={t}
                               i18nKey="static_pages.a_word_from_president.fifth"
                               components={{ bold: <strong /> }}
                        />
                    </p>
                    <div className={classes.image}>
                        <img src={quoteClose}/>
                        <img src={signature}/>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const WhoWeAre = () => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), { react: { useSuspense: false } });
    return <>

        <div className={classes.titleWrapper}>
            <h2 className={classes.title}>{t('static_pages.who_are_we.title')}</h2>
        </div>

        <div className={classes.wrapper}>
            <div className={'row'}>
                <div className={'col col-md-10 offset-md-1 col-lg-8 offset-lg-2'}>
                    <p>
                        <Trans t={t}
                               i18nKey="static_pages.who_are_we.first"
                               components={{ bold: <strong /> }}
                        />
                    </p>
                </div>
            </div>
        </div>
    </>
}

const OurMission = () => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), { react: { useSuspense: false } });
    return <>

        <div className={classes.titleWrapper}>
            <h2 className={classes.title}>{t('static_pages.our_mission.title')}</h2>
        </div>

        <div className={classes.wrapper}>
            <div className={'row'}>
                <div className={'col col-md-10 offset-md-1 col-lg-8 offset-lg-2'}>
                    <p>
                        <Trans t={t}
                               i18nKey="static_pages.our_mission.first"
                               components={{ bold: <strong /> }}
                        />
                    </p>
                    <p>
                        <Trans t={t}
                               i18nKey="static_pages.our_mission.second"
                               components={{ bold: <strong /> }}
                        />
                    </p>
                    <p>
                        <Trans t={t}
                               i18nKey="static_pages.our_mission.third"
                               components={{ bold: <strong /> }}
                        />
                    </p>
                </div>
            </div>
        </div>
    </>
}

const StaticPages = () => {

    const location = useLocation(); //location.pathname

    switch(location.pathname){
        case ROUTE_A_WORD_FROM_PRESIDENT:
            return <AWordFromPresident/>
        case ROUTE_OUR_MISSION:
            return <OurMission/>
        case ROUTE_WHAT_IS_MORGI:
            return <WhatIsMorgi/>
        case ROUTE_WHO_WE_ARE:
            return <WhoWeAre/>
        case ROUTE_COOKIE_POLICY:
            return <CookiePolicy/>
        case ROUTE_TOS_ROOKIE:
            return <ToSRookie/>
        case ROUTE_TOS_LEADER:
            return <ToSLeader/>
        case ROUTE_PRIVACY_POLICY:
            return <PrivacyPolicy/>
        case ROUTE_COMMUNITY_GUILDINES:
            return <CommunityGuidlines/>
        default:
            break;
    }
}


export default StaticPages;
