import {create} from 'jss';
import preset from 'jss-preset-default';
import rtl from 'jss-rtl';
import {DIRECTIONS} from "./constants";

const JssInstance = (function () {
    let instance;
    let currentLngDir;

    function createInstance(lngDir = 'ltr') {
        currentLngDir = lngDir;
        return lngDir === DIRECTIONS.RTL ? create({ plugins: [...preset().plugins, rtl()] }) : create(preset());
    }

    return {
        getInstance: function (lngDir) {
            if (!instance || currentLngDir !== lngDir) {
                instance = createInstance(lngDir);
            }
            return instance;
        }
    };
})();

export default JssInstance;
