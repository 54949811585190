import React, {useMemo} from 'react';
import {createUseStyles, useTheme} from "react-jss";
import {useTranslation, Trans} from "react-i18next";
import {getAvailableNamespaces} from "../../translations";
import Popover from "../../components/Popover";
import Button from "../../components/Button";
import Media from "../../components/Media";
import {getAppLink, redirectToExternalLink} from "../../utilities/utils";
import {APP_ROUTE_SIGNUP_LEADER_SOCIAL} from "../../utilities/constants/appRouteNames";
import {PARAM_ROOKIE_PROFILE, PARAM_USER_GROUP} from "../../utilities/constants";
import {getUserGroup} from "../../utilities/constants/user";
import cx from "classnames";
import {selectThemeMode} from "../../store/settingSlice";
import {useSelector} from "react-redux";
import Hidden from "../../components/Hidden";

const useStyles = createUseStyles(theme => ({
    root: {
        [theme.l] : {
        maxWidth: 344,
        },
        [theme.xlUp]: {
            height: '80%',
            width: '70%',
            maxWidth: 1140,
            maxHeight: 756
        },
           [theme.xxlUp]: {
            height: '70%',
            width: '70%',
            maxWidth: 1140
        }
    },
    body: {
        display: 'grid',
        padding: [4, 48, 64, 48],
        '& p': {
            paddingBottom: '16px'
        },
        [theme.s]: {
            justifyItems: 'center',
        },
        [theme.sUp]: {
            fontSize: 16,
        },
        [theme.lUp]: {
            padding: [0, 40, 56, 40],
            gridTemplateColumns: '1fr 1fr',
            gridColumnGap: '15%'
        },
    },
    avatar: {
        display: 'grid',
        width: '100%',
        borderRadius: 12,
        justifyItems: 'center',
        height: '298px',
        order: 2,
        [theme.lUp]: {
            borderRadius: 24,
            height: '100%',
            order: 1
        }
    },
    giftButton: {
        whiteSpace: 'nowrap',
        alignSelf: 'end',
        marginBottom: -25,
        [theme.lUp]: {
            '& button': {
                fontSize: 24,
                maxWidth: 'unset',
                maxHeight: 64
            }
        }
    },
    title: {
        fontSize: 26,
        fontWeight: 700,
        letterSpacing: '-0.05em',
        color: ({themeMode}) => themeMode === 'dark' ? theme.colors.white : theme.colors.darkPurple,
        textAlign: 'center',
        marginTop: 0,
        whiteSpace: 'pre-wrap',
        lineHeight: 1.2,
        '& span': {
            ...theme.textGradients.primary
        },
        [theme.sUp]: {
            textAlign: 'center',
            fontSize: 34,
        },
        [theme.lUp]: {
            textAlign: 'left',
            fontSize: 64,
            maxWidth: 280,
            marginBottom: 24
        },
    },
    description: {
        fontSize: 14,
        fontWeight: 400,
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
        marginBottom: 14,
        marginTop: 15,
        [theme.lUp]: {
            fontSize: 32,
            textAlign: 'left',
            maxWidth: 280
        }
    },
    button: {
        marginBottom: theme.spacing * 2,
    },
    terms: {
        fontSize: 12,
        fontWeight: 400,
        color: theme.colors.grey,
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
        '& a': {
            color: 'inherit'
        }
    },
    sideButtonLeft: {
        justifySelf: "end"
    },
    sideButtonRight: {
        justifySelf: "start"
    },
    rookieCardAvatar: {
        minHeight: 'inherit',
        borderRadius: 10,
        display: 'grid',
        gridTemplateRows: '0fr 1fr 0fr',
        justifyItems: 'center',
        marginBottom: 25,
        [theme.sUp]: {
            marginBottom: 0,
        }
    },
    rookieCard: {
        position: 'relative',
        width: '100%',
        [theme.sUp]: {
            '& $rookieCardButton': {
                display: 'none'
            }
        },
        [theme.xlUp]: {
            maxWidth: "700px",
            minHeight: "700px"
        }
    },
    content: {
        order: 1,
        '& h2': {
            textAlign: 'center',
        },
        [theme.lUp]:{
            overflow: 'auto',
            order: 2,
            '& h2': {
                textAlign: 'left',
                marginBottom: 24
            },
        }
    }
}));

const SendingMessageModal = ({onClose, avatar, name, rookieUsername, rookieGender, ctaText}) => {
    const [t] = useTranslation(getAvailableNamespaces(), {react: {useSuspense: false}});
    const themeMode = useSelector(selectThemeMode);
    const classes = useStyles({themeMode});
    const theme = useTheme();

    const gender = useMemo(
        () => {
            // Please forgive me for this...
            switch (rookieGender){
                case 1:
                    return 'male';
                case 2:
                    return 'female';
                default:
                    return 'other';
            }
        },
        [rookieGender]
    )

    const handleGiftNowClick = () => {
        const searchParams = [{
            name: PARAM_USER_GROUP,
            value: getUserGroup()
        }]
        if(rookieUsername) {
            searchParams.push({
                name: PARAM_ROOKIE_PROFILE,
                value: rookieUsername
            })
        }
        redirectToExternalLink(getAppLink(APP_ROUTE_SIGNUP_LEADER_SOCIAL, searchParams))
    }

    return (
        <Popover
            width={'90vw'}
            {...(window.innerWidth < 992 && {
                withHeader: true,
                title: t('sending_message.modal_title')
            })}
            className={classes.root}
            bodyClassName={classes.body}
            onClose={onClose}
        >
            <div className={classes.content}>
                <Hidden down={theme.breakPoints.l}>
                    <h2>{t('sending_message.modal_title')}</h2>
                </Hidden>
                <h1 className={classes.title}>
                    <Trans
                        t={t}
                        i18nKey={'sending_message.title'}
                        components={{span: <span/>, USER: name}}
                    />
                </h1>
                <div className={classes.description}>{t('sending_message.subtitle', {context: gender})}</div>
            </div>
            <Media image={avatar} className={classes.avatar}>
                <div className={cx(classes.giftButton)}>
                    <Button
                        data-primary
                        className={classes.sideButtonLeft}
                        onClick={() => handleGiftNowClick(rookieUsername)}
                    >
                        {ctaText}
                    </Button>
                </div>
            </Media>
        </Popover>
    );
};

export default SendingMessageModal;
