import {createSelector, createSlice} from '@reduxjs/toolkit'

const settingSlice = createSlice({
    name: 'setting',
    initialState: {
        themeMode: 'light',
        isCustomerlyVisible: false
    },
    reducers: {
        toggleThemeMode: (state, action) => {
            state.themeMode = action.payload.themeMode;
        },
        toggleCustomerlyVisibility: (state, action) => {
            state.isCustomerlyVisible = !state.isCustomerlyVisible;
        }
    }
})

const selectSelf = (state) => state.setting;
export const selectThemeMode = createSelector(selectSelf, state => state.themeMode);
export const selectCustomerlyVisible = createSelector(selectSelf, state => state.isCustomerlyVisible);

export default settingSlice.reducer
export const { toggleThemeMode, toggleCustomerlyVisibility } = settingSlice.actions
