import {Trans, useTranslation} from 'react-i18next'
import {getAvailableNamespaces} from '../../translations'
import React from 'react'
import {useStyles} from './StaticPages'
import {
    ROUTE_COMMUNITY_GUILDINES, ROUTE_COOKIE_POLICY,
    ROUTE_HOME,
    ROUTE_PRIVACY_POLICY,
} from '../../utilities/constants'
import {Link} from 'react-router-dom'
import {runtimeConfig} from "../../config";

const ToSRookie = () => {
    const classes = useStyles()
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: {useSuspense: false},
    })

    return (
        <>
            <div className={classes.titleWrapper}>
                <h2 className={classes.title}>
                    {t('static_pages.tos_rookie.title')}
                </h2>
            </div>

            <div className={classes.wrapper}>
                <div className={'row'}>
                    <div
                        className={
                            'col col-md-10 offset-md-1 col-lg-8 offset-lg-2'
                        }
                    >
                        <div className={classes.content}>
                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.1.text"
                                    components={{
                                        strong: <strong />,
                                        a: (
                                            <a
                                                target="_blank"
                                                href={`${ROUTE_HOME}`}
                                            />
                                        ),
                                        a1: (
                                            <a
                                                target="_blank"
                                                href={`${ROUTE_PRIVACY_POLICY}`}
                                            />
                                        ),
                                        a2: (
                                            <a
                                                target="_blank"
                                                href={`${ROUTE_COOKIE_POLICY}`}
                                            />
                                        ),
                                    }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-2.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-2.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>
                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-2.list.1"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-2.list.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-2.list.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-2.list.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-2.list.5"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-2.list.6"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-2.list.7"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-2.list.8"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>
                            <p>
                    <Trans t={t} i18nKey="static_pages.tos_rookie.2-2.text2" components={{strong: <strong />}} />
                </p>
                <p>
                    <Trans t={t} i18nKey="static_pages.tos_rookie.2-2.text3" components={{strong: <strong />}} />
                </p>
                <p>
                    <Trans t={t} i18nKey="static_pages.tos_rookie.2-2.text4" components={{strong: <strong />}} />
                </p>
                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-3.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-3.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>
                <p>
                    <Trans t={t} i18nKey="static_pages.tos_rookie.2-3.text2" components={{strong: <strong />}} />
                </p>
                <p>
                    <Trans t={t} i18nKey="static_pages.tos_rookie.2-3.text3" components={{strong: <strong />}} />
                </p>
                            {/* <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-3.list.1"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-3.list.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-3.list.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-3.list.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-3.list.5"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-3.list.6"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul> */}

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-4.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-4.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-4-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-4-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-4-2.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-4-2.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-4-3.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-4-3.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>
                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-5.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-5.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-5-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-5-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-5-2.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-5-2.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-5-3.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-5-3.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-5-4.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-5-4.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-5-5.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-5-5.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-5-6.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-5-6.text"
                                    components={{ 
                                        strong: <strong />,
                                        a1: (
                                            <a
                                                target="_blank"
                                                href={`https://support.giphy.com/hc/en-us`}
                                            />
                                        ),
                                        a2: (
                                            <a
                                                target="_blank"
                                                href={`https://support.giphy.com/hc/en-us/articles/360020027752-GIPHY-User-Terms-of-Service`}
                                            />
                                        ),
                                        
                                    }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-6.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-6.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-6-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-6-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-7.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-7-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-7-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>
                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-7-2.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-7-2.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>
                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.2-7-3.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.2-7-3.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.3.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.3.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.4.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.4.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.5.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>

                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.5.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>
                            
                            <p>
                    <strong>
                        <Trans t={t} i18nKey="static_pages.tos_rookie.6.title" components={{strong: <strong />}} />
                    </strong>
                </p>
                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.6-1.tittle"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.6-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            {/* <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.6-1.list.1"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.6-1.list.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.6-1.list.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.6-1.list.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul> */}

                            {/* <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.6-1.list.text2"
                                    components={{ strong: <strong /> }}
                                />
                            </p> */}

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.7.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.7-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.7-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.7-2.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.7-2.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.7-3.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.7-3.text"
                                    components={{
                                        strong: <strong />,
                                        a: (
                                            <a
                                                target="_blank"
                                                href="mailto:DMCA@morgis.com"
                                            />
                                        ),
                                    }}
                                />
                            </p>

                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.7-3.list.1"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.7-3.list.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.7-3.list.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.7-3.list.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.7-3.list.5"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.7-3.list.6"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>

                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.7-3.text2"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.7-3.list2.1"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.7-3.list2.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.7-3.list2.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.7-3.list2.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.7-4.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.7-4.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.8.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.8-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.8-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.8-2.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.8-2.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.9.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.9.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.10.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.10.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>
                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.11.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.11.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.11.list.1"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.11.list.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.11.list.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.11.list.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.11.list.5"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.11.list.6"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.11.list.7"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.11.list.8"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.11.list.9"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.11.list.10"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.11.list.11"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.11.list.12"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>

                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.11.text2"
                                    components={{
                                        strong: <strong />,
                                        a: (
                                            <a
                                                href={`mailto:compliance@morgis.com`}
                                            />
                                        ),
                                    }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.12.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>

                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.12.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.13.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.13-1.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>

                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.13-1.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.13-1.list.1"
                                        components={{
                                            strong: <strong />,
                                            a: (
                                                <Link
                                                    to={
                                                        ROUTE_COMMUNITY_GUILDINES
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.13-1.list.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.13-1.list.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.13-1.list.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.13-1.list.5"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>

                            <p>
                    <Trans t={t} i18nKey="static_pages.tos_rookie.13-1.text2" components={{strong: <strong />}} />
                </p>

                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.13-1.list2.1"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.13-1.list2.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.13-1.list2.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.13-1.list2.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.13-1.list2.5"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.13-1.list2.6"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.14.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>

                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.14.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.15.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>

                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.15.list.1"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.15.list.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.15.list.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.15.list.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.16.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.16.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.16.text2"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.17.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.17.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.18.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.18.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.19.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>

                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.19.text"
                                    components={{ strong: <strong /> }}
                                />
                            </p>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.20.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <ul>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.20.list.1"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.20.list.2"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.20.list.3"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.20.list.4"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.20.list.5"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.20.list.6"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                                <li>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.20.list.7"
                                        components={{ strong: <strong /> }}
                                    />
                                </li>
                            </ul>

                            <p>
                                <strong>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.tos_rookie.21.title"
                                        components={{ strong: <strong /> }}
                                    />
                                </strong>
                            </p>
                            <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos_rookie.21.text"
                                    components={{
                                        strong: <strong />,
                                        a: (
                                            <a
                                                href={`mailto:${runtimeConfig.customerCareEmail}`}
                                            />
                                        ),
                                    }}
                                />
                            </p>

                            {/* <p>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.tos.19.mail"
                                    components={{
                                        a: (
                                            <a
                                                href={`mailto:${runtimeConfig.customerCareEmail}`}
                                            />
                                        ),
                                    }}
                                />
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ToSRookie
