import {Trans, useTranslation} from 'react-i18next'
import {getAvailableNamespaces} from '../../translations'
import React from 'react'
import {useStyles} from './StaticPages'
import {ROUTE_COOKIE_POLICY, ROUTE_HOME} from '../../utilities/constants/routeNames'
import {Link} from 'react-router-dom'
import config from "../../config";

const PrivacyPolicy = () => {
    const classes = useStyles()
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: {useSuspense: false},
    })
    const morgiDPO = 'DPO@morgi.org'

    return (
        <>
            <div className={classes.titleWrapper}>
                <h2 className={classes.title}>
                    {t('static_pages.privacy_policy.0')}
                </h2>
            </div>

            <div className={classes.wrapper}>
                <div className={'row'}>
                    <div
                        className={
                            'col col-md-10 offset-md-1 col-lg-8 offset-lg-2'
                        }
                    >
<p>
                    <strong>
                        <Trans
                            t={t}
                            i18nKey="static_pages.privacy_policy.last_revised"
                            components={{
                                strong: <strong />
                            }}
                        />
                    </strong>
                </p>
                <p>
                    <strong>
                        <Trans
                            t={t}
                            i18nKey="static_pages.privacy_policy.1.title"
                            components={{
                                strong: <strong />
                            }}
                        />
                    </strong>
                </p>
                <p>
                    <Trans
                        t={t}
                        i18nKey="static_pages.privacy_policy.1.text"
                        components={{
                            strong: <strong />,
                            a:  <Link to={ROUTE_HOME}/>,
                            br: <br />
                        }}
                    />
                </p>
                <p>
                    <strong>
                        <Trans
                            t={t}
                            i18nKey="static_pages.privacy_policy.2.title"
                            components={{
                                strong: <strong />
                            }}
                        />
                    </strong>
                </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.privacy_policy.2.text"
                                components={{
                                    strong: <strong/>
                                }}
                            />
                        </p>

                        <ul>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.2.list.1"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.2.list.2"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.2.list.3"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.2.list.4"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.2.list.5"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.2.list.6"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.2.list.7"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.2.list.8"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.2.list.9"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                        </ul>

                        <p>
                    <strong>
                        <Trans
                            t={t}
                            i18nKey="static_pages.privacy_policy.3.title"
                            components={{
                                strong: <strong />
                            }}
                        />
                    </strong>
                </p>
                <p>
                    <Trans
                        t={t}
                        i18nKey="static_pages.privacy_policy.3.text"
                        components={{
                            strong: <strong />
                        }}
                    />
                </p>

                <p>
                    <strong>
                        <Trans
                            t={t}
                            i18nKey="static_pages.privacy_policy.4.title"
                            components={{
                                strong: <strong />
                            }}
                        />
                    </strong>
                </p>
                <p>
                    <Trans
                        t={t}
                        i18nKey="static_pages.privacy_policy.4.text"
                        components={{
                            strong: <strong />
                        }}
                    />
                </p>

                        <ul>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.1.purpose"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                    }}
                                />

                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.1.basis"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                    }}
                                />
                            </li>


                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.2.purpose"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.2.basis"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.3.purpose"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.3.basis"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.4.purpose"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.4.basis"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.5.purpose"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.5.basis"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.6.purpose"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.6.basis"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.7.purpose"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.7.basis"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.8.purpose"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.list.8.basis"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                        </ul>


                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.privacy_policy.4.text2"
                                components={{strong: <strong/>}}
                            />
                        </p>

                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.4.title2"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>

                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.privacy_policy.4.text3"
                                components={{strong: <strong/>}}
                            />
                        </p>

                        <p>
                    <strong>
                        <Trans
                            t={t}
                            i18nKey="static_pages.privacy_policy.5.title"
                            components={{
                                strong: <strong />,
                                a:  <a target="_blank" href={`mailto:${morgiDPO}`}/> 
                            }}
                        />
                    </strong>
                </p>

                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.privacy_policy.5.text"
                                components={{
                                    strong: <strong/>,
                                    a: (
                                        <a
                                            target="_blank"
                                            href="mailto:DPO@morgi.org"
                                        />
                                    ),
                                }}
                            />
                        </p>
                        <p>
                    <strong>
                        <Trans t={t} i18nKey="static_pages.privacy_policy.6.title" components={{strong: <strong />}} />
                    </strong>
                </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.privacy_policy.6.text"
                                components={{strong: <strong/>}}
                            />
                        </p>

                        {/*<table
                            border="1"
                            style={{
                                borderCollapse: 'collapse',
                                marginTop: 20,
                            }}
                        >
                            <thead>
                            <tr>
                                <th style={{textAlign: 'left'}}>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.purpose"
                                        components={{strong: <strong/>}}
                                    />
                                </th>
                                <th style={{textAlign: 'left'}}>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.basis"
                                        components={{strong: <strong/>}}
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.list.1.purpose"
                                        components={{strong: <strong/>}}
                                    />
                                </td>
                                <td>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.list.1.basis"
                                        components={{strong: <strong/>}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.list.2.purpose"
                                        components={{strong: <strong/>}}
                                    />
                                </td>
                                <td>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.list.2.basis"
                                        components={{strong: <strong/>}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.list.3.purpose"
                                        components={{strong: <strong/>}}
                                    />
                                </td>
                                <td>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.list.3.basis"
                                        components={{strong: <strong/>}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.list.4.purpose"
                                        components={{strong: <strong/>}}
                                    />
                                </td>
                                <td>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.list.4.basis"
                                        components={{strong: <strong/>}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.list.5.purpose"
                                        components={{strong: <strong/>}}
                                    />
                                </td>
                                <td>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.list.5.basis"
                                        components={{strong: <strong/>}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.list.6.purpose"
                                        components={{
                                            strong: <strong/>,
                                            a: (
                                                <Link
                                                    to={ROUTE_COOKIE_POLICY}
                                                />
                                            ),
                                        }}
                                    />
                                </td>
                                <td>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.list.6.basis"
                                        components={{strong: <strong/>}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.list.7.purpose"
                                        components={{strong: <strong/>}}
                                    />
                                </td>
                                <td>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.privacy_policy.5.list.7.basis"
                                        components={{strong: <strong/>}}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>*/}
                        {/* Marketing */}


                        <ul>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.6.list.1"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                    }}
                                />
                            </li>


                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.6.list.2"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.6.list.3"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.6.list.4"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.6.list.5"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.6.list.6"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.6.list.7"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                        </ul>

                        <p>
                    <strong>
                        <Trans t={t} i18nKey="static_pages.privacy_policy.7.title" components={{strong: <strong />}} />
                    </strong>
                </p>
                <p>
                    <Trans
                        t={t}
                        i18nKey="static_pages.privacy_policy.7.text"
                        components={{strong: <strong />}}
                    />
                </p>

                <p>
                    <strong>
                        <Trans t={t} i18nKey="static_pages.privacy_policy.8.title" components={{strong: <strong />}} />
                    </strong>
                </p>
                <p>
                    <Trans
                        t={t}
                        i18nKey="static_pages.privacy_policy.8.text"
                        components={{strong: <strong />}}
                    />
                </p>
                <p>
                    <strong>
                        <Trans t={t} i18nKey="static_pages.privacy_policy.9.title" components={{strong: <strong />}} />
                    </strong>
                </p>
                <p>
                    <Trans t={t} i18nKey="static_pages.privacy_policy.9.text" components={{strong: <strong />}} />
                </p>
                <p>
                    <strong>
                        <Trans t={t} i18nKey="static_pages.privacy_policy.10.title" components={{strong: <strong />}} />
                    </strong>
                </p>
                <p>
                    <Trans t={t} i18nKey="static_pages.privacy_policy.10.text" components={{strong: <strong />}} />
                </p>
                        <ul>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.10.list.1"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                    }}
                                />
                            </li>


                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.10.list.2"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.10.list.3"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.10.list.4"

                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.10.list.5"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.10.list.6"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.10.list.7"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.privacy_policy.10.list.text2"
                                    components={{
                                        strong: <strong/>,
                                        u: <u/>,
                                        a: <Link to={ROUTE_COOKIE_POLICY}/>,
                                    }}
                                />
                            </li>
                        </ul>

                        <p>
                    <strong>
                        <Trans t={t} i18nKey="static_pages.privacy_policy.11.title" components={{strong: <strong />}} />
                    </strong>
                </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.privacy_policy.11.text"
                                components={{strong: <strong/>}}
                            />
                        </p>

                        <p>
                    <strong>
                        <Trans t={t} i18nKey="static_pages.privacy_policy.12.title" components={{strong: <strong />}} />
                    </strong>
                </p>

                <p>
                    <Trans t={t} i18nKey="static_pages.privacy_policy.12.text" components={{strong: <strong />}} />
                </p>


                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.privacy_policy.13.text"
                                components={{
                                    strong: <strong/>,
                                    a: (
                                        <a
                                            target="_blank"
                                            href="mailto:DPO@morgi.org"
                                        />
                                    ),
                                }}
                            />
                        </p>
                        <p>
                    <strong>
                        <Trans t={t} i18nKey="static_pages.privacy_policy.13.title" components={{strong: <strong />}} />
                    </strong>
                </p>
                <p>
                    <Trans
                        t={t}
                        i18nKey="static_pages.privacy_policy.13.text"
                        components={{
                            strong: <strong />,
                            a: <a target="_blank" href={`mailto:${morgiDPO}`}/>
                        }}
                    />
                </p>
                <ul>
                    <li>
                        <Trans
                            t={t}
                            i18nKey="static_pages.privacy_policy.13.list.1"
                            components={{
                                strong: <strong />,
                                u: <u />,
                                a: <a target="_blank" href={`mailto:${morgiDPO}`}/>
                            }}
                        />
                    </li>
                    <li>
                        <Trans
                            t={t}
                            i18nKey="static_pages.privacy_policy.13.list.2"
                            components={{
                                strong: <strong />,
                                u: <u />,
                                a: <a target="_blank" href={`mailto:${morgiDPO}`}/>
                            }}
                        />
                    </li>
                </ul>
                <p>
                    <Trans t={t} i18nKey="static_pages.privacy_policy.13.text2" components={{strong: <strong />}} />
                </p>
                        {/* <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.privacy_policy.13.text2"
                                components={{strong: <strong/>}}
                            />
                        </p> */}


                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy
