import React from 'react';
import {createUseStyles} from 'react-jss';
import cx from "classnames";

const useStyles = createUseStyles(theme => ({
    root: {
        'width': ({width}) => width,
        maxWidth: ({maxWidth}) => maxWidth,
        maxHeight: theme.spacing * 7,
        /*'min-width': theme.spacing * 10,*/
        'border': 0,
        borderRadius: 100,
        'cursor': ({disabled}) => disabled ? 'not-allowed' : 'pointer',
        'font-weight': ({fontWeight}) => fontWeight ? fontWeight : 600,
        'font-size': ({fontSize}) => fontSize ? fontSize : 16,
        fontSize: 16,
        lineHeight: '1.4',
        'display': 'grid',
        'align-items': 'center',
        'justify-content': 'center',
        'padding': [theme.spacing * 2, theme.spacing * 3],
        position: 'relative',
        transition: 'ease-in-out 300ms',
        boxShadow: 'rgba(23, 29, 41, 0.08) 0px 0px 12px 0px',
        outline: "none",
        '& > span': {
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: ({icon, iconPosition}) => icon ? iconPosition ==='right' ? '1fr auto': 'auto 1fr' : '1fr',
            gridColumnGap: 8,
            '& svg': {
                width: 18
            }
        },

        '&[data-block]': {
            'width': '100%'
        },

        '&[data-icon-only]': {
            backgroundColor: theme.colors.white,
            maxWidth: '56px',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
            border: 'none',
            padding: [theme.spacing * 2],
            [theme.s]: {
                '& span': {
                    '& svg': {
                        width: 20,
                        height: 20
                    }
                },
            },
            [theme.mUp]: {
                '& span': {
                    '& svg': {
                        width: 24,
                        height: 24
                    }
                },
            }
        },

        '&[data-small]': {
            'padding': [theme.spacing, theme.spacing * 2],
            '& span': {
                '& svg': {
                    width: 16
                }
            },
        },

        '&[data-big]': {
            'padding': [theme.spacing * 3, theme.spacing * 4],
            '& span': {
                '& svg': {
                    width: 20
                }
            },
        },

        '&:not([data-primary]):not([data-secondary]):not([data-transparent]):not([data-outlined]):not([data-icon-only])': {
            'background-color': theme.colors.white,
            'border': ({disabled}) => disabled ? `solid 1px ${theme.colors.lightGrey}` : `solid 1px ${theme.colors.darkPurple}`,
            'color': theme.colors.darkPurple,
            '& span': {
                '& svg': {
                    fill: `${theme.colors.darkPurple}!important`
                }
            },
            '&:hover': {},
        },

        '&[data-primary]': {
            'background-color': ({disabled}) => disabled ? theme.colors.inactivePurple : theme.colors.primary,
            'color': ({disabled}) => disabled ? theme.colors.white : theme.colors.white,
            '& span': {
                '& svg': {
                    fill: ({disabled}) => `${disabled ? theme.colors.white : theme.colors.white}!important`
                }
            },
            '&:hover': {
                'background-color': ({disabled}) => disabled ? theme.colors.inactivePurple : theme.colors.darkPurple,
                'color': ({disabled}) => disabled ? theme.colors.white : theme.colors.white,
                '& span': {
                    '& svg': {
                        fill: ({disabled}) => `${disabled ? theme.colors.white : theme.colors.white}!important`
                    }
                },
            },
        },

        '&[data-secondary]': {
            'background-color': ({disabled}) => disabled ? theme.colors.yellowInactive : theme.colors.yellow,
            'color': ({disabled}) => disabled ? theme.colors.black : theme.colors.black,
            '& span': {
                '& svg': {
                    fill: ({disabled}) => `${disabled ? theme.colors.black : theme.colors.black}!important`
                }
            },
            '&:hover': {
                'background-color': ({disabled}) => disabled ? theme.colors.yellowInactive : theme.colors.yellowLight,
                'color': ({disabled}) => disabled ? theme.colors.black : theme.colors.black,
                '& span': {
                    '& svg': {
                        fill: ({disabled}) => `${disabled ? theme.colors.black : theme.colors.black}!important`
                    }
                },
            },
        },
        '&[data-outlined]': {
            'background-color': 'transparent',
            'border': ({disabled}) => disabled ? `1px solid ${theme.colors.lightGrey}` : `1px solid ${theme.colors.darkPurple}`,
            'color': ({disabled}) => disabled ? theme.colors.lightGrey : theme.colors.darkPurple,
            'box-sizing': 'border-box',
            '& span': {
                '& svg': {
                    fill: ({disabled}) => `${disabled ? theme.colors.lightGrey : theme.colors.darkPurple}!important`
                }
            },
        },
        '&[data-transparent]': {
            boxShadow: 'none',
            'background-color': 'transparent',
            'border': 'none',
            'color': ({disabled}) => disabled ? theme.colors.lightGrey : theme.colors.darkPurple,
            '& span': {
                '& svg': {
                    fill: ({disabled}) => `${disabled ? theme.colors.lightGrey : theme.colors.darkPurple}!important`
                }
            },
        },
    },
}));

const Button = ({
                    className,
                    children,
                    width = 'auto',
                    type = 'button',
                    disabled,
                    icon,
                    iconPosition = 'left',
                    fontSize,
                    fontWeight,
                    maxWidth= '400px',
                    ...props
                }) => {
    const classes = useStyles({width, maxWidth, disabled: disabled, icon, iconPosition, fontSize, fontWeight});
    return (
        <button className={cx(classes.root, className)} {...props} type={type} disabled={disabled}>
            {icon ?
                iconPosition === 'left' ?
                    <span>
                    {icon}
                        <span>{children}</span>
                </span> :
                    <span>
                    <span>{children}</span>
                        {icon}
                </span>

                :
                <span>{children}</span>}
        </button>
    );
};

export default Button;
