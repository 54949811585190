import * as yup from 'yup';

export const CHECKOUT_FIELDS = {
    customerName: 'customerName',
    email: 'email',
    phone: 'phone',
    street: 'street',
    paymentMethod: 'paymentMethod',
    address: 'address',
    city: 'city',
    zipCode: 'zip_code',
    rowAddress: 'raw_address',
    countryName: 'country_name',
    countryCode: 'country_code',
    stateCode: 'state_code'
};

export const AddressSchema = yup.object({
    [CHECKOUT_FIELDS.address]: yup.string().min(1, {
        message: 'validation:required_street'
    }),
    [CHECKOUT_FIELDS.city]: yup.string().min(1, {
        message: 'validation:required_city'
    }),
    [CHECKOUT_FIELDS.zipCode]: yup.string().optional(),
    [CHECKOUT_FIELDS.rowAddress]: yup.string().optional(),
    [CHECKOUT_FIELDS.countryName]: yup.string().optional(),
    [CHECKOUT_FIELDS.countryCode]: yup.string().optional(),
    [CHECKOUT_FIELDS.stateCode]: yup.string().optional()
})