import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {createUseStyles} from "react-jss";
import lottie from "lottie-web";
import {DIRECTION_FORWARD, DIRECTION_REVERSE, PAUSE, PLAY, STOP} from "../utilities/constants";

const useStyles = createUseStyles(theme =>  ({
    root: {
        width: 100,
        height: 100,
    }
}))

const Animation = ({
                       animationData,
                       title = '',
                       name = 'animation',
                       autoplay = true,
                       loop = false,
                       renderer = 'svg',
                       rendererExtraSettings,
                       onAnimationClick,
                       animationStyleClass,
                       animationState = STOP,
                       direction = DIRECTION_FORWARD,
                       speed = 1,
                       segmentPlay = []
                   }) => {

    const [instance, setInstance] = useState(null);
    const [playingState, setPlayingState] = useState(autoplay ? PLAY : STOP);
    const animationContainer = useRef();
    const classes = useStyles();

    useEffect(() => {
        const animationOpts = {
            name,
            container: animationContainer.current,
            renderer,
            loop,
            autoplay,
            animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
                ...rendererExtraSettings
            }
        }

        setInstance(lottie.loadAnimation(animationOpts));

        return () => {
            if(instance) {
                instance.destroy();
            }
        }
    }, [])


    /* Segments control */
    useEffect(() => {
            if (instance && segmentPlay.length > 0){
                instance.playSegments(segmentPlay, true);
            }
        }, [segmentPlay, instance]

    )

    /* Direction control */
    useEffect(() => {
            if (instance &&
                direction && (direction === DIRECTION_FORWARD || DIRECTION_REVERSE)) {
                instance.setDirection(direction)
            }
        }, [direction, instance]
    )

    /* Speed control */
    useEffect(() => {
            if (instance &&
                direction && (speed >= 0)) {
                instance.setSpeed(direction)
            }
        }, [speed, instance]
    )

    useEffect(() => {
        setPlayingState(playingState);
    }, [animationState, instance])

    /* Playing state control */
    useEffect(() => {
            if (instance) {
                if (playingState === PLAY) {
                    instance.play();
                }
                else if (playingState === PAUSE) {
                    instance.pause();
                }
                else if (playingState === STOP) {
                    instance.stop();
                }
            }
        }, [playingState]
    )

    const onClickHandler = () => {
        if (typeof onAnimationClick === 'function') onAnimationClick();
    }

    return (
        <div ref={animationContainer}
             title={title}
             onClick={onClickHandler}
             className={cx(classes.root, animationStyleClass)}
        />
    )
}

Animation.propTypes = {
    animationData: PropTypes.object.isRequired,
    name: PropTypes.string,
    title: PropTypes.string,
    onAnimationClick: PropTypes.func,
    animationState: PropTypes.oneOf([PLAY, STOP, PAUSE]),
    loop: PropTypes.bool,
    autoplay: PropTypes.bool,
    speed: (props, propName, componentName) => {
        let value = props[propName];
        if (typeof value === 'number') {
            return (value >= 0 && value <= 1) ? null : new Error(propName + ' in ' + componentName + " is not within 0 to 1");
        }
    },
    direction: PropTypes.oneOf([-1, 1])
};

export default Animation;