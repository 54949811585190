import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import ExpandableText from "../../../components/ExpandableText";
import FavTopics from "../../../components/FavTopics";
import { getAvailableNamespaces } from "../../../translations";

const useStyles = createUseStyles((theme) => ({
    achievementsTitle: {
        fontWeight: "700",
        fontSize: "20px",
        lineHeight: "28px",
        margin: "16px 0 16px 0",
    },
    backgroundBanner: {
        padding: "16px",
        borderRadius: "14px",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    },
    bio: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "300px",
        margin: "10px 0 0",
        fontSize: 14,
        color: `${theme.colors.darkPurple}`,
        opacity: "50%",

        [theme.mUp]: {
            maxWidth: "100%",
        },
    },
    wishesWrapper: {
        display: 'flex',
        gap: "2px",
        fontWeight: 700,
    },
    wishName: {
        color: "#8649D1"
    }
}))

const RookieAboutSection = ({ rookieProfile }) => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const {
        description,
        type_attributes: { path },
        chat_topics,
        favorite_activities,
    } = rookieProfile;

    return (
        <div>
            <h4 className={classes.achievementsTitle}>
                {t('rookie_profile_page.about_section_title')}
            </h4>
            <div className={classes.backgroundBanner}>
                {path && (
                    <div className={classes.wishesWrapper}>
                        {t('rookie_profile_page.i_am')}
                        <span className={classes.wishName}>
                            {path?.name}
                        </span>
                    </div>
                )}
                <p className={classes.bio}>
                    <ExpandableText>
                        {description}
                    </ExpandableText></p>
                <FavTopics
                    viewedFavChatTopics={chat_topics}
                    viewedFavActivities={favorite_activities}
                    path={path}
                />
            </div>
        </div>
    )
}

export default RookieAboutSection