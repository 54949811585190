
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputText from '../../InputText';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    label: {
        marginBottom: '2px',
    }
})
export const PlaceAutocomplete = ({ onPlaceSelect, onBlur, defaultValue, error, name, label, placeholder }) => {
    const classes = useStyles()
    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    const inputRef = useRef(null);
    const places = useMapsLibrary('places');
    const { t } = useTranslation();

    useEffect(() => {
        if (!places || !inputRef.current) return;

        const options = {
            fields: ['address_components']
        };

        setPlaceAutocomplete(new places.Autocomplete(inputRef.current, { ...options }));
    }, [places]);

    useEffect(() => {
        if (!placeAutocomplete) return;

        placeAutocomplete.addListener('place_changed', () => {
            onPlaceSelect({ ...placeAutocomplete.getPlace(), formatted_address: inputRef.current?.value });
        });
    }, [onPlaceSelect, placeAutocomplete]);

    useEffect(() => {
        if (inputRef.current && defaultValue) {
            onPlaceSelect({
                formatted_address: defaultValue.raw_address,
                address_components: [
                    { long_name: defaultValue.city, short_name: defaultValue.city, types: ['locality'] },
                    { long_name: defaultValue.zip_code, short_name: defaultValue.zip_code, types: ['postal_code'] },
                    { long_name: defaultValue.country_name, short_name: defaultValue?.country_code, types: ['country'] },
                    { long_name: defaultValue.state_code, short_name: defaultValue.state_code, types: ['administrative_area_level_1'] }
                ]
            });
            inputRef.current.value = defaultValue.raw_address;
        }
    }, [defaultValue]);

    return (
        <div>
            {label ? <label className={classes.label}>{label}</label> : <></>}
            <InputText
                ref={inputRef}
                placeholder={placeholder}
                name={name}
                errors={error ? { message: t(error) } : undefined}
                onChange={e => {
                    if (!e.target.value.length) {
                        setPlaceAutocomplete(null);
                        onPlaceSelect(null);
                    }
                }}
                onBlur={onBlur}
            />
        </div>
    );
};
