import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Button from './Button'
import { CloseIcon, RightArrowActionIcon } from '../assets/icons/icons'
import { useSwipeable } from 'react-swipeable'
import { useViewportSize } from '../hooks/useViewportSize'
import { useLockBodyScroll } from '../hooks/useLockBodyScroll'

/**
 * Usage example:
 *
 *      - remap photos or videos to media array like
 *           const photosMap = photos?.map((photo) => {
 *             return {
 *                 type: 'image',
 *                 url: photo?.url,
 *             }
 *         })
 *
 *          const media = [...new Set([...photosMap, videoMap])]
 *
 *
 *       - declare options for plyr
 **/

const useStyles = createUseStyles((theme) => ({
    overlay: {
        // fullscreen
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.9)',
        alignContent: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1033,
        [theme.mUp]: {
            gap: 10,
        },
    },
    containerCounter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 22,
        padding: 6,
        height: 40,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1034,
    },
    counter: {
        fontSize: 16,
        color: 'grey',
    },
    carousel: {
        height: '100%',
        display: 'flex',
    },
    carouselInner: {
        transition: 'transform 0.3s ease',
        flexGrow: 1,
        maxHeight: '100vh',
        alignSelf: 'center',
        display: 'flex',
    },
    containerItem: {
        height: '100vh',
        width: '100vw',
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > *': {
            maxHeight: '100vh',
        },
    },
    image: {
        maxWidth: '100%',
    },
    video: {
        maxWidth: "100%",
    },
    indicators: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        justifyContent: 'space-between',
        top: '50%',
        '& button': {
            backgroundColor: 'transparent !important',
            border: 'none !important',
            margin: 5,
            '& svg path': {
                fill: 'white',
            },
        },
        '& button:nth-child(1)': {
            '& svg': {
                transform: 'rotate(180deg)',
            },
        },
    },
    overlayButton: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1033,
    },
    closeButton: {
        display: 'flex',
        padding: 4,
        backgroundColor: theme.colors.primary,
        borderRadius: 4,
    },
    closeIcon: {
        cursor: 'pointer',
        '& path': {
            fill: 'white',
        },
    },
}))

const LightBox = (props) => {
    const {
        media,
        plyrOptions = {},
        onClose,
        autoplay = false,
        currentIndex = 0,
        isMultipleTypesAutoplayVideo = false,
        ...rest
    } = props
    const classes = useStyles()
    const [activeIndex, setActiveIndex] = useState(currentIndex)
    const [paused, setPaused] = useState(false)
    const [showButtons, setShowButtons] = useState(false)
    const { width } = useViewportSize()
    const [isMobile, setIsMobile] = useState(width <= 448)
    const [activeIndexVideo, setActiveIndexVideo] = useState(null)

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])
    useLockBodyScroll()

    const slideCarousel = (newIndex) => {
        if (newIndex < 0) {
            newIndex = 0
        } else if (newIndex >= media.length) {
            newIndex = media.length - 1
        }
        setActiveIndex(newIndex)
    }

    useEffect(() => {
        if (autoplay === true) {
            const interval = setInterval(() => {
                if (!paused) {
                    slideCarousel(activeIndex + 1)
                }
                slideCarousel(activeIndex + 1)
            }, 4000)

            return () => {
                if (interval) {
                    clearInterval(interval)
                }
            }
        }
    }, [activeIndex, paused, media])

    const handlers = useSwipeable({
        onSwipedLeft: () => slideCarousel(activeIndex + 1),
        onSwipedRight: () => slideCarousel(activeIndex - 1),
    })

    const onMouseEnter = () => {
        setShowButtons(true)
        setPaused(true)
    }

    const onMouseLeave = () => {
        setShowButtons(false)
        setPaused(false)
    }

    useEffect(() => {
        if(media[activeIndex]?.type === 'video') {
            const prevVideo = document.getElementById(activeIndexVideo)

            if(activeIndexVideo) {
                prevVideo.pause()
                prevVideo.currentTime = 0
            }
            setActiveIndexVideo(activeIndex)
            document.getElementById(activeIndex).play()

        } 
    }, [activeIndex, media])

    return (
        <div className={classes.overlay}>
            <div className={classes.containerCounter}>
                <span className={classes.counter}>
                    {activeIndex + 1} / {media?.length}
                </span>
                <span className={classes.closeButton}>
                    <CloseIcon
                        className={classes.closeIcon}
                        onClick={onClose}
                    />
                </span>
            </div>
            <div
                className={classes.carousel}
                //onMouseEnter={() => onMouseEnter()}
                //onMouseLeave={() => onMouseLeave()}
                {...handlers}
            >
                <div
                    className={classes.carouselInner}
                    style={{ transform: `translateX(-${activeIndex * 100}vw` }}
                >
                    {!!media &&
                        media?.map(({ url, type }, index) => {
                            return (
                                <div
                                    className={classes.containerItem}
                                    key={url}
                                >
                                    {type === 'image' ? (
                                        <img
                                            className={classes.image}
                                            src={url}
                                        />
                                    ) : (
                                        <video controls   preload="metadata" id={index}  className={classes.video}>
                                            <source src={`${url}#t=0.001`}/>
                                        </video>
                                    )}
                                </div>
                            )
                        })}
                </div>
                {!isMobile && (
                    <div className={classes.indicators}>
                        <Button
                            style={{
                                visibility:
                                    activeIndex === 0 ? 'hidden' : 'visible',
                            }}
                            icon={<RightArrowActionIcon />}
                            onClick={() => slideCarousel(activeIndex - 1)}
                        />
                        <Button
                            style={{
                                visibility:
                                    activeIndex === media?.length - 1
                                        ? 'hidden'
                                        : 'visible',
                            }}
                            icon={<RightArrowActionIcon />}
                            onClick={() => slideCarousel(activeIndex + 1)}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default LightBox
