import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss'
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { selectThemeMode } from "../store/settingSlice";
import { setUserGroup } from "../utilities/constants/user";
import { setPersona } from '../store/userSlice';
import PageMeta from "../components/PageMeta";
import {PARAM_PERSONA} from "../utilities/constants";

const useStyles = createUseStyles(theme => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		transition: 'background 0.5s ease 0s',
		backgroundColor: ({ themeMode }) => themeMode === 'light' ? theme.colors.white : theme.colors.darkPurple
	},
	container: {
		composes: 'container',
		flex: 1
	}
}));

const PublicLayout = ({ children, meta }) => {

	const themeMode = useSelector(selectThemeMode)
	const classes = useStyles({ themeMode });
	const dispatch = useDispatch();

	useEffect(() => {
		setUserGroup();
		if (typeof window !== `undefined`) {
			const search = window.location.search;
			const params = new URLSearchParams(search);
			const param = params.get(PARAM_PERSONA);
			if (!!param && Number.isInteger(+param)) {
				dispatch(setPersona(+param))
			}
		}
	}, [])

	return (
		<div className={`${classes.wrapper}`}>
			<PageMeta {...meta}/>
			<Header isLight={themeMode === 'light'} />
			<div className={classes.container}>
				{children}
			</div>
		</div>
	)
};


export default PublicLayout;
