import { createUseStyles, useTheme } from "react-jss";
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { GOAL_STATUSES } from "../utilities/constants/goalTypes";
import { CheckIcon, ExclamationMark } from "../assets/icons/icons";

const useStyles = createUseStyles((theme) => ({
  root: {
    display: "block",
    position: "relative",
    width: ({ size }) => size,
    height: ({ size }) => size,
    transform: "rotate(-90deg)",
    transformOrigin: "center",
  },
  percentage: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "rotate(90deg)",
    "& > svg": {
      width: "20px !important",
    },
  },
}));

const CircularProgress = forwardRef(function CircularProgress(
  {
    className,
    percentageClassName,
    status = "default",
    defaultIcon,
    successIcon = <CheckIcon />,
    failIcon = <ExclamationMark />,
    pendingIcon = <CheckIcon />,
    size = 60,
    thickness = 5,
    percentage = 0,
    strokeLinecap = "round",
    strokeColor = ["#8649D1", "#8B6DE8", "#8F92FF"],
    ...other
  },
  circleRef
) {
  const theme = useTheme();
  const classes = useStyles({ size });
  const PI = 3.14;
  const R = (size - thickness) / 2;
  let circumference = 2 * PI * R;
  let offset = circumference - (percentage / 100) * circumference;
  let gradientId =
    `${strokeColor[0]}${strokeColor[1]}${strokeColor[2]}`.replace(/#/g, "");

  const stroke =
    status === GOAL_STATUSES.pending
      ? theme.colors.yellow
      : status === GOAL_STATUSES.failure
      ? theme.colors.red
      : status === GOAL_STATUSES.success
      ? theme.colors.green
      : `url(#${gradientId}`;

  const renderCorrectIcon = () => {
    switch (status) {
      case GOAL_STATUSES.success:
        return successIcon;
      case GOAL_STATUSES.pending:
        return pendingIcon;
      case GOAL_STATUSES.failure:
        return failIcon;
      default:
        return defaultIcon;
    }
  };

  return (
    <div
      ref={circleRef}
      className={cx(classes.root, className)}
      role="progressbar"
      {...other}
    >
      <span className={cx(classes.percentage, percentageClassName)}>
        {percentage ? `${percentage}%` : renderCorrectIcon()}
      </span>
      <svg
        style={{ display: "block" }}
        viewBox={`${size / 2} ${size / 2} ${size} ${size}`}
      >
        <linearGradient
          id={gradientId}
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%"
          gradientTransform="rotate(45)"
        >
          <stop offset="0%" stopColor={strokeColor[0]} />
          <stop offset="38.548%" stopColor={strokeColor[1]} />
          <stop offset="100%" stopColor={strokeColor[2]} />
        </linearGradient>

        {!percentage ? (
          <circle
            strokeWidth={thickness}
            fill={"transparent"}
            cx={size}
            cy={size}
            r={R}
            stroke={stroke}
          />
        ) : (
          <circle
            strokeWidth={thickness}
            fill={"transparent"}
            cx={size}
            cy={size}
            r={R}
            stroke={`url(#${gradientId}`}
            strokeLinecap={strokeLinecap}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={offset}
          />
        )}
      </svg>
    </div>
  );
});

CircularProgress.propTypes = {
  status: PropTypes.oneOf([
    "fail",
    "success",
    "default",
    "uploading",
    "pending",
  ]),
  size: PropTypes.number,
  thickness: PropTypes.number,
  showPercent: PropTypes.bool,
  percentage: PropTypes.number.isRequired,
  strokeLineCap: PropTypes.oneOf(["butt", "round", "square"]),
};

export default CircularProgress;
