import { useEffect, useState } from "react"
import Spinner from "../../components/Spinner"
import axios from "../../utilities/axios"
import CTAModal from "../../components/CTAModal"
import { createUseStyles, useTheme } from "react-jss"
import alert from "../../assets/animations/Alert-1.json";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useTranslation } from "react-i18next"
import Animation from "../../components/Animation"
import { getAvailableNamespaces } from "../../translations"
import { PaymentStepsModal, PaymentStepsVariants } from "../rookieProfilePage/PaymentStepsModal/PaymentStepsModal"

const useStyles = createUseStyles((theme) => ({
    alertAnimation: {
        margin: "0 auto",
        height: "220px",
        width: "240px",
        padding: "15px",
        [theme.mUp]: {
          padding: 0,
        },
      },
}))

const PaymentsStatus = () => {
    const theme = useTheme()
    const classes = useStyles({ theme });
    const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [transactionStatus, setTransactionStatus] = useState(null)
    const [isError, setIsError] = useState(null)
    const isSuccess = transactionStatus?.status === "success"
    const [hostName, setHostName] = useState('')
    const [userEmail, setUserEmail] = useState('')

    const contentVariant = {
        ["gift"] : isSuccess ? PaymentStepsVariants.SUCCESS_SUBSCRIPTION : PaymentStepsVariants.FAILURE_SUBSCRIPTION,
        ["micromorgi"]: isSuccess ? PaymentStepsVariants.SUCCESS_PACKAGE : PaymentStepsVariants.FAILURE_PACKAGE
    }

    const checkLastTransactionStatus = async() => {
        const userId = typeof window !== 'undefined' ? localStorage.getItem("userIdForPaymentStatus") : null
        try{
            setLoading(true)
            const {data} = await axios.get(`/users/${userId}/latest-payment`)
            console.log(data)
            setTransactionStatus(data)
        }catch(err){
            setIsError(true)
            console.log(err)
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        if(typeof window !== 'undefined') {
            setUserEmail(localStorage.getItem("userEmailForPaymentStatus"))
            setHostName(localStorage.getItem("hostNameForPaymentsStatus"))
        }
            
        checkLastTransactionStatus()
        return () => {
            if(typeof window !== 'undefined') {
                localStorage.removeItem("paymentStartLocation")
                localStorage.removeItem("userIdForPaymentStatus")
                localStorage.removeItem("userEmailForPaymentStatus")
                localStorage.removeItem("hostNameForPaymentsStatus")
            }
        }
    },[])

    if(isError) {
        return <CTAModal  
                    isBackButton={true}
                    onClick={() => history.push("/")}
                    onClose={() => history.push("/")}
                    onClickGoBack={() => history.push(typeof window !== 'undefined' ? localStorage.getItem("paymentStartLocation") : "/")}
                    title={t("attention_modal.title")}
                        description={t("attention_modal.description_transaction")}
                        buttonText={t("attention_modal.go_home")}
                >
                    <Animation
                        animationData={alert}
                        animationStyleClass={classes.alertAnimation}
                    />
                </CTAModal>
    }
    const dataToDisplay = {
        Email: userEmail,
        ROOKIE_NAME: hostName,
    }

    return loading ? <Spinner/> : 
        !!transactionStatus && <PaymentStepsModal 
                                    variant={contentVariant[transactionStatus?.type]} 
                                    dataToDisplay={dataToDisplay} 
                                    onClose={() => history.push(typeof window !== 'undefined' ? localStorage.getItem("paymentStartLocation") : "/")}
                                />
}
export default PaymentsStatus