import React from "react";
import { createUseStyles } from "react-jss";
import Navbar from "./Nav";

const useStyles = createUseStyles((theme) => ({
  root: {
    position: "relative",
    /*position: 'sticky',
		top: 0,*/
    zIndex: 1030,
    transition: "background 0.5s ease 0s",
    backgroundColor: ({ isLight }) =>
      isLight ? theme.colors.white : "transparent",
    [theme.sUp]: {
      borderBottom: ({ isLight }) =>
        isLight ? `1px solid rgba(0,0,0,.1)` : `1px solid rgba(255,255,255,.2)`,
    },
  },
  headerContainer: {
    padding: [0, theme.spacing * 1.5],
    [theme.sUp]: {
      padding: [0, theme.spacing * 2],
    }
  },
}));

const Header = ({ className, isLight = true, ...props }) => {
  const classes = useStyles({ isLight });
  return (
    <header className={classes.root}>
      <div className={classes.headerContainer}>
        <Navbar isLight={isLight} />
      </div>
    </header>
  );
};

export default Header;
