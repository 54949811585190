import React, {useEffect, useState} from 'react'
import {createUseStyles} from 'react-jss'
import cx from 'classnames'
import useGoogleIdentity from '../hooks/useGoogleIdentity'
import {handleApiError} from '../utilities/helpers'
import {useDispatch} from 'react-redux'
import {
    PARAM_PATH,
    PARAM_PERSONA,
    PARAM_REFERRAL_CODE,
    PARAM_ROOKIE_PROFILE,
    PARAM_USER_GROUP,
    ROUTE_LINK_ACCOUNTS,
} from '../utilities/constants'
import {useHistory, useLocation} from 'react-router-dom'
import {unwrapResult} from '@reduxjs/toolkit'
import Spinner from "./Spinner";
import {loginSocial} from "../store/userSlice";
import Alert from "./Alert";
import {getAppLink} from "../utilities/utils";

const useStyles = createUseStyles((theme) => ({
    facebookButton: ({height, collapsed, disabled, icon, minWidth}) => ({
        display: 'grid',
        gridTemplateColumns: collapsed ? '1fr' : icon ? 'auto 1fr auto' : '40px 1fr 40px',
        width: collapsed ? height : '100%',
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
        height: height ?? 50,
        border: `1px solid #00063D`,
        borderRadius: 6,
        padding: [0, 16],
        cursor: disabled ? 'not-allowed' : 'pointer',
        minWidth: minWidth ?? 334,
        minHeight: 37,
    }),
    icon: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: ({iconRight, iconLeft}) => ({
        fontSize: 16,
        fontWeight: 600,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        color: '#00063D',
        margin: iconLeft
            ? [0, 0, 0, theme.spacing]
            : iconRight
                ? [0, theme.spacing, 0, 0]
                : [0, 0, 0, 0],
    }),
    alert: {
        position: 'fixed',
        top: 92,
        zIndex: 1000,
        left: '50%',
        transform: 'translateX(-50%)',
        minWidth: '96%',
        [theme.mUp]: {
            top: 160,
            minWidth: 900
        }
    }
}))

export const GoogleIcon = ({iconSize, ...props}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="24"
            fill="none"
            viewBox="0 0 23 24"
            {...props}
        >
            <mask
                id="mask0_1073_8290"
                style={{maskType: 'alpha'}}
                width="23"
                height="24"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path
                    fill="#DB4437"
                    d="M22.544 12.408c0-.74-.093-1.294-.185-1.894H11.456v3.926h6.468c-.231 1.71-1.94 4.943-6.467 4.943-3.927 0-7.115-3.233-7.115-7.253 0-6.42 7.577-9.377 11.642-5.45l3.141-3.003C17.139 1.829 14.505.674 11.457.674A11.425 11.425 0 000 12.13a11.396 11.396 0 0011.457 11.457c6.605 0 11.087-4.62 11.087-11.18z"
                ></path>
            </mask>
            <g mask="url(#mask0_1073_8290)">
                <path
                    fill="#F0B501"
                    d="M7.185 10.773L-.052 5.774l-3.003 6.7 2.482 6.37 7.758-5.59v-2.481z"
                ></path>
                <path
                    fill="#2BA24C"
                    d="M6.127 13.255l-6.943 5.467 4.825 5.467 6.717.937 5.988-.937L20.6 22.07l-7.168-5.831-7.306-2.985z"
                ></path>
                <path
                    fill="#E43E2B"
                    d="M5.78 10.478L.365 6.312l3.887-4.946L9.077-.7l7.828.468 4.634 4.13-6.283 3.698-5.415-.938-4.061 3.819z"
                ></path>
                <path
                    fill="#3B7DED"
                    d="M20.601 22.02l-5.64-4.426-4.235-2.76V9.002h13l.294 6.839-3.419 6.178z"
                ></path>
            </g>
        </svg>
    )
}

const GoogleButton = ({
                          rootClassname,
                          textClassname,
                          iconSize = 25,
                          height = 50,
                          icon = <GoogleIcon iconSize={iconSize}/>,
                          iconPosition = 'right',
                          text = 'Continue with Facebook',
                          collapsed = false,
                          disabled,
                          outerTrigger = false,
                          minWidth,
                          ...props
                      }) => {
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)


    // send GSI Token Response it to backend
    const requestBackendAuth = async (authResponse) => {
        const searchParams = new URLSearchParams(location?.search)
        const rookieReferral = searchParams.get(PARAM_ROOKIE_PROFILE)
        const userPublicGroup = searchParams.get(PARAM_USER_GROUP)
        const referralCode = searchParams.get(PARAM_REFERRAL_CODE)
        const leaderPath = searchParams.get(PARAM_PATH)
        const persona = searchParams.get(PARAM_PERSONA)
        setIsLoading(true)
        try {
            const data = await dispatch(
                loginSocial({
                    type: 'google',
                    authResponse: {
                        ...authResponse,
                        first_rookie: rookieReferral || undefined,
                        public_group: +userPublicGroup || undefined,
                        referral_code: referralCode || undefined,
                        path_id: leaderPath || undefined,
                        persona: persona || undefined,
                    },
                })
            )
            const unwrappedData = unwrapResult(data)
            window.location.replace(getAppLink())
            return unwrappedData
        } catch (error) {
            if (error?.status === 302) {
                history.push(ROUTE_LINK_ACCOUNTS, {
                    email: error?.data?.email,
                    userID: error?.data?.id,
                    platform: 'google',
                    data: authResponse,
                })
            } else {
                const errorRemap = {response: {...error}}
                handleApiError({
                    error: errorRemap,
                    callbackOnGeneralError: setError,
                })
            }
        } finally {
            setIsLoading(false)
        }
    }

    const googleClient = useGoogleIdentity({
        onSuccessCallback: requestBackendAuth,
    })

    const singInWithGoogle = () => {
        if (googleClient) {
            googleClient.requestAccessToken()
        } else {
            setError(
                'Cannot login with Google, please refresh the page.'
            )
        }
    }

    useEffect(
        () => {
            if (outerTrigger && googleClient) {
                singInWithGoogle()
            }
        },
        [outerTrigger, googleClient]
    )


    const classes = useStyles({
        height,
        collapsed,
        disabled,
        minWidth,
        icon,
        iconRight: iconPosition === 'right',
        iconLeft: iconPosition === 'left'
    })
    return (
        <>   {isLoading && <Spinner overlayFullscreen/>}
            <button
                className={cx(classes.facebookButton, rootClassname)}
                type={'button'}
                onClick={singInWithGoogle}
                disabled={disabled}
                {...props}
            >
                {!collapsed && (
                    <>
                        <div className={classes.icon}>
                            {iconPosition === 'left' && icon}
                        </div>
                        <div className={cx(classes.text, textClassname)}>
                            {text}
                        </div>
                    </>
                )}
                <div className={classes.icon}>
                    {(collapsed || iconPosition === 'right') && icon}
                </div>
            </button>
            {
                error &&
                <Alert
                    visible={error}
                    message={error}
                    dismissTimeout={30000}
                    dismissCallback={() => setError(null)}
                    className={classes.alert}
                />
            }
        </>
    )
}

export default GoogleButton
