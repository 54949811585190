import axios from 'axios';
import { runtimeConfig } from '../config';
import i18next from 'i18next';

// create axios custom instance with custom config
const customAxios = axios.create({
    baseURL: runtimeConfig.appEndpoint,
    withCredentials: true,
    headers: {
        common: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
        post: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    },
});

customAxios.interceptors.request.use(config => {
    config.headers['X-localization'] = i18next.language || 'en_US'
    return config
})


// enable global reponse handling
customAxios.interceptors.response.use(
    function(response) {
        // pass response without change
        return response;
    },
    function(error) {

        // get error info
        let statusCode = error?.response?.status;
        let message = error?.response?.data?.message;

        switch (statusCode) {
            case 401:
                // LocalStorageManager.removeAuthData();
                // window.location.replace(LOGIN);
                break;
            case 500:
                //alert(message);
                break;
            default:
        }

        // pass error without change
        return Promise.reject(error);
    },
);

export default customAxios;
