import { Trans, useTranslation } from 'react-i18next'
import { getAvailableNamespaces } from '../../translations'
import React from 'react'
import { useStyles } from './StaticPages'

const CookiePolicy = () => {
    const classes = useStyles()
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    })

    return (
        <>
            <div className={classes.titleWrapper}>
                <h2 className={classes.title}>
                    {t('static_pages.cookie_policy.title')}
                </h2>
            </div>

            <div className={classes.wrapper}>
                <div className={'row'}>
                    <div
                        className={
                            'col col-md-10 offset-md-1 col-lg-8 offset-lg-2'
                        }
                    >
                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.last_updated"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>
                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.introduction"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.introduction_text"
                                components={{strong: <strong/>}}
                            />
                        </p>
                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.what_are_cookies"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.what_are_cookies_text"
                                components={{
                                    strong: <strong/>,
                                    a: (
                                        <a
                                            target="_blank"
                                            href="http://www.allaboutcookies.org"
                                        />
                                    ),
                                }}
                            />
                        </p>
                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.show_tracking"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.show_tracking_text"
                                components={{strong: <strong/>}}
                            />
                        </p>
                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.what_type_of_tracking"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.five_type_of_tracking"
                                components={{strong: <strong/>}}
                            />
                        </p>
                        <ul>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.first_type"
                                    components={{strong: <strong/>}}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.second_type"
                                    components={{strong: <strong/>}}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.third_type"
                                    components={{strong: <strong/>}}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.fourth_type"
                                    components={{strong: <strong/>}}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.fifth_type"
                                    components={{strong: <strong/>}}
                                />
                            </li>
                        </ul>
                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.tracking_technologies_1"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.type_1"
                                components={{strong: <strong/>}}
                            />
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.purpose_1"
                                components={{strong: <strong/>}}
                            />
                        </p>
                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.tracking_technologies_2"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.type_2"
                                components={{strong: <strong/>}}
                            />
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.purpose_2"
                                components={{strong: <strong/>}}
                            />
                        </p>
                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.tracking_technologies_3"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.type_3"
                                components={{strong: <strong/>}}
                            />
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.purpose_3"
                                components={{strong: <strong/>}}
                            />
                        </p>

                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.tracking_technologies_4"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.type_4"
                                components={{strong: <strong/>}}
                            />
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.purpose_4"
                                components={{strong: <strong/>}}
                            />
                        </p>

                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.tracking_technologies_5"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.type_5"
                                components={{strong: <strong/>}}
                            />
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.purpose_5"
                                components={{
                                    strong: <strong/>,
                                    a: (
                                        <a
                                            target="_blank"
                                            href="https://www.facebook.com/policies/cookies/"
                                        />
                                    ),
                                    a1: (
                                        <a
                                            target="_blank"
                                            href="https://www.facebook.com/about/ads"
                                        />
                                    ),
                                    a2: (
                                        <a
                                            target="_blank"
                                            href="https://www.facebook.com/ads/preferences/edit/"
                                        />
                                    ),

                                }}
                            />
                        </p>

                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.tracking_technologies_6"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.type_6"
                                components={{strong: <strong/>}}
                            />
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.purpose_6"
                                components={{strong: <strong/>}}
                            />
                        </p>

                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.tracking_technologies_7"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.type_7"
                                components={{strong: <strong/>}}
                            />
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.purpose_7"
                                components={{strong: <strong/>}}
                            />
                        </p>

                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.tracking_technologies_8"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.type_8"
                                components={{strong: <strong/>}}
                            />
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.purpose_8"
                                components={{strong: <strong/>}}
                            />
                        </p>

                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.tracking_technologies_9"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.type_9"
                                components={{strong: <strong/>}}
                            />
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.purpose_9"
                                components={{strong: <strong/>}}
                            />
                        </p>

                        <p>
                            <strong>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.how_to_manage_cookies"
                                    components={{strong: <strong/>}}
                                />
                            </strong>
                        </p>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.cookie_policy.how_to_manage_cookies_text"
                                components={{strong: <strong/>}}
                            />
                        </p>
                        <ul>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.manage_1"
                                    components={{strong: <strong/>}}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.manage_2"
                                    components={{strong: <strong/>}}
                                />
                                <ul>
                                    <li>
                                        <a
                                            target="_blank"
                                            href="https://support.google.com/chrome/answer/95647?hl=en"
                                        >
                                            <Trans
                                                t={t}
                                                i18nKey="static_pages.cookie_policy.manage_3.1"
                                                components={{
                                                    strong: <strong/>,
                                                }}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target="_blank"
                                            href="https://support.google.com/chrome/answer/2392971?hl=en"
                                        >
                                            <Trans
                                                t={t}
                                                i18nKey="static_pages.cookie_policy.manage_3.2"
                                                components={{
                                                    strong: <strong/>,
                                                }}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target="_blank"
                                            href="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies"
                                        >
                                            <Trans
                                                t={t}
                                                i18nKey="static_pages.cookie_policy.manage_3.3"
                                                components={{
                                                    strong: <strong/>,
                                                }}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target="_blank"
                                            href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                                        >
                                            <Trans
                                                t={t}
                                                i18nKey="static_pages.cookie_policy.manage_3.4"
                                                components={{
                                                    strong: <strong/>,
                                                }}
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            target="_blank"
                                            href="https://support.apple.com/kb/PH19214?locale=en_US"
                                        >
                                            <Trans
                                                t={t}
                                                i18nKey="static_pages.cookie_policy.manage_3.5"
                                                components={{
                                                    strong: <strong/>,
                                                }}
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="static_pages.cookie_policy.manage_4"
                                    components={{
                                        strong: <strong/>,
                                        a: (
                                            <a
                                                target="_blank"
                                                href="https://optout.networkadvertising.org/?c=1"
                                            />
                                        ),
                                    }}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CookiePolicy
