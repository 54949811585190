import axios from '../utilities/axios'

export const httpGetLanguage = (text) => {
    const url = `/translations/get-language?text=${text}`
    return axios.get(url)
}

export const httpGetTranslation = ({contents, target}) => {
    const url = `/translations`
    return axios.post(url, {contents, target})
}