import {JssProvider, ThemeProvider} from "react-jss";
import getAppTheme from "../theme";
import {useTranslation} from "react-i18next";
import JssInstance from "../utilities/jss";
import { useSSR } from 'react-i18next';


const DirectionManager = ({isServer, children, initialI18nStore, initialLanguage, ...props}) => {

    const { i18n } = useTranslation();
    const direction = i18n.dir()

    const customJss = JssInstance.getInstance(direction)
    const theme = getAppTheme(direction, customJss, props.registry);

    // change <html> attributes
    if(!isServer){
        document.documentElement.dir = direction;
        document.documentElement.lang = i18n.language;
        useSSR(window.initialI18nStore, window.initialLanguage);
    }

    return (
        <JssProvider jss={customJss} {...props}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </JssProvider>
    );
}

export default DirectionManager;
