import {createUseStyles} from "react-jss";
import {useTranslation} from "react-i18next";
import {getAvailableNamespaces} from "../translations";

const useStyles = createUseStyles(theme => ({
    table: {
        backgroundColor: theme.colors.white,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
        borderRadius: "10px",
        overflow: "hidden",
        margin: "0 auto 16px auto",
        maxWidth: "500px"
    },
    tableHeader: {
        backgroundColor: theme.colors.primary,
        display: 'grid',
        gridTemplateColumns: "1fr 1fr 1fr",
        textAlign: "center",
        padding: "12px 12px"
    },
    headerItem: {
        fontSize: "14px",
        fontWeight: 700,
        color: theme.colors.white,
        padding: "0 3px",
        [theme.mUp]: {
            fontSize: "16px"
        }
    },
    tableBody: {
        '& hr': {
            margin: 0
        },
    },
    tableBodyRow: {
        display: 'grid',
        gridTemplateColumns: "1fr 1fr 1fr",
        textAlign: "center",
        padding: "12px 12px"
    },
    tableBodyItem: {
        fontSize: "14px",
        color: theme.colors.darkPurpleMidOpacity,
        padding: "0 3px",
        [theme.mUp]: {
            fontSize: "16px"
        }
    }

}));

const Table = ({tableContent}) => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {useSuspense: false});

    return (
        <div className={classes.table}>
            <div className={classes.tableHeader}>
                {tableContent.header.map((item) => {
                    return (
                        <span key={item} className={classes.headerItem}>
                            {t(`${item}`)}
                        </span>
                    )
                })}
            </div>
            <div className={classes.tableBody}>
                <div className={classes.tableBodyRow}>
                    {tableContent.firstRow.map((item) => {
                        return (
                            <span key={item} className={classes.tableBodyItem}>
                                {t(`${item}`)}
                            </span>
                        )
                    })}
                </div>
                <hr/>
                <div className={classes.tableBodyRow}>
                    {tableContent.secondRow.map((item) => {
                        return (
                            <span key={item} className={classes.tableBodyItem}>
                                {t(`${item}`)}
                            </span>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Table