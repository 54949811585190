import Popover from "./Popover";
import Button from "./Button";
import {createUseStyles} from "react-jss";
import Animation from "./Animation";
import {useTranslation} from "react-i18next";
import {getAvailableNamespaces} from "../translations";

const useStyles = createUseStyles((theme) => ({
    popover: {
        display: "flex",
        flexDirection: 'column',
        textAlign: "center",
        width: "90%",
        height: "90%",
        [theme.xsUp]: {
            maxHeight: "557px",
        },
        [theme.mUp]: {
            width: "60%",
            height: "70%",
        },
        [theme.lUp] : {
            width: "40%",
        }
    },
    body: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        //justifyContent: "space-between",
        gap: 8,
        padding: [8, 24],
        height: "90%",
        "& p": {
            marginTop: 0
        }
    },
    content: {
        flexGrow: 1,
        // overflowY:'scroll',
        [theme.xsUp]: {
            overflowY:'hiden',
        }
    },
    buttonWrapper: {
        borderTop: "1px solid #DEE5EF",
        paddingTop: "20px",
    },
    button: {
        margin: "0 auto 15px auto",
    },
    goBackButton: {
        textDecoration: "underline",
        color: "#00063D",
        cursor: "pointer",
    },
}));

const CTAModal = ({
                            buttonText,
                            description,
                            title,
                            onClick,
                            isBackButton = true,
                            onClickGoBack,
                            children,
                            hideCloseButton,
                            onClose,
                            ...rest
                        }) => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {useSuspense: false});
    const onClosePopover = () => {
        if(onClose) {
            onClose()
        }
    }                      

    return (
        <Popover
            hideCloseButton={hideCloseButton}
            overlay
            withHeader
            title={title}
            maxWidth={400}
            minWidth={345}
            className={classes.popover}
            bodyClassName={classes.body}
            onClose={onClosePopover}
        >
            <>
                <p className={classes.description}>{description}</p>
                <div className={classes.content}>
                    {children}
                </div>
                <div className={classes.buttonWrapper}>
                    <Button
                        data-primary
                        width="100%"
                        className={classes.button}
                        onClick={onClick}
                        disabled={rest.isSubmitting || rest.isDisabled}
                    >
                        {buttonText}
                    </Button>
                    {isBackButton && (
                        <span className={classes.goBackButton} onClick={onClickGoBack}>
                            {t("attention_modal.go_back_button")}
                        </span>
                    )}
                </div>
            </>
        </Popover>
    );
};

export default CTAModal;