import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import { createUseStyles } from "react-jss";
import { MicromorgiIcon, TopMegaFanIcon } from "../../../assets/icons/icons";
import megaGiftSmall from "../../../assets/images/megaGiftSmall.png";

const useStyles = createUseStyles((theme) => ({
  achievementsTitle: {
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "28px",
    margin: "16px 0 16px 0",
  },
  achivementsBanner: {
    padding: "16px",
    borderRadius: "14px",
    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    marginBottom: 16,
    display: "flex",
    gap: 12,
  },
  profileBannerImgWrapper: {
    width: "63px",
    height: "63px",

    "& > svg": {
      width: "100%",
      height: "100%",
      background: "linear-gradient(#8f90fe 0%, #8b71ea 50.31%, #864bd2 100%)",
      borderRadius: 16,
    },
    "& > img": {
      width: "100%",
      height: "100%",
    },

    [theme.mUp]: {
      width: "100px",
      height: "100px",
    },
  },
  profileBannerInfo: {
    display: "flex",
    flexDirection: "column",
    gap: 4,

    "& > p": {
      margin: 0,
      textTransform: "uppercase",
      fontWeight: 600,
    },
  },
  profileBannerCost: {
    display: "flex",
    gap: 4,

    "& > p": {
      color: "rgba(0, 6, 61, 0.7)",
      margin: 0,
    },

    "& > span": {
      fontWeight: 600,
    },

    "& > svg": {
      width: 18,
      height: 18,
    },
  },
  profileBannerDescription: {
    display: "flex",
    gap: 4,
    fontSize: 12,
    marginRight: 5,
    color: "rgba(0, 6, 61, 0.5)",

    "& > p": {
      margin: 0,
      color: `${theme.colors.darkPurple}`,
    },

    [theme.mUp]: {
      fontSize: 14,
    },
  },
}));

const RookieAchievementSection = ({ rookieProfile }) => {
  const classes = useStyles();
  const [t] = useTranslation(getAvailableNamespaces(), {
    react: { useSuspense: false },
  });

  const isShowTopFanSection =
    !!rookieProfile?.type_attributes?.top_fans_infos?.completed_counter &&
    rookieProfile?.type_attributes?.top_fans_infos.completed_counter >= 2;
  const isShowMegaGiftSection =
    !!rookieProfile?.type_attributes?.mega_gifts_count;
  const megaGiftAmount = rookieProfile?.type_attributes?.mega_gifts_count;
  const topFanAmount =
    rookieProfile?.type_attributes?.min_morgi_to_open_connection;
  const minAmountToReachTopFan =
    rookieProfile?.type_attributes?.top_fans_infos?.min_amount_to_reach;

  const MEGA_GIFT = {
    cost: 5000,
    dollarAmount: 500,
  };

  return (
    <div>
      {isShowMegaGiftSection && isShowTopFanSection && (
        <h4 className={classes.achievementsTitle}>
          {t("rookie_profile_page.my_achievements_section.title")}
        </h4>
      )}

      {isShowMegaGiftSection && (
        <div className={classes.achivementsBanner}>
          <div className={classes.profileBannerImgWrapper}>
            <img src={megaGiftSmall} />
          </div>

          <div className={classes.profileBannerInfo}>
            <p>{t("rookie_profile_page.my_achievements_section.mega_gift")}</p>
            <div className={classes.profileBannerCost}>
              <p>{t("rookie_profile_page.my_achievements_section.cost")}</p>
              <MicromorgiIcon />
              <span>{MEGA_GIFT.cost}</span>
            </div>
            <div className={classes.profileBannerDescription}>
              {t("rookie_profile_page.my_achievements_section.gift_received")}
              <p>{megaGiftAmount}</p>
            </div>
          </div>
        </div>
      )}

      {isShowTopFanSection && (
        <div className={classes.achivementsBanner}>
          <div className={classes.profileBannerImgWrapper}>
            <TopMegaFanIcon />
          </div>
          <div className={classes.profileBannerInfo}>
            <p>{t("rookie_profile_page.my_achievements_section.top_fan")}</p>
            <div className={classes.profileBannerCost}>
              <p>{t("rookie_profile_page.my_achievements_section.cost")}</p>
              <MicromorgiIcon />
              <span>{minAmountToReachTopFan}</span>
            </div>
            <div className={classes.profileBannerDescription}>
              {t("rookie_profile_page.my_achievements_section.gift_received")}
              <p>{topFanAmount}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RookieAchievementSection;
