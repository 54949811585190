export const ROUTE_FAQ = "/faq";
export const ROUTE_CAROUSEL = "/carousel";
export const ROUTE_HOME = "/";
export const ROUTE_NOT_FOUND = "/404";
export const ROUTE_A_WORD_FROM_PRESIDENT = "/a-word-from-president";
export const ROUTE_OUR_MISSION = "/our-mission";
export const ROUTE_WHAT_IS_MORGI = "/what-is-morgi";
export const ROUTE_WHO_WE_ARE = "/who-we-are";
export const ROUTE_COOKIE_POLICY = "/cookie-policy";
export const ROUTE_TOS_LEADER = "/tos-friend";
export const ROUTE_TOS_ROOKIE = "/tos-host";
export const ROUTE_TOS = "/tos";
export const ROUTE_PRIVACY_POLICY = "/privacy-policy";
export const ROUTE_COMMUNITY_GUILDINES = "/community-guidlines";
export const ROUTE_ROOKIE_PROFILE = "/:username";
export const ROUTE_JOIN_AS_LEADER = "/join-as-guest";
export const ROUTE_LANDING_ROOKIE = "/host";
export const ROUTE_FAQ_ROOKIE = "/faq-host";
export const ROUTE_FAQ_LEADER = "/faq-guest";
export const ROUTE_GOAL_PAGE = "/goal/:slug";
export const ROUTE_LINK_ACCOUNTS = '/auth/link-accounts'
export const ROUTE_LANDING_GUEST = "/guest";
export const ROUTE_COMPETITION = "/competition-waivers";
export const ROUTE_CHECK_PAYMENT = '/payment-status'

export const ROUTE_SIGNUP_SPECIAL_HOST = '/auth/signup-special-host'

export const HOSTS_TOUR_GENERAL_LINK = 'https://stars.morgis.com'
export const HOSTS_TOUR_ATHLETES_LINK = 'https://stars.morgis.com/en/athlete'
export const HOSTS_TOUR_ARTISTS_SHOWBIZ_LINK = 'https://stars.morgis.com/en/artists'
export const HOSTS_TOUR_ENTREPRENEURS_LINK = 'https://stars.morgis.com/en/entrepreneurs'
export const HOSTS_TOUR_INFLUENCERS_LINK = 'https://stars.morgis.com/en/influencer'
export const HOSTS_TOUR_LICENSED_PROFESSIONALS_LINK = 'https://stars.morgis.com/en/licensed'
export const HOSTS_TOUR_MODELS_LINK = 'https://stars.morgis.com/en/models'
export const HOSTS_TOUR_TEACHERS_COACHES_LINK = 'https://stars.morgis.com/en/teachers'
