import React, {useState} from 'react';
import {createUseStyles} from "react-jss";
import {DropdownArrowIcon} from "../assets/icons/icons";
import {isElement} from "react-is";
import {useTranslation} from "react-i18next";
import {getAvailableNamespaces} from "../translations";
import PropTypes from "prop-types";
import cx from 'classnames';


/* *
* Usage:
* <Accordion isMain defaultIndex={1} onItemClick={Foo}>
    <Accordion defaultIndex={1} onItemClick={Foo} label="Main Topic #1">
        <AccordionItem label="Sub Topic #1">
         <p>Lorem ipsum</p>
        </AccordionItem>
    <Accordion/>
    <AccordionItem label="Sub Topic #1">
      Lorem ipsum
    </AccordionItem>
    <AccordionItem label="Sub Topic #2">
      Dolor sit amet
    </AccordionItem>
  </Accordion>
*
* */


const useStyles = createUseStyles(theme => ({
    collapsed: {
        display: 'none'
    },
    expanded: {
        display: 'block'
    },
    accordionWrapper: {

    },
    itemWrapper: {
        marginBottom: 32,
        paddingLeft: 16,
        borderLeft: ({isCollapsed}) => isCollapsed  ? `1px solid ${theme.colors.transparent}` : `1px solid ${theme.colors.black}`,
        '& p': {
            color: theme.colors.darkPurple,
            opacity: 0.5,
            fontSize: 14
        },
        '& ul': {
            color: theme.colors.darkPurple,
            opacity: 0.5,
            fontSize: 14
        }
    },
    mainTopic: {
        cursor: 'pointer',
        outline: 'none',
        border: 'none',
        background: 'none',
        width: '100%',
        margin: [0, 0, 16, 0],
        padding: 0,
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'start',
        gridTemplateColumns: '1fr 24px',
        textAlign:'left',
        color: ({isCollapsed}) => isCollapsed ? theme.colors.darkPurple : theme.colors.primary,
        fontWeight: 600,
        fontSize: 16,
        '& > svg': {
            transform: ({isCollapsed}) => isCollapsed ? 'rotate(0)' : 'rotate(180deg)',
            justifySelf: 'end'
        }
    },
    secondaryTopic: {
        composes: '$mainTopic',
        color: theme.colors.darkPurple,
        fontWeight: 400,
        '& > svg': {
            transform: ({isCollapsed}) => isCollapsed ? 'rotate(0)' : 'rotate(180deg)',
            justifySelf: 'end'
        }
    }

}))

export const AccordionItem = ({isCollapsed, children, label, handleClick, ...rest}) => {

    const classes = useStyles({isCollapsed});

    return (
        <div className={classes.itemWrapper}>
            <button className={classes.secondaryTopic} onClick={handleClick}>
                {label}
                <DropdownArrowIcon/>
            </button>
            <div
                className={isCollapsed ?  classes.collapsed : classes.expanded}
                aria-expanded={isCollapsed}
            >
                {children}
            </div>
        </div>
    );
}



const Accordion = ({
                       isMain = false,
                       defaultExpandedChildIndex = 0,
                       onItemClick,
                       children,
                       label,
                       className,
                       isCollapsed,
                       handleClick
}) => {

    const classes = useStyles({isCollapsed});
    const [t] = useTranslation(getAvailableNamespaces(), {useSuspense: false});
    const [expandedChildIndexes, setExpandedChildIndexes] = useState([defaultExpandedChildIndex]);

    const changeItem = itemIndex => {
        if (typeof onItemClick === 'function') onItemClick();
        if(expandedChildIndexes.includes(itemIndex)){
            setExpandedChildIndexes([...expandedChildIndexes.filter(item => item !== itemIndex)])
        }else{
            setExpandedChildIndexes([...expandedChildIndexes, itemIndex])
        }
    };

    const renderChildren = () => {
        if(Array.isArray(children)){
            return children
                .map( ({ props, type: AccordionChild }, index) => (
                    <AccordionChild
                        isCollapsed={!expandedChildIndexes.includes(index)}
                        label={t(props.label)}
                        handleClick={() => changeItem(index)}
                        children={props.children}
                    />
                ))
        }else{
            if(isElement(children)){
                const { type, props } = children;
                const AccordionChild = type;
                return <AccordionChild
                    isCollapsed={!expandedChildIndexes.includes(0)}
                    label={t(props.label)}
                    handleClick={() => changeItem(0)}
                    children={props.children}
                />
            }else{
                return children
            }
        }
    }

    return (
        <div className={cx(classes.accordionWrapper,className)}>
            {!isMain &&
                <button className={classes.mainTopic} onClick={handleClick}>
                    {label}
                    <DropdownArrowIcon/>
                </button>
            }
            <div
                className={isCollapsed ?  classes.collapsed : classes.expanded}
                aria-expanded={isCollapsed}
            >
                {renderChildren()}
            </div>
        </div>
    );
}

Accordion.propTypes = {
    isMain: PropTypes.bool,
    defaultExpandedChildIndex: PropTypes.number,
    onItemClick: PropTypes.func,
    label: PropTypes.string,
    isCollapsed: PropTypes.bool,
    handleClick: PropTypes.func
};

export default Accordion;