import React from 'react';
import Popover from "./Popover";
import {createUseStyles} from "react-jss";
import {
    EmailIcon, 
    FacebookIcon, 
    LinkIcon, 
    TextMessageIcon, 
    TwitterIcon, 
    TelegramMonoIcon, 
    WhatsappMonoIcon
} from "../assets/icons/icons";
import {useTranslation} from "react-i18next";
import {getAvailableNamespaces} from "../translations";
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton
} from "react-share";
import {copyTextToClipboard} from "../utilities/utils";
import Tippy from '@tippyjs/react';


const useStyles = createUseStyles(theme => ({
    popover: {
        overflowY: 'scroll',
        maxHeight: '100%',
        zIndex: 1033,
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    },
    body: {
        paddingBottom: 0,
    },
    root: {},
    title: {
        fontSize: 22,
        fontWeight: 400,
        letterSpacing: '-0.05em',
        textAlign: 'center',
        color: theme.colors.darkPurple,
        marginBottom: theme.spacing * 3,
        marginTop: 0,
    },
    list: {
        display: 'grid',

        gridTemplateColumns: '1fr',
        width: `calc(100% + ${theme.spacing * 6}px)`,
        marginLeft: -theme.spacing * 3,
        marginRight: -theme.spacing * 3,
        padding: [0, theme.spacing * 3],
    },
    listItem: {
        display: 'grid',
        gridTemplateColumns: '0fr 1fr',
        border: 'none',
        backgroundColor: 'transparent',
        height: 60,
        alignItems: 'center',
        textAlign: 'left',
        padding: [0, theme.spacing * 3],
        borderBottom: `1px solid ${theme.colors.lightGrey} !important`,
        '&:hover': {
            backgroundColor: theme.colors.lightGrey + '!important'
        },
        cursor: 'pointer',
    },
    listWrapper: {
        display: 'grid',
        textAlign: 'left',
        '&>div': {
            padding: 0
        },
        '&>button': {
            padding: 0
        }
    },
    icon: {
        width: '20px !important',
        height: 20,
        color: theme.colors.black,
        marginRight: theme.spacing * 2,
    },
    text: {
        fontSize: 16,
        fontWeight: 700,
        color: theme.colors.darkPurple
    }
}));

const ShareModal = props => {
    const {url, onClose, options, message} = props;
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {useSuspense: false});

    const mergedOptions = {
        facebook: true,
        twitter: true,

        telegram: true,
        whatsapp: true,
        textMessage: false,
        email: true,
        link: true,
        ...options
    };

    return (
        <Popover
            title={t('common:share')}
            withHeader
            width={'90%'}
            maxWidth={'345px'}
            onClose={onClose}
            className={classes.popover}
            bodyClassName={classes.body}
        >
            <div className={classes.root}>
                <h2 className={classes.title}>Share with friends!</h2>

                <div className={classes.list}>
                    <div className={classes.listWrapper}>
                        <h3>{t('common:messaging')}</h3>
                        {mergedOptions.telegram && (
                            <TelegramShareButton resetButtonStyle={false} className={classes.listItem}
                                                 url={`${message} ${url}`}>
                                <TelegramMonoIcon className={classes.icon}/>
                                <span className={classes.text}>{t('share.telegram')}</span>
                            </TelegramShareButton>
                        )}
                        {mergedOptions.whatsapp && (
                            <WhatsappShareButton resetButtonStyle={false} className={classes.listItem}
                                                 url={`${message} ${url}`}>
                                <WhatsappMonoIcon className={classes.icon}/>
                                <span className={classes.text}>{t('share.whatsapp')}</span>
                            </WhatsappShareButton>
                        )}
                        {mergedOptions.textMessage && (
                            <div className={classes.listItem}>
                                <TextMessageIcon className={classes.icon}/>
                                <span className={classes.text}>{t('share.text_message')}</span>
                            </div>
                        )}
                        {mergedOptions.email && (
                            <EmailShareButton resetButtonStyle={false} 
                                            className={classes.listItem} 
                                            url={`${message} ${url}`}
                                            subject={t('share.email_subject')}>
                                <EmailIcon className={classes.icon}/>
                                <span className={classes.text}>{t('share.email')}</span>
                            </EmailShareButton>
                        )}
                        {mergedOptions.link && (
                            <Tippy
                                theme="dark"
                                trigger="click"
                                inlinePositioning={true}
                                content={t('share.tooltip_content')}
                                duration={200}
                                hideOnClick={false}
                                onShow={(instance) => {
                                    setTimeout(() => {
                                        instance.hide();
                                    }, 2000);
                                }}
                            >
                                <div className={classes.listItem} onClick={() => copyTextToClipboard(url)}>
                                    <LinkIcon className={classes.icon}/>
                                    <span className={classes.text}>{t('share.copy_link')}</span>
                                </div>
                            </Tippy>
                        )}
                    </div>
                    <div className={classes.listWrapper}>
                        <h3>{t('common:social')}</h3>
                        {mergedOptions.facebook && (
                            <FacebookShareButton resetButtonStyle={false}
                                                 url={url}
                                                 quote={message}
                                                 className={classes.listItem}
                            >
                                <FacebookIcon className={classes.icon}/>
                                <span className={classes.text}>{t('share.facebook')}</span>
                            </FacebookShareButton>

                        )}
                        {mergedOptions.twitter && (
                            <TwitterShareButton resetButtonStyle={false} className={classes.listItem}
                                                url={`${message} ${url}`}>
                                <TwitterIcon className={classes.icon}/>
                                <span className={classes.text}>{t('share.twitter')}</span>
                            </TwitterShareButton>
                        )}
                    </div>
                </div>
            </div>
        </Popover>
    );
};

export default ShareModal;
