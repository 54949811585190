import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {getAvailableNamespaces} from "../translations";

const PageMeta = ({title, ogImage, ogTitle, ogDescription}) => {
    const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });

    return <Helmet>
        {title && <title>{t(title)} - Morgis.com</title>}
        {ogImage && <meta property="og:image" content={ogImage}/>}
        {ogTitle && <meta property="og:title" content={t(ogTitle)}/>}
        {ogDescription && <meta property="og:description" content={t(ogDescription)}/>}
    </Helmet>
}

export default PageMeta;
