import React from 'react';
import Accordion, {AccordionItem} from '../../components/Accordion';
import {createUseStyles} from 'react-jss';
import {Trans, useTranslation} from "react-i18next";
import {getAvailableNamespaces} from "../../translations";


const useStyles = createUseStyles(theme => ({
    wrapper: {
        padding: 15
    },
    heading: {
        textAlign: 'center',
        fontSize: 22,
        fontWeight: 400,
        margin: '15px 0px',
        lineHeight: '27px'
    },
    title: {
        ...theme.pageStyle.title
    },
    subtitle: {
        ...theme.pageStyle.subtitle
    },
    titleWrapper: {
        ...theme.pageStyle.titleWrapper,
        padding: [16, 0, 0, 0]
    },
    accordion: {
        margin: '15px 0px',
        '& div:nth-child(1)': {
            padding: 0,
            border: 0,
            margin: '30px 0px',
            '& h4': {
                fontSize: 14,
                fontWeight: 'normal',

            },
            '& button': {
                fontWeight: 'bold'
            },
            '& li': {
                marginBottom: 16
            }
        }
    },
    subAccordion: {
        margin: '15px 0px 15px 15px !important',
        '& div:nth-child(1)': {
            padding: 0,
            border: 0,
            margin: '30px 0px',
            '& h4': {
                fontSize: 14,
                fontWeight: 400,

            },
            '& button': {
                fontWeight: 400
            },
            '& li': {
                marginBottom: 16
            },
            '& p': {
                paddingRight: "15px"
            }
        }
    }
}));


const FaqLeader = () => {

    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {react: {useSuspense: false}});

    return (
        <>
            <div className={classes.titleWrapper}>
                <h2 className={classes.title}>{t('menu.faq_for_leaders')}</h2>
            </div>

            <div className={classes.wrapper}>
                <Accordion isMain className={classes.accordion}>
                    <AccordionItem
                        label={t('static_pages.faq_leader.1.title')}
                        index="1"
                    >
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.faq_leader.1.text1"
                                components={{ underline: <u /> }}
                            />
                        </p>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.1.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.1.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section2.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.1.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section3.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.1.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section4.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section4.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.1.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section5.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.1.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section6.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section6.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section6.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.1.section7.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section7.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.1.section7.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem label={t('static_pages.faq_leader.3.title')}>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section6.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section7.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section7.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section8.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section8.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section9.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section9.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section10.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section10.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section11.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section11.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section12.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section12.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section13.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section13.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section14.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section14.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section15.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section15.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section16.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section16.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section17.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section17.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section18.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section18.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section19.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section19.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section20.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section20.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.3.section21.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.3.section21.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem label={t('static_pages.faq_leader.2.title')}>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.2.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.2.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.2.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.2.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section4.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section4.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.2.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section5.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section5.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.2.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section6.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.2.section7.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section7.title1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section7.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section7.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section7.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section7.text4"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section7.text5"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.2.section8.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section8.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section8.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section8.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.2.section9.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section9.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section9.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.2.section10.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section10.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.2.section10.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem
                        label={t('static_pages.faq_leader.10.title')}
                    >
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.faq_leader.10.title1"
                                components={{ underline: <u /> }}
                            />
                        </p>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.10.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section1.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section1.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.10.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.10.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section3.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section3.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.10.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section4.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section4.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section4.text4"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section4.text5"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section4.text6"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section4.text7"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section4.text8"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section4.text9"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section4.text10"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section4.text11"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section4.text12"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section4.text13"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.10.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.10.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section6.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.10.section7.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section7.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section7.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section7.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.10.section8.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section8.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section8.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.10.section9.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section9.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.10.section10.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section10.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section10.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.10.section11.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section11.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section11.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section11.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.10.section12.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section12.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.10.section12.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem label={t('static_pages.faq_leader.7.title')}>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.faq_leader.7.title1"
                                components={{ underline: <u /> }}
                            />
                        </p>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.7.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.7.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.7.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.7.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.7.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.7.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.7.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.7.section3.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.7.section3.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.7.section3.text4"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.7.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.7.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.7.section4.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.7.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.7.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.7.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.7.section6.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.7.section6.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem label={t('static_pages.faq_leader.4.title')}>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.faq_leader.4.title1"
                                components={{ underline: <u /> }}
                            />
                        </p>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.4.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.4.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.4.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.4.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.4.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.4.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.4.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.4.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.4.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.4.section4.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem label={t('static_pages.faq_leader.8.title')}>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.faq_leader.8.title1"
                                components={{ underline: <u /> }}
                            />
                        </p>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section1.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section1.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section1.text4"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section1.text5"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section3.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section4.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section4.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section5.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section5.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section5.text4"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section5.text5"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section6.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section6.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section7.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section7.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section7.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section7.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section8.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section8.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section9.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section9.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section10.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section10.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section10.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section11.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section11.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section12.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section12.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section13.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section13.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section14.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section14.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section15.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section15.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section16.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section16.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.8.section17.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.8.section17.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem
                        label={t('static_pages.faq_leader.13.title')}
                    >
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.faq_leader.13.title1"
                                components={{ underline: <u /> }}
                            />
                        </p>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section1.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section1.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section2.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section3.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section4.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section4.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section6.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section6.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section7.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section7.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section8.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section8.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section9.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section9.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section10.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section10.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section10.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section11.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section11.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section11.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section11.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section12.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section12.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section12.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section13.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section13.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section14.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section14.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section15.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section15.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section16.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section16.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section16.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section17.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section17.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section17.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section17.text3"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section17.text4"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>

                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.13.section18.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.13.section18.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem
                        label={t('static_pages.faq_leader.14.title')}
                    >
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.faq_leader.14.title1"
                                components={{ underline: <u /> }}
                            />
                        </p>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.14.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.14.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.14.section1.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.14.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.14.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.14.section2.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.14.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.14.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem label={t('static_pages.faq_leader.9.title')}>
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.faq_leader.9.title1"
                                components={{ underline: <u /> }}
                            />
                        </p>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.9.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.9.section1.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.9.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.9.section2.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.9.section3.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.9.section3.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.9.section3.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.9.section4.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.9.section4.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.9.section5.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.9.section5.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.9.section6.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.9.section6.text1"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.9.section6.text2"
                                        components={{ underline: <u /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>

                <Accordion
                    isMain
                    className={classes.accordion}
                    defaultExpandedChildIndex={-1}
                >
                    <AccordionItem
                        label={t('static_pages.faq_leader.12.title')}
                    >
                        <p>
                            <Trans
                                t={t}
                                i18nKey="static_pages.faq_leader.12.title1"
                                components={{ strong: <strong /> }}
                            />
                        </p>
                        <Accordion isMain className={classes.subAccordion}>
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.12.section1.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.12.section1.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.12.section1.text2"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.12.section1.text3"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.12.section1.text4"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                        <Accordion
                            isMain
                            className={classes.subAccordion}
                            defaultExpandedChildIndex={-1}
                        >
                            <AccordionItem
                                label={t(
                                    'static_pages.faq_leader.12.section2.title'
                                )}
                                index="1"
                            >
                                <p>
                                    <Trans
                                        t={t}
                                        i18nKey="static_pages.faq_leader.12.section2.text1"
                                        components={{ strong: <strong /> }}
                                    />
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </AccordionItem>
                </Accordion>
            </div>
        </>
    )
}


export default FaqLeader;
