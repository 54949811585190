import { SnapGoalDefaultIcon, SpecialGoalDefaultIcon, GrandeGoalDefaultIcon } from "../../assets/icons/icons";

export const GOAL_STATUSES = {
  active: "active",
  awaitingProof: "awaiting_proof",
  pending: "proof_pending_approval",
  pending_admin_approval: "pending",
  default: "default",
  success: "successful",
  proofDeclined: "proof_status_declined",
  cancelled: "cancelled",
  suspended: "suspended",
  review: "review",
};

export const strokeColor = {
  success: ["#6BDAB2", "#60C29F", "#00955F"],
  default: ["#8649D1", "#8B6DE8", "#8F92FF"],
  fail: ["#EC8383", "#E66060", "#DF3030"],
};

export const GOAL_SIZE = {
  snap: 1,
  special: 2,
  grande: 3,
}

export const goalCardDefoultContent = {
  [GOAL_SIZE.snap]: {
      icon: <SnapGoalDefaultIcon />,
      type: 'snap',
  },
  [GOAL_SIZE.special]: {
      icon: <SpecialGoalDefaultIcon />,
      type: 'special',
  },
  [GOAL_SIZE.grande]: {
      icon: <GrandeGoalDefaultIcon />,
      type: 'grande',
  },
}
