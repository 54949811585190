import {isElement, isValidElementType} from "react-is";
import { runtimeConfig } from '../config';

export const retrieveValueForRs = (options, value) => {
    if (value === null || value === '' || value === undefined) return null;
    return options.filter(option => option.value.toString() === value.toString())
};

export const toReactElement = (item) => {
    const Element = isValidElementType(item) ?  item : null;
    return Element && <Element/> || isElement(item) && item;
}

export const getAppLink = (appRouteName = '', searchParams = null) => {
    const url = new URL(runtimeConfig.appHost)
    url.pathname = appRouteName;
    if(!!searchParams?.length){
        searchParams.forEach(({name, value}) => {
            url.searchParams.append(name, value)
        })
    }
    return url.toString();
}

export const redirectToExternalLink = externalLink => {
    if (typeof window !== 'undefined') window.location.href = externalLink;
}

export const computeAge = (birthday) => {
    const bDay = new Date(birthday);
    const ageDifMs = Date.now() - bDay.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const arrayShuffle = array => [...array].sort(() => Math.random() -0.5);

export const copyTextToClipboard = text => {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
}

export const remapURLSearchParams = searchQuery => {
    const params = new URLSearchParams(searchQuery);
    const paramsRemap = [];
    for (const [key, value] of params.entries()) {
        paramsRemap.push({
            name: key,
            value
        })
    }
    return paramsRemap;
}

export const FAQ_TABLE = {
    paymentPeriod: {
        header: [
            'static_pages.faq_rookie.7.section2.table.header1',
            'static_pages.faq_rookie.7.section2.table.header2',
            'static_pages.faq_rookie.7.section2.table.header3',
        ],
        firstRow: [
            'static_pages.faq_rookie.7.section2.table.row1_text1',
            'static_pages.faq_rookie.7.section2.table.row1_text2',
            'static_pages.faq_rookie.7.section2.table.row1_text3',
        ],
        secondRow: [
            'static_pages.faq_rookie.7.section2.table.row2_text1',
            'static_pages.faq_rookie.7.section2.table.row2_text2',
            'static_pages.faq_rookie.7.section2.table.row2_text3',
        ]
    },
    paymentShare: {
        header: [
            'static_pages.faq_rookie.9.section5.table.header1',
            'static_pages.faq_rookie.9.section5.table.header2',
            'static_pages.faq_rookie.9.section5.table.header3',
        ],
        firstRow: [
            'static_pages.faq_rookie.9.section5.table.row1_text1',
            'static_pages.faq_rookie.9.section5.table.row1_text1',
            'static_pages.faq_rookie.9.section5.table.row1_text3',
        ],
        secondRow: [
            'static_pages.faq_rookie.9.section5.table.row2_text1',
            'static_pages.faq_rookie.9.section5.table.row2_text2',
            'static_pages.faq_rookie.9.section5.table.row2_text3',
        ]
    }
}

export const getExtension = (url) => {
    return url.split('.').pop()
}


