import settingReducer from "./settingSlice";
import userReducer from './userSlice';
import carouselSlice from "./carouselSlice";

const reducer = {
    setting: settingReducer,
    user:userReducer,
    carousel: carouselSlice,
}

export default {
    reducer
}