import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {httpGetRookiesByParams} from "../httpRequests/carousel.http";

export const fetchRookiesByPath = createAsyncThunk(
    'carousel/rookiesByPath',
    async({path}) => {
        const {data} = await httpGetRookiesByParams({path});
        return data;
    }
);


const carouselSlice = createSlice({
        name: 'carousel',
        initialState: {
            rookies: [],
            hasToRefresh: true,
            lastIndex: 0,
            lastLeaderCardIndex: 0
        },
        reducers: {
            setCardIndex: (state, action) => {
                state.lastIndex = action.payload;
            },
            setRefreshCarousel: (state, action) => {
                state.hasToRefresh = action.payload;
            },
            setRookies: (state, action) => {
                state.rookies = action.payload;
            },
            setLeaderCardIndex: (state, action) => {
                state.lastLeaderCardIndex = action.payload;
            },
        },
        extraReducers: {
            [fetchRookiesByPath.fulfilled]: (state, action) => {
                state.rookies = state.rookies.concat(action.payload);
            }
        }
    }
)

const selectSelf = (state) => state.carousel;
export const selectRookies = createSelector(selectSelf, state => state.rookies)
export const selectLastIndex = createSelector(selectSelf, state => state.lastIndex)
export const selectHadToRefresh = createSelector(selectSelf, state => state.hasToRefresh)
export const selectLastLeaderCardIndex = createSelector(selectSelf, state => state.lastLeaderCardIndex)
export const { setCardIndex, setRefreshCarousel, setRookies, setLeaderCardIndex } = carouselSlice.actions

export default carouselSlice.reducer