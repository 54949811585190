import {createUseStyles} from 'react-jss';
import Button from "../../components/Button";
import {useTranslation} from "react-i18next";
import {getAvailableNamespaces} from "../../translations";
import Page404 from "../../assets/images/404.svg"
import {LinkWithQuery} from "../../components/LinkWithQuery";

const useStyles = createUseStyles(theme => ({

    row: {
        composes: 'row',
        ...theme.bootstrapGridUtils.h100
    },
    root: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign:'center',
        height: '100%'
    },
    title: {
        marginBottom: 32,
        '& h2': {
            fontSize: 22,
            fontWeight: 400
        }
    },
    message: {
        marginBottom: 32,
        '& p':{
            whiteSpace: 'pre-wrap',
            fontSize: 14,
            color: theme.colors.darkPurpleMidOpacity
        }
    },
    cta: {
        '& > a': {
            textDecoration: 'none'
        }
    }
}));

const PageNotFound = () => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });

    return (
        <div className={classes.row}>
            <div className="col">
                <div className={classes.root}>
                    <div>
                        {/* TODO: change image with correct one */}
                        <img src={Page404}/>
                        <div className={classes.title}>
                            <h2>
                                {t('not_found.title')}
                            </h2>
                        </div>
                        <div className={classes.message}>
                            <p>
                                {t('not_found.message')}
                            </p>
                        </div>
                        <div className={classes.cta}>
                            <LinkWithQuery to={'/'}>
                                <Button
                                    width={'100%'}
                                >
                                    {t('not_found.cta')}
                                </Button>
                            </LinkWithQuery>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageNotFound;
