import React, {useEffect, useContext, useState} from 'react';
import { useLocation } from 'react-router';
import { createUseStyles } from 'react-jss';
import {useTranslation} from "react-i18next";
import {getAvailableNamespaces} from "../../translations";
import {httpFetchRookie} from "../../httpRequests/rookie.http"
import Context from "../../Context";
import maskImg from "../../assets/images/mask-image.png"
import lock from "../../assets/images/bubble.png"
import Button from "../../components/Button";
import {getAppLink, redirectToExternalLink, remapURLSearchParams} from "../../utilities/utils";
import {KNOWN_COOKIES, getUserGroup} from "../../utilities/constants/user";
import {APP_ROUTE_SIGNUP_LEADER_SOCIAL} from "../../utilities/constants/appRouteNames";
import {PARAM_REFERRAL_CODE, PARAM_USER_GROUP, ROUTE_HOME, ROUTE_JOIN_AS_LEADER} from "../../utilities/constants";
import Spinner from "../../components/Spinner"
import {Helmet} from "react-helmet";
import Cookies from 'js-cookie';

const useStyles = createUseStyles(theme => ({
    wrapper: {
        maxWidth: 640,
        margin: '0 auto',
        [theme.mUp]:{
            padding: [56, 0]
        }
    },
    titleWrapper: {
        marginTop: "20px",
        textAlign: "center",
        fontSize: "26px",
        maxWidth: "90%",
        fontWeight: 700,
        margin: "0 auto"
    },
    rookieName: {
        color: theme.colors.primary,
    },
    avatarWrapper: {
        marginTop: "42px",
        textAlign: "center",
        "& span": {
            position: "relative"
        }
    },
    avatar: {
        marginRight: "25px",
        height: "97px",
        width: "108px",
        maskImage: `url(${maskImg})`,
        objectFit: 'cover'
    },
    lock: {
        position: "absolute",
        height: "63px",
        width: "69px",
        top: "-34px",
        left: "70px"
    },
    description: {
        maxWidth: "90%",
        margin: "0 auto",
        textAlign: "center",
        marginTop: "42px",
        fontSize: "18px",
        color: theme.colors.blackOpacityMid
    },
    link: {
        textDecoration: "none"
    },
    button: {
        padding: "14px 21px",
        margin: "34px auto",
        color: theme.colors.white,
    },
    bottomText: {
        color: theme.colors.primary,
        textAlign: "center",
        fontSize: "26px",
        fontWeight: 700,
        maxWidth: "75%",
        margin: "0 auto"
    }
}));

const fetchComponentData = async ({searchQuery}) => {
    let searchParams = new URLSearchParams(searchQuery);
    const rookieUserName = searchParams.get(PARAM_REFERRAL_CODE);
    return rookieUserName ? httpFetchRookie(rookieUserName) : null;
}

const JoinAsLeaderPage = (props) => {
    const classes = useStyles();
    const context = useContext(Context);
    const [t] = useTranslation(getAvailableNamespaces(), { react: { useSuspense: false } });
    const [rookie, setRookie] = useState(context?.componentData && {...context?.componentData, ...context?.componentData?.type_attributes})
    const [isLoading, setIsLoading] = useState(true)
    const query = new URLSearchParams(props.location.search);
    const rookieUserName = query.get(PARAM_REFERRAL_CODE)
    const location = useLocation();

    if (location.pathname === ROUTE_JOIN_AS_LEADER && Cookies.get(KNOWN_COOKIES.authCookie)) {
        redirectToExternalLink(getAppLink(`${ROUTE_HOME}${rookieUserName}`));
    }

    const {
        type_attributes,
        full_name: fullname,
        photos
    } = rookie || {}

    const fetchRookie = async() => {
        try {
            setIsLoading(true)
            const {data} = await fetchComponentData({param: rookieUserName})
            const rookieProfile = { ...data, ...data?.type_attributes }
            setRookie(rookieProfile)
        } catch(e) {
        }finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(!rookie || rookie && !Object.values(rookie).length ) {
            fetchRookie()
        }else{
            setIsLoading(false)
        }
    }, [])

    return <>
        <Helmet>
            {photos?.length && <meta property="og:image" content={`${photos[0].url}`}/>}
            {type_attributes?.first_name && <meta property="og:title" content={`Join as ${fullname}'s Friend - Morgis.com`}/>}
        </Helmet>
        {
            isLoading ?
                <Spinner/> :
                <div className={classes.wrapper}>
                    <div className={classes.titleWrapper}>
                        <span className={classes.rookieName}>{t('join_as_leader_page.rookie_name', {ROOKIE_FIRST_NAME: type_attributes?.first_name, ROOKIE_LAST_NAME: type_attributes?.last_name})}</span>
                        <span>{t('join_as_leader_page.title')}</span>
                    </div>
                    <div className={classes.avatarWrapper}>
                    <span>
                        <img className={classes.avatar} src={rookie?.avatar?.url} alt="avatar" />
                        <img className={classes.lock} src={lock} alt="lock"/>
                    </span>
                    </div>
                    <div className={classes.description}>
                        {t('join_as_leader_page.description')}
                        <span> {t('join_as_leader_page.rookie_name', {ROOKIE_FIRST_NAME: type_attributes?.first_name, ROOKIE_LAST_NAME: type_attributes?.last_name})}</span>
                    </div>
                    <a className={classes.link} href={getAppLink(APP_ROUTE_SIGNUP_LEADER_SOCIAL, [
                        ...remapURLSearchParams(location?.search),
                        {
                            name: PARAM_USER_GROUP,
                            value: getUserGroup()
                        }
                    ])}>
                        <Button data-primary className={classes.button}>
                            {t('join_as_leader_page.button_text', {ROOKIE_FIRST_NAME: type_attributes?.first_name})}
                        </Button>
                    </a>
                    <div className={classes.bottomText}>{t('join_as_leader_page.bottom_text')}</div>
                </div>
        }
    </>

}

export default JoinAsLeaderPage

JoinAsLeaderPage.serverFetch = fetchComponentData;
