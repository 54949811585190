import anonP0S1EN from "../../assets/images/anonPage/AnonRookie/anonP0S1EN.png";
import anonP0S1ESP from "../../assets/images/anonPage/AnonRookie/anonP0S1ESP.png";
import anonP0S1PT_BR from "../../assets/images/anonPage/AnonRookie/anonP0S1PT_BR.png";
import anonP0S2EN from "../../assets/images/anonPage/AnonRookie/anonP0S2EN.png";
import anonP0S2ESP from "../../assets/images/anonPage/AnonRookie/anonP0S2ESP.png";
import anonP0S2PT_BR from "../../assets/images/anonPage/AnonRookie/anonP0S2PT_BR.png";
import anonP0S3EN from "../../assets/images/anonPage/AnonRookie/anonP0S3EN.png";
import anonP0S3ESP from "../../assets/images/anonPage/AnonRookie/anonP0S3ESP.png";
import anonP0S3PT_BR from "../../assets/images/anonPage/AnonRookie/anonP0S3PT_BR.png";
import anonP0S4EN from "../../assets/images/anonPage/AnonRookie/anonP0S4EN.png";
import anonP0S4ESP from "../../assets/images/anonPage/AnonRookie/anonP0S4ESP.png";
import anonP0S4PT_BR from "../../assets/images/anonPage/AnonRookie/anonP0S4PT_BR.png";

import mobileImg1EN from "../../assets/images/anonPage/mobileImages/mobileImg1EN.png"
import mobileImg1PT from "../../assets/images/anonPage/mobileImages/mobileImg1PT.png"
import mobileImg1ES from "../../assets/images/anonPage/mobileImages/mobileImg1ES.png"
import mobileImg2EN from "../../assets/images/anonPage/mobileImages/mobileImg2EN.png"
import mobileImg2PT from "../../assets/images/anonPage/mobileImages/mobileImg2PT.png"
import mobileImg2ES from "../../assets/images/anonPage/mobileImages/mobileImg2ES.png"
import mobileImg3EN from "../../assets/images/anonPage/mobileImages/mobileImg3EN.png"
import mobileImg3PT from "../../assets/images/anonPage/mobileImages/mobileImg3PT.png"
import mobileImg3ES from "../../assets/images/anonPage/mobileImages/mobileImg3ES.png"
import mobileImg4EN from "../../assets/images/anonPage/mobileImages/mobileImg4EN.png"
import mobileImg4PT from "../../assets/images/anonPage/mobileImages/mobileImg4PT.png"
import mobileImg4ES from "../../assets/images/anonPage/mobileImages/mobileImg4ES.png"


export const dataAccordingToPersonaForRookie = {
  0: [
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
  ],
  7: [
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
  ],
  8: [
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
  ],
  9: [
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
  ],
  10: [
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
  ],
  11: [
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
  ],
};

export const dataAccordingToPersonaForLeader = {
  0:[
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
  ],
  7: [
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
  ],
  8: [
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
  ],
  9:[
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
  ],
  10: [
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
  ],
  11:[
    { EN: anonP0S1EN, PT: anonP0S1PT_BR, ES: anonP0S1ESP },
    { EN: anonP0S2EN, PT: anonP0S2PT_BR, ES: anonP0S2ESP },
    { EN: anonP0S3EN, PT: anonP0S3PT_BR, ES: anonP0S3ESP },
    { EN: anonP0S4EN, PT: anonP0S4PT_BR, ES: anonP0S4ESP },
  ],
};

export const dataForMobile = {
  0: [
    { EN: mobileImg1EN, PT: mobileImg1PT, ES: mobileImg1ES },
    { EN: mobileImg2EN, PT: mobileImg2PT, ES: mobileImg2ES },
    { EN: mobileImg3EN, PT: mobileImg3PT, ES: mobileImg3ES },
    { EN: mobileImg4EN, PT: mobileImg4PT, ES: mobileImg4ES },
  ],
  7: [
    { EN: mobileImg1EN, PT: mobileImg1PT, ES: mobileImg1ES },
    { EN: mobileImg2EN, PT: mobileImg2PT, ES: mobileImg2ES },
    { EN: mobileImg3EN, PT: mobileImg3PT, ES: mobileImg3ES },
    { EN: mobileImg4EN, PT: mobileImg4PT, ES: mobileImg4ES },
  ],
  8: [
    { EN: mobileImg1EN, PT: mobileImg1PT, ES: mobileImg1ES },
    { EN: mobileImg2EN, PT: mobileImg2PT, ES: mobileImg2ES },
    { EN: mobileImg3EN, PT: mobileImg3PT, ES: mobileImg3ES },
    { EN: mobileImg4EN, PT: mobileImg4PT, ES: mobileImg4ES },
  ],
  9: [
    { EN: mobileImg1EN, PT: mobileImg1PT, ES: mobileImg1ES },
    { EN: mobileImg2EN, PT: mobileImg2PT, ES: mobileImg2ES },
    { EN: mobileImg3EN, PT: mobileImg3PT, ES: mobileImg3ES },
    { EN: mobileImg4EN, PT: mobileImg4PT, ES: mobileImg4ES },
  ],
  10: [
    { EN: mobileImg1EN, PT: mobileImg1PT, ES: mobileImg1ES },
    { EN: mobileImg2EN, PT: mobileImg2PT, ES: mobileImg2ES },
    { EN: mobileImg3EN, PT: mobileImg3PT, ES: mobileImg3ES },
    { EN: mobileImg4EN, PT: mobileImg4PT, ES: mobileImg4ES },
  ],
  11: [
    { EN: mobileImg1EN, PT: mobileImg1PT, ES: mobileImg1ES },
    { EN: mobileImg2EN, PT: mobileImg2PT, ES: mobileImg2ES },
    { EN: mobileImg3EN, PT: mobileImg3PT, ES: mobileImg3ES },
    { EN: mobileImg4EN, PT: mobileImg4PT, ES: mobileImg4ES },
  ],
};
