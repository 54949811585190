import * as yup from 'yup'

const LINK_ACCOUNT_MODEL = {
    password: 'password',
}

const linkAccountValidationSchema = yup.object().shape({
    [LINK_ACCOUNT_MODEL.password]: yup
        .string()
        .required('validation:required_password')
})

export { LINK_ACCOUNT_MODEL, linkAccountValidationSchema as validationSchema }
