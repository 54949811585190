import { createUseStyles } from 'react-jss'
import {
    PlayButton,
} from '../../assets/icons/icons'
import Slider from 'react-slick'
import LightBox from '../../components/LightBox'
import Portal from '../../components/Portal'
import { useState } from 'react'

const useStyles = createUseStyles((theme) => ({
    slider: {
        width: '100%',
        alignSelf: 'start',
        display: 'flex',
        minWidth: '0px',
        justifyContent: 'center',
        height: '100%',
        borderRadius: '10px',
        zIndex: 3,
        opacity: ({ isGoalEnded }) => isGoalEnded && '0.8',
        filter: ({ isGoalEnded }) => isGoalEnded && 'grayscale(100%)',
        [theme.mUp]: {
            overflowY: 'unset',
            borderRadius: '15px',
        },
        '& *': {
            minHeight: '0px',
            minWidth: '0px',
        },
        '& .slick-list': {
            minHeight: '240px',
        },
        '& .slick-arrow': {
            position: 'absolute',
            zIndex: 11,
            height: '50px',
            width: '50px',
            '&:before': {
                content: '""',
                display: 'none',
            },
        },
        '& .slick-prev': {
            left: '8px',
        },
        '& .slick-next': {
            right: '8px',
        },
        '& .slick-dots': {
            position: 'relative',
            top: 0,
            zIndex: 50,
            height: '10px',
        },

        '& .slick-dots ul': {
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },

        '& .slick-dots li': {
            width: 'auto',
        },
        '& .slick-dots li button': {
            width: 'auto',
            padding: 3,
        },

        '& .slick-dots li button:before': {
            color: theme.colors.white,
            opacity: 1,
            width: 'auto',
        },

        '& .slick-dots li.slick-active button:before': {
            color: theme.colors.primary,
            opacity: 1,
            width: 'auto',
        },
    },
    slide: {
        position: 'relative',
        textAlign: 'center',
        display: 'flex!important',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        borderRadius: '10px',
        overflow: 'hidden',
        [theme.mUp]: {
            borderRadius: '15px',
        },
        '&::before': {
            content: '" "',
            position: 'absolute',
            borderRadius: 10,
            height: '100%',
            width: '100%',
            background:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))',
        },
        '& img': {
            width: '380px',
            height: '240px',
            objectFit: 'cover',
            [theme.mediaQueries.mobile.xxsUp]: {
                width: '396px',
            },
            [theme.mUp]: {
                width: '650px',
            },
            [theme.lUp]: {
                width: '868px',
            },
        },
        '& video': {
            width: '380px',
            height: '240px',
            objectFit: 'cover',
            [theme.mediaQueries.mobile.xxsUp]: {
                width: '396px',
            },
            [theme.mUp]: {
                width: '650px',
            },
            [theme.lUp]: {
                width: '868px',
            },
        },
    },
    playButton: {
        cursor: 'pointer',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1033,
    },
}))

const CoverMediaSlider = ({ media, isGoalEnded = false }) => {
    const classes = useStyles({ isGoalEnded })
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        beforeChange: (_, nextIndex) => {
            setCurrentIndex(nextIndex)
        },
        appendDots: (dots) => (
            <div>
                <ul style={{ margin: '0px', padding: '10px', zIndex: 10 }}>
                    {' '}
                    {dots}{' '}
                </ul>
            </div>
        ),
    }

    return (
        <div className={classes.slider}>
            <div />
            <Slider {...settings}>
                {media?.map(({ type, url }, index) => (
                    <>
                        <div
                            className={classes.slide}
                            onClick={() => setIsPreviewOpen(true)}
                            key={index}
                        >
                            {type === 'image' ? (
                                <div>
                                    <img src={url} />
                                </div>
                            ) : (
                                <>
                                    <video preload="metadata">
                                        <source src={`${url}#t=0.001`} />
                                    </video>
                                    <span className={classes.playButton}>
                                        <PlayButton />
                                    </span>
                                </>
                            )}
                        </div>
                    </>
                ))}
            </Slider>
            {isPreviewOpen && (
                <Portal>
                    <LightBox
                        media={media}
                        onClose={() => setIsPreviewOpen(false)}
                        currentIndex={currentIndex}
                        isMultipleTypesAutoplayVideo
                    />
                </Portal>
            )}
        </div>
    )
}

export default CoverMediaSlider
