import { createUseStyles } from 'react-jss'
import { Trans, useTranslation } from 'react-i18next'
import {getAvailableNamespaces} from "../../../translations";
import {useViewportSize} from "../../../hooks/useViewportSize";
import {LinkedFacebookAccountIcon, LinkedGoogleAccountIcon} from "../../../assets/icons/icons";
import Button from "../../../components/Button";
import {getAppLink, redirectToExternalLink, remapURLSearchParams} from "../../../utilities/utils";
import {APP_ROUTE_LOGIN, APP_ROUTE_SIGNUP_LEADER_SOCIAL} from "../../../utilities/constants/appRouteNames";
import {PARAM_GOAL, PARAM_ROOKIE_ID, PARAM_USER_GROUP} from "../../../utilities/constants";
import {getUserGroup} from "../../../utilities/constants/user";


const useStyles = createUseStyles((theme) => ({
    successfulLink: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing * 5,
        height: ({ vh }) =>
            `calc((${vh}px * 100) - ${theme.sizing.headerHeight})`,
        [theme.mUp]: {
            justifyContent: 'center',
            height: ({ vh }) =>
                `calc((${vh}px * 100) - ${theme.sizing.headerSmallUpHeight})`,
        },
    },
    content: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.mUp]: {
            height: 'fit-content',
        },
    },
    iconWrapper: {},
    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing * 2,
        textAlign: 'center',
        color: theme.colors.darkPurple,
        marginTop: '30px',
    },
    title: {
        fontSize: 22,
        fontWeight: 400,
        letterSpacing: -0.5,
        '& span': {
            textTransform: 'capitalize',
        },
    },
    subtitle: {
        fontSize: 14,
        '& span': {
            textTransform: 'capitalize',
        },
    },
    button: {
        width: '100%',
        marginBottom: '28px',
    },
}))

const SuccessfulLink = ({ platform }) => {
    const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false })
    const { vh } = useViewportSize()

    const classes = useStyles({ vh })
    return (
        <div className={classes.successfulLink}>
            <div className={classes.content}>
                <div className={classes.iconWrapper}>
                    {platform === 'facebook' ? (
                        <LinkedFacebookAccountIcon />
                    ) : (
                        <LinkedGoogleAccountIcon />
                    )}
                </div>
                <div className={classes.textWrapper}>
                    <div className={classes.title}>
                        <Trans
                            t={t}
                            i18nKey={'signup.link_accounts.on_success.title'}
                            values={{
                                PLATFORM: platform,
                            }}
                            components={{
                                span: <span />,
                            }}
                        />
                    </div>
                    <div className={classes.subtitle}>
                        <Trans
                            t={t}
                            i18nKey={
                                'signup.link_accounts.on_success.description'
                            }
                            values={{
                                PLATFORM: platform,
                            }}
                            components={{
                                span: <span />,
                            }}
                        />
                    </div>
                </div>
            </div>
            <Button
                data-primary
                className={classes.button}
                onClick={() =>
                    redirectToExternalLink(
                        getAppLink(APP_ROUTE_SIGNUP_LEADER_SOCIAL, [
                            ...remapURLSearchParams(location.search),
                            {
                                name: PARAM_USER_GROUP,
                                value: getUserGroup(),
                            },
                        ])
                    )}
            >
                Continue
            </Button>
        </div>
    )
}

export default SuccessfulLink
