import React, {useEffect} from 'react';


const Component = () => {
    const handleDownload = () => {
        const link = document.createElement('a');
        link.download = 'competition-waivers';
        link.href = '/competition-waivers.pdf';
        link.click();
    };

    const onClose = () => {
        window?.close();
    };

    useEffect(
        () => {
            handleDownload()
            setTimeout(() => {
                onClose()
            }, 500)
        },[]
    )

    return <></>
}

export default Component;
