import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { getAvailableNamespaces } from "../../translations";

const useStyles = createUseStyles((theme) => ({
  sectionWrapper: {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginRight: "7px",
    padding: "5px",
    borderRadius: "7px",
    color: theme.colors.white,
    background: theme.colors.purpleGradient,
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "15px",
    whiteSpace: "nowrap",
  },
  description: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "140%",
    color: theme.colors.darkPurpleMidOpacity,
  },
}));

const ForWhomGoalSection = ({ isForOthers, whoAreOthers }) => {
  const [t] = useTranslation(getAvailableNamespaces(), {
    react: { useSuspense: false },
  });

  const classes = useStyles({});

  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.label}>
        {isForOthers
          ? t("goal_page.for_whom_section.others")
          : t("goal_page.for_whom_section.myself")}
      </div>
      <div className={classes.description}>{whoAreOthers}</div>
    </div>
  );
};

export default ForWhomGoalSection;
