import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../translations";
import { MicromorgiIcon } from "../../assets/icons/icons";
import { calculatePercentage } from "../../utilities/helpers";
import CircularProgress from "../../components/CircularProgress";
import { strokeColor } from "../../utilities/constants/goalTypes";

const useStyles = createUseStyles((theme) => ({
  root: {
    display: "grid",
    margin: "0 auto",
    borderRadius: 10,
    boxShadow: "0px 4px 10px 0px rgba(0,0,0,0.15)",
    width: "316px",
    padding: "14px",
    backgroundColor: theme.colors.white,
    [theme.mUp]: {
      width: "450px",
    },
  },
  content: {
    display: "grid",
    alignItems: "center",
    gridColumnGap: theme.spacing * 2,
    gridTemplateColumns: "1fr auto",

  },
  title: {
    marginBottom: "5px",
    fontSize: "12px",
    fontWeight: 700,
    [theme.mUp]: {
      fontSize: "16px",
    },
  },
  raisedSection: {
    display: "grid",
    gridTemplateColumns: "auto auto 1fr",
    gridColumnGap: "5px",
    alignItems: "center",
    [theme.mUp]: {
      gridColumnGap: "10px",
      fontSize: "16px",
    },
  },
  icon: {
    height: "14px",
    width: "14px",
  },
  micromorgi: {
    fontSize: "12px",
    fontWeight: 700,
    [theme.mUp]: {
      fontSize: "16px",
    },
  },
  editTimeButton: {
    cursor: "pointer",
    color: theme.colors.primary,
    fontSize: "12px",
    fontWeight: 700,
    marginTop: "5px",
    width: "fit-content",
  },
  percentageClassName: {
    zIndex: "-1",
    left: "1px",
    bottom: "1px",
    fontWeight: 700,
    height: "95%",
    width: "95%",
    borderRadius: "50%",
    border: `1px solid ${theme.colors.lightGrey}`,
  },
  headerText: {
    color: theme.colors.white,
    fontWeight: 700,
    fontSize: "12px",
    cursor: "pointer",
  },
  progressClassName: {
    "&:after": {
      content: '""',
      position: "relative",
      display: "block",
      width: "2px",
      height: "6px",
      background: "#20BF55",
      borderRadius: "1px",
      top: "-100%",
      left: "50%",
    },
  },
  timerClassName: {
    display: "flex",
  },
  percentageZero: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: 60,
    height: 60,
    borderRadius: "50%",
    border: `1px solid rgb(0,0,0, 0.2)`,
    fontWeight: 700,
  },
}));

const GoalDetails = ({ goal = {}, goalStatus }) => {
  const { target_amount, donations_count } = goal;
  const classes = useStyles({});
  const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });

  const percentage = calculatePercentage(donations_count, target_amount);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div>
          <div className={classes.title}>{t("goal_page.details")}</div>
          <div className={classes.raisedSection}>
            <span>{t("goal_page.raised")}</span>
            <MicromorgiIcon className={classes.icon} />
            <span className={classes.micromorgi}>
              {+donations_count} / {+target_amount}
            </span>
          </div>
        </div>
        <div>
          {!percentage ? (
            <span className={classes.percentageZero}>
              <span>{percentage}%</span>
            </span>
          ) : (
            <CircularProgress
              percentage={percentage > 100 ? 100 : percentage}
              strokeColor={strokeColor[goalStatus]}
              percentageClassName={classes.percentageClassName}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(GoalDetails);
