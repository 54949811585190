import React from 'react';
import PropTypes from 'prop-types';
import {createUseStyles, useTheme} from 'react-jss';
import cx from "classnames";
import {toReactElement} from "../utilities/utils";
import {NavLinkWithQuery} from "./NavLinkWithQuery";

const useStyles = createUseStyles(theme => ({
	navLink: {
		display: 'grid',
		gridTemplateColumns: ({suffix}) => suffix ? `${theme.spacing * 4}px 1fr ${theme.spacing * 8}px` : `${theme.spacing * 4}px 1fr`,
		gridTemplateRows: theme.spacing * 4,
		gridColumnGap: theme.spacing / 2,
		padding: [theme.spacing * 2, 0, theme.spacing * 2, theme.spacing * 2],
		textDecoration: 'none',
		alignItems: 'center',
		fontWeight: 400,
		borderLeft: `3px solid transparent`,
		'&.active': {
			borderLeftColor: theme.colors.primary,
			backgroundColor: theme.colors.lowPurple
		},
		cursor: 'pointer'
	},
	title: {
		...theme.flexUtils.start,
		gridColumnStart: 2,
		height: '100%',
		'& > *': {
			maxHeight: '100%',
		}
	},
	icon: {
		...theme.flexUtils.centered
	},
	suffix: {
		gridColumnStart: 3,
		justifySelf: 'end'
	}
}));


const MenuItem = ({
					  customClassName,
					  children,
					  icon = null,
					  title = '',
					  to = null,
					  suffix = null,
					  onClick = null,
					  ...props
}) => {

	const theme = useTheme();
	const classes = useStyles({theme, suffix});

	return (
		<>
			{
				(children && <span className={cx(customClassName, classes.navLink)} {...props}>
					{icon && <span aria-hidden="true" className={classes.icon}>{toReactElement(icon)}️</span>}
					<span className={classes.title}>{children}</span>
				</span>)
				|| (to ?
					<NavLinkWithQuery exact className={cx(customClassName, classes.navLink)} onClick={onClick} to={to} {...props}>
						{icon && <span aria-hidden="true" className={classes.icon}>{toReactElement(icon)}️</span>}
						<span className={classes.title}>{title}</span>
						{suffix && <span className={classes.suffix}>{suffix}</span>}
					</NavLinkWithQuery>
					:
					<span className={cx(customClassName, classes.navLink)} onClick={onClick} {...props}>
					{icon && <span aria-hidden="true" className={classes.icon}>{toReactElement(icon)}️</span>}
						<span className={classes.title}>{title}</span>
						{suffix && <span className={classes.suffix}>{suffix}</span>}
				</span>)
			}
		</>
	);
};


MenuItem.propTypes = {
	className: PropTypes.string,
	suffix: PropTypes.any,
	icon: PropTypes.any,
	title: PropTypes.string,
	to: PropTypes.string,
	onClick: PropTypes.func
};

export default MenuItem;
