import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Popover from "../../../components/Popover";
import { getAvailableNamespaces } from "../../../translations";
import MorgisPackagesModal from '../MorgisPackagesModal/MorgisPackagesModal';
import SubscriptionsPlansModal from '../SubscriptionsPlansModal/SubscriptionsPlansModal';
import { MembershipPlans } from "./components/MembersipPlans/MembershipPlans";
import { MorgisPackage } from "./components/MorgisPackage/MorgisPackage";

const useStyles = createUseStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px'
  },
  modalRoot: {
    width: '394px',
  },
  modalBody: {
    padding: '0 24px 24px',
    maxWidth: '394px',
  },

  '@media (max-width: 768px)': {
    modalRoot: {
      width: '100%',
      maxHeight: '100vh',
      height: '100%',
      borderRadius: '0 !important',
    },
    root: {
      height: '100%'
    },
    modalBody: {
      margin: '0 auto',
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
    },
    packagesList: {
      maxHeight: '346px',
    },
    packagesListContainer: {
      flex: '1'
    },
  }
}))

export const ChooseSupportModal = ({ onClose, content, rookieProfile }) => {
  const classes = useStyles();
  const [t] = useTranslation(getAvailableNamespaces(), {
    react: { useSuspense: false },
  });
  const [showSubscriptionPlansModal, setShowSubscriptionPlansModal] = useState(false);
  const [showMorgiPackagesModal, setShowMorgiPackagesModal] = useState(false);
  return (
    <>
      {!showSubscriptionPlansModal && !showMorgiPackagesModal && <Popover
        className={classes.modalRoot}
        bodyClassName={classes.modalBody}
        title={t('rookie_profile_page.choose_support_modal.title')}
        withHeader
        onClose={onClose}
      >
        <MembershipPlans content={content} rookieName={rookieProfile.full_name}
          onSubmit={() => {
            setShowSubscriptionPlansModal(true);
          }} />
        <MorgisPackage content={content}
          onSubmit={() => {
            setShowMorgiPackagesModal(true);
          }} />
      </Popover>
      }

      {showSubscriptionPlansModal &&
        <SubscriptionsPlansModal
          rookieId={rookieProfile.id}
          hostName={rookieProfile.full_name}
          onClose={() => { setShowSubscriptionPlansModal(false) }} />
      }
      {showMorgiPackagesModal &&
        <MorgisPackagesModal
          onClose={() => { setShowMorgiPackagesModal(false) }} rookieId={rookieProfile.id} rookieName={rookieProfile.full_name} />
      }
    </>
  )
}