import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";
import ButtonGhost from "../../components/ButtonGhost";
import { useViewportSize } from "../../hooks/useViewportSize";
import { getAvailableNamespaces } from "../../translations";
import {
  APP_ROUTE_LOGIN_LEADER,
  APP_ROUTE_LOGIN_ROOKIE,
  APP_ROUTE_SIGNUP_LEADER,
  APP_ROUTE_SIGNUP_ROOKIE_EMAIL,
} from "../../utilities/constants/appRouteNames";
import {
  getAppLink,
  redirectToExternalLink,
  remapURLSearchParams,
} from "../../utilities/utils";
import { EmailIconButton } from "../../assets/icons/icons";
import Button from "../../components/Button";

const useStyles = createUseStyles((theme) => ({
  buttonsWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    maxWidth: "520px",
    marginBottom: "16px",

    "& > button": {
      borderRadius: "6px",
      width: "100%",
      maxWidth: "400px",
    },
  },
  linkContainer: {
    alignSelf: "center",
    color: theme.colors.darkPurpleMidOpacity,
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const AuthWithEmailCTA = ({ role }) => {
  const { vh, vw } = useViewportSize();
  const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });
  const location = useLocation();
  const screenSizeM = 768;
  const isDesktopSize = vw * 100 >= screenSizeM;
  const isRookie = role === "rookie";

  const renderLabelButtonGhost = useMemo(() => {
    return isDesktopSize
      ? "anonymous_tour.continue_with_email"
      : "anonymous_tour.leader_button_email";
  }, [isDesktopSize]);

  const handleRedirect = () => {
    redirectToExternalLink(
      isRookie
        ? getAppLink(APP_ROUTE_LOGIN_ROOKIE, [
            ...remapURLSearchParams(location?.search),
          ])
        : getAppLink(APP_ROUTE_LOGIN_LEADER, [
            ...remapURLSearchParams(location?.search),
          ])
    );
  };

  const classes = useStyles({ vh });

  return (
    <div className={classes.buttonsWrapper}>
      <ButtonGhost
        iconRight={<EmailIconButton />}
        callback={() =>
          redirectToExternalLink(
            isRookie
              ? getAppLink(APP_ROUTE_SIGNUP_ROOKIE_EMAIL, [
                  ...remapURLSearchParams(location?.search),
                ])
              : getAppLink(APP_ROUTE_SIGNUP_LEADER, [
                  ...remapURLSearchParams(location?.search),
                ])
          )
        }
        text={renderLabelButtonGhost}
        minWidth={isDesktopSize ? 230 : 108}
      />
      <div className={classes.linkContainer}>
          {
            <Trans
              t={t}
              i18nKey="common:login_link"
              components={{ span: <span className={classes.link} onClick={handleRedirect}/> }}
            />
          }
      </div>
    </div>
  );
};

export default AuthWithEmailCTA;
