import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Context from "../../Context";
import { httpFetchGoal } from "../../httpRequests/goals.http";
import { createUseStyles } from "react-jss";
import CoverPhoto from "./CoverPhoto";
import GoalDetail from "./GoalDetail";
import { getAvailableNamespaces } from "../../translations";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import goalImage from "../../assets/images/mockGoalImage/mockGoalImage.png";
import { GOAL_STATUSES } from "../../utilities/constants/goalTypes";
import { calculatePercentage } from "../../utilities/helpers";
import Spinner from "../../components/Spinner";
import {
  getAppLink,
  redirectToExternalLink,
  remapURLSearchParams,
} from "../../utilities/utils";
import { APP_ROUTE_SIGNUP_LEADER_SOCIAL } from "../../utilities/constants/appRouteNames";
import {
  PARAM_GOAL,
  PARAM_ROOKIE_ID,
  PARAM_ROOKIE_PROFILE,
  PARAM_USER_GROUP,
  ROUTE_HOME,
} from "../../utilities/constants";
import { getUserGroup } from "../../utilities/constants/user";
import { Helmet } from "react-helmet";
import { LinkWithQuery } from "../../components/LinkWithQuery";
import ForWhomGoalSection from "./ForWhomGoalSection";

const useStyles = createUseStyles((theme) => ({
  root: {
    position: "relative",
    height: "85vh",
    minHeight: ({ vh }) =>
      `calc((${vh}px * 100) - ${theme.sizing.headerHeight})`,
    paddingBottom: "120px",
    [theme.mUp]: {
      gridTemplateRows: "0fr 70px 0fr 0fr 0fr 0fr",
      minHeight: ({ vh }) =>
        `calc((${vh}px * 100) - ${theme.sizing.headerSmallUpHeight}`,
    },
  },
  content: {
    overflowY: "auto",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    padding: [0, theme.spacing * 2, theme.spacing * 2, theme.spacing * 2],
    alignItems: "center",
    [theme.mUp]: {
      gridTemplateRows: "0fr 70px 0fr 0fr 0fr 0fr",
    },
  },
  goalDetails: {
    position: "relative",
    top: "-90%",
  },
  description: {
    margin: "0 auto",
    width: "100%",
    [theme.mUp]: {
      width: "650px",
    },
  },
  descriptionTitle: {
    display: "block",
    fontWeight: 700,
    margin: "20px 0 15px 0",
    [theme.mUp]: {
      fontSize: "16px",
    },
  },
  descriptionText: {
    color: theme.colors.blackOpacityMid,
    width: "100%",
    wordBreak: "break-all",
    [theme.mUp]: {
      maxWidth: "650px",
      fontSize: "16px",
    },
  },
  button: {
    position: "absolute",
    bottom: 0,
    width: "80%",
    display: "flex",
    justifyContent: "center",
    justifySelf: "center",
    margin: "20px auto 10px auto",
    [theme.mUp]: {
      margin: "30px auto 20px auto",
    },
  },
  empty: {
    display: "flex",
    flexDirection: "column",
    padding: 16,
    height: "85vh",
  },
  titleWrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    fontSize: 36,
    ...theme.textGradients.primary,
    margin: "40px auto",
    //flexGrow: 1
    [theme.mUp]: {
      fontSize: 52,
    },
  },
  cta: {
    ...theme.mainCta,
    display: "flex",
    justifyContent: "center",
  },
  forWhom: {
    margin: "0 auto",
    width: "100%",
    [theme.mUp]: {
      width: "650px",
    },
  },
}));

const fetchComponentData = async ({ param: goalId }) => {
  return goalId ? httpFetchGoal(goalId) : null;
};

const GoalPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { search } = useLocation();
  const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });

  const context = useContext(Context);
  const [goal, setGoal] = useState(context?.componentData);
  const { slug } = useParams();
  const arrayFromSlug = slug.match(/.*-(\d+)/);
  const goalId = arrayFromSlug?.length > 1 ? arrayFromSlug[1] : "";

  const percentage = calculatePercentage(
    +goal?.donations_count,
    +goal?.target_amount
  );

  const fetchGoal = async () => {
    try {
      setIsLoading(true);
      const {data} = await fetchComponentData({ param: goalId });
      setGoal(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(goal)
  useEffect(() => {
    if (!goal || !!goal && !goal?.slug) {
      fetchGoal();
    } else {
      setIsLoading(false);
    }
  }, []);

  const computeIsGoalTimeEnded = useMemo(
    // TRUE - the goal time is ended (not active goal)
    // FALSE - the goal time is not ended (active goal)
    () => {
      const endGoalDate = new Date(goal?.end_date);
      const endDateDifMs = Date.now() - endGoalDate.getTime();
      return endDateDifMs > 0;
    },
    [goal?.end_date]
  );

  const getGoalStatus = useMemo(() => {
    switch (computeIsGoalTimeEnded) {
      case true:
        if (percentage >= 75) {
          return GOAL_STATUSES.success;
        } else {
          return GOAL_STATUSES.failure;
        }
      case false:
        if (percentage >= 75) {
          return GOAL_STATUSES.success;
        } else {
          return GOAL_STATUSES.default;
        }
    }
  }, [percentage, computeIsGoalTimeEnded]);

  const classes = useStyles({});
  return (
    <>
      <Helmet>
        {!!goal?.name && <title>{goal?.name} - Morgis.com</title>}

        {!!goal?.media?.length && (
          <meta property="og:image" content={`${goal?.media[0]?.url}`} />
        )}
        {!!goal?.name && goal?.rookie?.full_name && (
          <meta
            property="og:title"
            content={`${goal?.name} - ${goal?.rookie?.full_name} - Morgis.com`}
          />
        )}
        {!!goal?.name && goal?.rookie?.full_name && (
          <meta
            property="og:description"
            content={`Support ${goal?.rookie?.full_name} on their ${goal?.name} goal!`}
          />
        )}

        {!!goal?.media?.length && (
          <meta name="twitter:card" content="summary_large_image" />
        )}
        {!!goal?.name && goal?.rookie?.full_name && (
          <meta
            name="twitter:description"
            content={`Support ${goal?.rookie?.full_name} on their ${goal?.name} goal!`}
          />
        )}
        {!!goal?.media?.length && (
          <meta property="twitter:image" content={`${goal?.media[0]?.url}`} />
        )}
        {!!goal?.name && goal?.rookie?.full_name && (
          <meta
            property="twitter:title"
            content={`${goal?.name} - ${goal?.rookie?.full_name} - Morgis.com`}
          />
        )}
      </Helmet>

      <div className={classes.root}>
        {isLoading ? (
          <Spinner />
        ) : !goal ? (
          <div className={classes.empty}>
            <div className={classes.titleWrapper}>
              <div className={classes.title}>Goal not found!</div>
            </div>
            <div className={classes.cta}>
              <LinkWithQuery to={ROUTE_HOME} style={{ textDecoration: "none" }}>
                <Button data-primary width={"100%"}>
                  Go back to Homepage
                </Button>
              </LinkWithQuery>
            </div>
          </div>
        ) : (
          <div className={classes.content}>
            <CoverPhoto
              goal={goal}
              image={goalImage}
              computeIsGoalTimeEnded={computeIsGoalTimeEnded}
            />
            {/* goal details section */}
            <div className={classes.goalDetails}>
              <GoalDetail goal={goal} goalStatus={getGoalStatus} />
            </div>

            <div className={classes.forWhom}>
              <ForWhomGoalSection
                isForOthers={goal?.is_for_others}
                whoAreOthers={goal.radiobox}
              />
            </div>

            {/* description section */}
            <div className={classes.description}>
              <span className={classes.descriptionTitle}>
                {t("goal_page.description")}
              </span>
              <span className={classes.descriptionText}>{goal?.details}</span>
            </div>
            {/* button section */}
            <div
              className={classes.button}
              onClick={() =>
                redirectToExternalLink(
                  getAppLink(APP_ROUTE_SIGNUP_LEADER_SOCIAL, [
                    ...remapURLSearchParams(search),
                    {
                      name: PARAM_ROOKIE_ID,
                      value: goal.rookie_id,
                    },
                    {
                      name: PARAM_GOAL,
                      value: slug,
                    },
                    {
                      name: PARAM_USER_GROUP,
                      value: getUserGroup(),
                    },
                  ])
                )
              }
            >
              <Button data-primary width="100%">
                {t("goal_page.support_goal_btn")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GoalPage;

GoalPage.serverFetch = fetchComponentData;
