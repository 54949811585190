import defaultUnit from 'jss-plugin-default-unit';
// TODO: convert in JS
import 'bootstrap/dist/css/bootstrap-grid.css';
import "fontsource-inter/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

const spacing = 8;

const breakPoints = {
    xxs: 360,
    xs: 375,
    s: 576,
    m: 768,
    l: 992,
    xl: 1200,
    xxl: 1440,
    xxxl: 1536,
    xxxxl: 1920
};

const mobileBreakPoints = {
    xxxs: 250,
    xxs: 360,
    xs: 375,
    s: 384,
    m: 411,
};

const desktopBreakPoints = {
    xs: 1024,
    s: 1280,
    m: 1366,
    l: 1440,
    xl: 1536,
    xxl: 1920
};

const tabletBreakPoints = {
    xs: 600,
    s: 768,
    m: 810,
    l: 912,
    xl: 1024, // collide with desktop
    xxl: 1280 // collide with desktop
};

const mediaQueries = {
    mobile: {
        xxsUp: `@media only screen and (min-width: ${mobileBreakPoints.xxs}px)`,
        xxsUpH: `@media only screen and (min-width: ${mobileBreakPoints.xxs}px) and (min-height: 740px)`,
        xsUp: `@media only screen and (min-width: ${mobileBreakPoints.xs}px)`,
        xsUpH: `@media only screen and (min-width: ${mobileBreakPoints.xs}px) and (min-height: 740px)`,
        sUp: `@media only screen and (min-width: ${mobileBreakPoints.s}px)`,
        mUp: `@media only screen and (min-width: ${mobileBreakPoints.m}px)`,
        mUpH: `@media only screen and (min-width: ${mobileBreakPoints.m}px) and (min-height: 740px)`,
    },
    tablet: {
        xsUp: `@media only screen and (min-width: ${tabletBreakPoints.xs}px)`,
        sUp: `@media only screen and (min-width: ${tabletBreakPoints.s}px)`,
        mUp: `@media only screen and (min-width: ${tabletBreakPoints.m}px)`,
        lUp: `@media only screen and (min-width: ${tabletBreakPoints.l}px)`,
        xlUp: `@media only screen and (min-width: ${tabletBreakPoints.xl}px)`,
        xxlUp: `@media only screen and (min-width: ${tabletBreakPoints.xxl}px)`,
    },
    desktop: {
        xsUp: `@media only screen and (min-width: ${desktopBreakPoints.xs}px)`,
        sUp: `@media only screen and (min-width: ${desktopBreakPoints.s}px)`,
        mUp: `@media only screen and (min-width: ${desktopBreakPoints.m}px)`,
        lUp: `@media only screen and (min-width: ${desktopBreakPoints.l}px)`,
        xlUp: `@media only screen and (min-width: ${desktopBreakPoints.xl}px)`,
        xxlUp: `@media only screen and (min-width: ${desktopBreakPoints.xxl}px)`,
    },
}

const sUp = `@media only screen and (min-width: ${breakPoints.s}px)`;
const mUp = `@media only screen and (min-width: ${breakPoints.m}px)`;
const lUp = `@media only screen and (min-width: ${breakPoints.l}px)`;
const xlUp = `@media only screen and (min-width: ${breakPoints.xl}px)`;
const xxsUp = `@media only screen and (min-width: ${breakPoints.xxs}px)`;
const xxsUpH = `@media only screen and (min-width: ${breakPoints.xxs}px) and (min-height: 540px)`;
const xsUp = `@media only screen and (min-width: ${breakPoints.xs}px)`;
const xsUpH = `@media only screen and (min-width: ${breakPoints.xs}px) and (min-height: 600px)`;
const sUpM = `@media only screen and (min-width: 600px)`;
const xxlUp = `@media only screen and (min-width: ${breakPoints.xxl}px)`;
const xxxlUp = `@media only screen and (min-width: ${breakPoints.xxxl}px)`;
const xxxxlUp = `@media only screen and (min-width: ${breakPoints.xxxxl}px)`;
const s = `@media only screen and (max-width: ${breakPoints.s}px)`;
const m = `@media only screen and (max-width: ${breakPoints.m}px)`;
const l = `@media only screen and (max-width: ${breakPoints.l}px)`;
const lDown = `@media only screen and (max-width: ${breakPoints.l}px)`;


const sizing = {
    maxFormWidth: 420,
    headerHeight: '64px',
    headerSmallUpHeight: '111px',
    baseFontSize: 14
}

const colors = {
    body: '#fff',
    white: '#fff',
    black: '#000000',
    blackOpacity: 'rgb(0,0,0, 0.1)',
    blackOpacityMid: 'rgb(0,0,0, 0.5)',
    lightBlack: '#2C2C2C',
    darkGrey: '#737373',
    midGrey: '#80839E',
    grey: '#A4B0C0',
    iconGrey: '#394A64',
    lightGrey: '#E3E3E3',
    xLightGrey: '#DEE5EF',
    smokeWhite: '#efefef',
    overlay: 'rgba(23,29,41,0.72)',
    red: '#FF0000',
    failureAlert: '#B11B1B',
    failureAlertBg: '#FFF1F1',
    failureCloseAlert: '#DF3030',
    lightRed: '#f69f9f',
    green: '#6FCF97',
    notificationGreen: '#00D015',
    successAlert: '#00955F',
    successAlertBg: '#CFFFE2',
    successCloseAlert: '#00613E',
    greenChat: '#68EC92',
    greenLabel: '#96FFB3',
    yellow: '#FDBA0D',
    yellowInactive: '#FFDA48',
    yellowLight: '#FDCC0D',
    blue: '#4ac5ff',
    primary: '#8649D1',
    primaryAlertBg: '#F0E4FF',
    primaryCloseAlert: '#50109D',
    lowPurple: '#EFECFF',
    lowPurpleOpacity: 'rgba(239,236,255, 0.5)',
    inactivePurple: '#B99AD8',
    darkPurple: '#00063D',
    darkPurpleOpacity: 'rgba(0, 6, 61, 0.1)',
    darkPurpleMidOpacity: 'rgba(0, 6, 61, 0.5)',
    purpleGradient:
        'linear-gradient(0deg, #8649D1 0%, #8B6DE8 38.54%, #8F92FF 100%)',
    yellowGradient: 'linear-gradient(0deg, #FDCC0D 40.28%, #FFE2BA 88.19%)',
    transparent: 'transparent',
    warningRed: '#EB5757',
    darkWarningRed: '#701919',
}

const textGradients = {
    primary: {
        background: 'linear-gradient(to top, #8C6EE0 0%, #B39AFF 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        fallbacks: [
            {background: '-webkit-linear-gradient(to top, #8C6EE0 0%, #B39AFF 100%)'},
            {background: '-moz-linear-gradient(to top, #8C6EE0 0%, #B39AFF 100%)'},
            {background: '#8C6EE0'},
        ]
    }
}

const label = {
    position: 'relative',
    /*textTransform: 'capitalize',*/
    color: colors.black,
    fontWeight: '700',
    fontSize: 14,
    lineHeight: '1.8',
};

const input = {
    width: '100%',
    fontSize: 16,
    //lineHeight: 0,
    border: `1px solid`,
    borderColor: colors.xLightGrey,
    borderRadius: 4,
    boxSizing: 'border-box',
    position: 'relative',
    color: colors.darkPurple,
    backgroundColor: colors.white,
    padding: spacing * 2,
    outline: 'none',
    '&::placeholder': {
        color: colors.grey
    },
    '&:focus': {
        boxShadow: `0 0 0 2px ${colors.inactivePurple}`,
        borderColor: colors.primary,
        // 'background-color': 'transparent'
    },
    '&[readonly], &[disabled]': {
        cursor: 'default',
        border: `none`,
        backgroundColor: colors.lightGrey,
        color: colors.darkGrey
    }
};

const inputError = {
    borderColor: colors.red,
    '&:focus': {
        boxShadow: `0 0 0 2px ${colors.lightRed}`,
        borderColor: colors.red,
    },
};


const textarea = {
    ...input,
    resize: 'none',
    minHeight: 40,
    outline: 'none',
    lineHeight: 'auto'
};

const link = {
   color: colors.blackOpacityMid
}

const control = { label, input, textarea, inputError };

const card = {
    borderRadius: '4px',
    boxShadow: "0 2px 6px 0 rgba(191, 197, 210, 0.27)",
};

const defaultFontFamily = 'Inter';

const gridUtils = {

    centered : {
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center'
    },
    colFlow : {
        gridAutoFlow: 'column',
        gridColumnGap: spacing
    },
    rowFlow : {
        gridAutoFlow: 'row',
        gridRowGap: spacing
    },
    gapS: {
        gridColumnGap: spacing,
        gridRowGap: spacing
    },
    gapM: {
        gridColumnGap: spacing * 2,
        gridRowGap: spacing * 2
    },
    gapL: {
        gridColumnGap: spacing * 4,
        gridRowGap: spacing * 4
    }
}

const flexUtils = {
    centered : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    start : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    end : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    columnSpaceBetween: {
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between'
    }
}


const mainCta = {
    margin: [24, 0, 0,0],
    [s]: {
        /*position: 'sticky',
        bottom: 0,*/
        background: colors.white,
        padding: [spacing * 3, 0, spacing * 2, 0],
        borderTop: `1px solid ${colors.blackOpacity}`,
        margin: [8, 0, 0, 0]
    },
}

const formRootStyle = {
    position: 'relative',
    margin: 'auto',
    padding: [0, 16],
    maxWidth: sizing.maxFormWidth,
    minHeight: `calc(100vh - ${sizing.headerHeight})`,
    [s]: {
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between'
    }
}

const pageStyle = {
    titleWrapper: {
        padding: '24px 0',
        [sUp]: {
            padding: '36px 0'
        }
    },
    title: {
        textAlign: 'center',
        letterSpacing: '-0.05em',
        fontWeight: 500,
        margin: [spacing * 2, 0 ,spacing * 2 , 0],
        fontSize: 22,
        [mUp]: {
            fontSize: 36,
        },
    },
    subtitle: {
        textAlign: 'center',
        color: colors.darkGrey,
        fontSize: 14,
        margin: '0 auto 16px auto',
        [mUp]: {
            maxWidth: 400,
            fontSize: 16,
        },
    },
    mainCta: {
        borderTop: `1px solid ${colors.blackOpacity}`,
        /*position: 'sticky',
            bottom: 0,*/
        /*background: colors.white,*/
        padding: [spacing * 3, 0, spacing * 3, 0],
        margin: [8, 0, 0, 0],
        [sUp]: {
            borderTop: `none`,
            margin: [24, 0, 0, 0]
        }
    }
}

const bootstrapGridUtils = {
    h100: {
        height: `calc(100vh - ${sizing.headerHeight})`,
        [sUp]: {
            height: `calc(100vh - ${sizing.headerSmallUpHeight})`
        }
    },
    flexCol: {
        display: 'flex',
        flexDirection: 'column'
    },
    colGrow: {
        flex: '1 0 auto'
    },
    colShrink: {
        flex: '0 1 auto'
    }
}

const getAppTheme = (direction, customJssInstance, sheetsRegistry) => {

    customJssInstance.use(
        defaultUnit({
            'grid-column-gap': 'px',
            'grid-row-gap': 'px'
        })
    );

    const sheet = customJssInstance.createStyleSheet({
        '@global *': {
            'font-family': "'Inter'",
            'outline': 'none',
        },
        '@global html, body': {
            'font-family': "'Inter'",
            'font-size': sizing.baseFontSize,
            'color': colors.darkPurple,
            'background-color': colors.body,
            'line-height': 1.4,
            'margin': 0
        },
        '@global label, input, textarea, select, button': {
            'font-family': 'inherit',
        },
        '@global svg[viewBox="0 0 24 24"]': {
            'width': 24,
            'height': 'auto',
            'cursor': 'pointer',
        },
        '@global svg[viewBox="0 0 24 24"]:not([data-color])': {
            'fill': colors.iconGrey,
        },
        '@global h1, h2, h3, p, span, small': {
            'margin-bottom': 0,
        },
        '@global a[data-anchor-unstyled]': {
            'text-decoration': 'none',
        },
        '@global a': {
            color: colors.darkPurpleMidOpacity,
        },
        '@global a[data-button]': {
            'text-decoration': 'none',
        },
        '@global .text-truncate': {
            'text-overflow': 'ellipsis',
            'overflow': 'hidden',
            'white-space': 'nowrap',
        },
        '@global .overflow-hidden': {
            'overflow': 'hidden',
        },
        '@global .overflow-y-auto': {
            'overflow-y': 'auto',
        },
        '@global .d-flex': {
            'display': 'flex',
        },
        '@global .align-items-center': {
            'align-items': 'center',
        },
        '@global .justify-items-center': {
            'justify-items': 'center',
        },
        '@global .justify-content-center': {
            'justify-content': 'center',
        },
        '@global .container': {
            'padding': '0!important',
        },
        '@global hr': {
            'width': '100%',
            'border': 'none',
            'height': '1px',
            'background-color': '#E8E8E8',
            'margin': [spacing * 3, 0]
        },
        '@global label': {
            ...label
        }
    }, {index: 3, meta: 'globals', link: true}).attach();

    if (sheetsRegistry) {
        sheetsRegistry.add(sheet)
    }

    const carouselWrapperWidthIncrement = 112;
    const rookieCardContainerGaps = {
        xxxs: 30,
        xxs: 30,
        xs: 30,
        s: 30,
        m: 40,
        l: 40,
    }
    const rookieCardClosedRatio = 416 / 302;
    const rookieCardOpenRatio = 478 / 344;
    const rookieCardClosedWidths = {
        xxxs: 198,
        xxs: 240,
        xs: 262,
        s: 298,
        m: 344,
        l: 482,
    }

    // Not used actually
    const rookieCardOpenWidths = {
        xxxs: 288,
        xxs: 328,
        xs: 343,
        s: 382,
        m: 344,
        l: 482,
    }

    const generateRookieCardHeightFromWidth = (isOpen = false) => {
        const heights = {};
        const widths = isOpen ? rookieCardOpenWidths : rookieCardClosedWidths;
        const ratio = isOpen ? rookieCardOpenRatio : rookieCardClosedRatio;
        Object.keys(widths).forEach(size => {
            heights[size] = widths[size] * ratio
        })
        return heights
    }

    const rookieCardClosedProps = {
        ratio: rookieCardClosedRatio,
        width: rookieCardClosedWidths,
        height: generateRookieCardHeightFromWidth()
    }

    const rookieCardOpenProps = {
        ratio: rookieCardOpenRatio,
        width: rookieCardOpenWidths,
        height: generateRookieCardHeightFromWidth(true)
    }


    const generateRookieCardSizes = (isOpen = false, containerGap = false) => {
        const rookieCard = isOpen ? rookieCardOpenProps : rookieCardClosedProps;
        const sizes = {};
        Object.keys(rookieCardClosedWidths).forEach(size => {
            const gap = containerGap ? rookieCardContainerGaps[size] : 0
            sizes[size] = {
                width: rookieCard.width[size] ?? 198,
                height: rookieCard.height[size] + gap,
            }
        })
        return sizes
    }

    const rookieCardProps = {
        closed: {
            ...rookieCardClosedProps,
            sizes: generateRookieCardSizes(false),
            sizesWithGap: generateRookieCardSizes(false, true),
        },
        open: {
            ...rookieCardOpenProps,
            sizes: generateRookieCardSizes(true),
            sizesWithGap: generateRookieCardSizes(true, true),
        }
    }

    const rookieCardStyle = (gap = 0) => ({
        /** Mobile sizes */
        width: 216,
        height: 216 * rookieCardClosedRatio + gap,
        [mediaQueries.mobile.xxsUp]:{
            width: 264,
            height: 264 * rookieCardClosedRatio + gap,
        },
        [mediaQueries.mobile.xxsUpH]:{
            width: 294,
            height: 294 * rookieCardClosedRatio + gap,
        },
        [mediaQueries.mobile.xsUp]:{
            width: 220,
            height: 220 * rookieCardClosedRatio + gap,
        },
        [mediaQueries.mobile.xsUpH]:{
            width: 296,
            height: 296 * rookieCardClosedRatio + gap,
        },
        [mediaQueries.mobile.sUp]:{
            width: 300,
            height: 300 * rookieCardClosedRatio + gap,
        },
        [mediaQueries.mobile.mUp]:{
            width: 294,
            height: 294 * rookieCardClosedRatio + gap,
        },
        [mediaQueries.mobile.mUpH]:{
            width: 348,
            height: 348 * rookieCardClosedRatio + gap,
        },
        /** Tablet sizes */
        [mediaQueries.tablet.sUp]:{
            width: 348,
            height: 348 * rookieCardClosedRatio + gap,
        },
        [mediaQueries.tablet.lUp]:{
            width: 364,
            height: 364 * rookieCardClosedRatio + gap,
        },
        /** Desktop sizes */
        [mediaQueries.desktop.sUp]:{
            width: 264,
            height: 264 * rookieCardClosedRatio + gap,
        },
        [mediaQueries.desktop.lUp]: {
            width: 294,
            height: 294 * rookieCardClosedRatio + gap,
        },
        [mediaQueries.desktop.xlUp]: {
            width: 348,
            height: 348 * rookieCardClosedRatio + gap,
        },
        [mediaQueries.desktop.xxlUp]: {
            width: 480,
            height: 480 * rookieCardClosedRatio + gap,
        }
    })

    return ({
        rtl: direction,
        spacing,
        card,
        colors,
        control,
        s,
        m,
        l,
        xsUp,
        sUp,
        sUpM,
        mUp,
        lUp,
        xlUp,
        xxlUp,
        xxxlUp,
        gridUtils,
        flexUtils,
        mainCta,
        sizing,
        formRootStyle,
        link,
        breakPoints,
        textGradients,
        pageStyle,
        bootstrapGridUtils,
        rookieCardProps,
        mediaQueries,
        rookieCardStyle
    })
}

export default getAppTheme;
