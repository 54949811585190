import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import { createUseStyles } from "react-jss";
import { GOAL_STATUSES } from "../../../utilities/constants/goalTypes";
import { GoalsGradient } from "../../../assets/icons/icons";
import GoalCard from "../../../components/GoalCard";

const useStyles = createUseStyles((theme) => ({
  goalSection: {
    margin: [0, 0, 16, 0],
  },
  achievementsTitle: {
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "28px",
    margin: "16px 0 16px 0",
  },
  backgroundBanner: {
    padding: "16px",
    borderRadius: "14px",
    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
  },
  goalSectionTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
  },
  goalSectionTitleIcon: {
    margin: [0, "5px", 0, 0],
  },
}));

const RookieGoalSection = ({ rookieProfile }) => {
  const classes = useStyles();
  const [t] = useTranslation(getAvailableNamespaces(), {
    react: { useSuspense: false },
  });

  const {
    type_attributes: { goals },
    full_name,
  } = rookieProfile;

  const activeRookieGoals = goals.filter(
    (goal) => goal?.status === GOAL_STATUSES.active
  );

  return (
    <div className={classes.goalSection}>
      <h4 className={classes.achievementsTitle}>
        {t("rookie_profile_page.goal_section.title")}
      </h4>
      <div className={classes.backgroundBanner}>
        <div className={classes.goalSectionTitle}>
          <GoalsGradient className={classes.goalSectionTitleIcon} />
          {t("rookie_profile_page.goal_section.active_goals")}
        </div>
        {!(activeRookieGoals.length === 0) ? (
          <div>
            {activeRookieGoals.map((goal) => {
              return <GoalCard key={goal?.id} goal={goal} />;
            })}
          </div>
        ) : (
          <div>
            {t("goal_card.has_not_active_goals", {
              USER: full_name,
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default RookieGoalSection;
