export const calculatePercentage = (goalProgress, goalMicromorgiAmount) => {
  if (
    (typeof goalProgress === "number" &&
      typeof goalMicromorgiAmount === "number") ||
    !!goalProgress
  ) {
    return Math.floor((+goalProgress / +goalMicromorgiAmount) * 100);
  } else {
    return 0;
  }
};

export const handleApiError = ({
                                 error,
                                 callbackOnFieldError,
                                 callbackOnGeneralError,
                                 prependNameSpace,
                               }) => {
  if (error?.response?.data) {
    Object.keys(error.response.data).forEach((errorFieldName) => {
      if (errorFieldName === 'message') {
        const errorValue = error.response.data[errorFieldName]
        // Generic error
        callbackOnGeneralError(errorValue)
      } else {
        // Form field error
        const errorValue = prependNameSpace
            ? `${prependNameSpace}:${error.response.data[errorFieldName]}`
            : error.response.data[errorFieldName]
        callbackOnFieldError &&
        callbackOnFieldError(errorFieldName, {
          message: Array.isArray(errorValue)
              ? errorValue.join(' ')
              : errorValue,
          type: 'backend',
        })
      }
    })
  }
}

export const replaceThousands = (amount, withoutK = false) => {
  let number
  if (amount < 1000) {
      number = amount
  } else if (withoutK) {
      const string = String(amount)

      number =
          string.slice(0, string.length - 3) +
          ',' +
          string.slice(string.length - 3)
  } else {
      number = amount / 1000 + 'k'
  }
  return number
}


export const getMinMaxAmount = (array) => {
  if (!array.length) {
      return { min: null, max: null }
  }

  let min = array[0].amount
  let max = array[0].amount

  for (let i = 1; i < array.length; i++) {
      const amount = array[i].amount
      if (amount < min) {
          min = amount
      }
      if (amount > max) {
          max = amount
      }
  }

  return {
      minToShow: replaceThousands(min, true),
      maxToShow: replaceThousands(max, true),
      minValue: min,
      maxValue: max,
  }
}

export const amountToShow = (amountsArray, defaultArrey, isForGoal = false) => {
  const { minToShow, maxToShow, minValue, maxValue } =
      getMinMaxAmount(amountsArray)

  let minAmount = minValue
  let maxAmount = maxValue

  // only for goal support
  if (isForGoal) {
      const maxAmountIndex = defaultArrey?.findIndex(
          (item) => item?.amount === maxValue
      )
      // check if the next amount is the bigest amount
      if (maxAmountIndex !== defaultArrey?.length - 1) {
          const nextMaxAmount = defaultArrey[maxAmountIndex + 1].amount
          maxAmount = ` - ${nextMaxAmount - 1}`
      } else {
          minAmount = minAmount === maxAmount ? '' : `${minToShow} - `
          maxAmount = maxToShow + '+'
      }
  }
  if (!!minToShow) {
      const maxPart = amountsArray.length >= 2 ? ` - ${maxToShow}` : ''
      const stringToShow = isForGoal
          ? minAmount + maxAmount
          : minToShow + maxPart
      return stringToShow
  }
}