import React from 'react';
import {BackIcon} from "../assets/icons/icons";
import {useTranslation} from "react-i18next";
import {getAvailableNamespaces} from "../translations";
import {createUseStyles} from "react-jss";
import cx from 'classnames';
import {LinkWithQuery} from "./LinkWithQuery";

const useStyles = createUseStyles( theme => ({
    baseStyle: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: ({text}) => text ? 'auto 1fr' : '1fr',
        gridColumnGap: 8,
        cursor: 'pointer',
        textDecoration: 'none',
        padding: [0, 0, 0, 6]
    }
}))

const Back = ({
                  baseClass,
                  callback,
                  to,
                  text,
                  icon = <BackIcon/>
}) => {

    const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });
    const classes = useStyles({text});

    return callback ?
        <span onClick={callback}
              className={cx(classes.baseStyle, baseClass)}
        >
            {icon}
            {text && <span>{t(text)}</span>}
        </span>
        :
        <LinkWithQuery to={to}
              className={cx(classes.baseStyle, baseClass)}>
            {icon}
            {text && <span>{t(text)}</span>}
        </LinkWithQuery>

}

export default Back;