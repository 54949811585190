import React from "react";
import {createUseStyles} from "react-jss";
import {useTranslation} from "react-i18next";
import {getAvailableNamespaces} from "../translations";

const useStyles = createUseStyles((theme) => ({
    buttonGhost: ({height, disabled, iconRight, iconLeft, minWidth}) => ({
        display: "grid",
        gridTemplateColumns: iconLeft || iconRight ? "auto 1fr auto" : "1fr",
        textDecoration: "none",
        backgroundColor: "transparent",
        justifyContent: "center",
        alignItems: "center",
        height: height ?? 50,
        border: `1px solid ${theme.colors.darkPurple}`,
        borderRadius: "6px",
        padding: [0, theme.spacing * 2],
        cursor: disabled ? "not-allowed" : "pointer",
        color: theme.colors.darkPurple,
        minWidth: minWidth ?? 334,
        minHeight: 37,
        width: "100%",
    }),
    icon: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    text: ({iconRight, iconLeft}) => ({
        fontSize: 16,
        fontWeight: 600,
        textAlign: "center",
        whiteSpace: "nowrap",
        color: theme.colors.darkPurple,
        margin: iconLeft
            ? [0, 0, 0, theme.spacing]
            : iconRight
                ? [0, theme.spacing, 0, 0]
                : [0, 0, 0, 0],
    }),
    buttonLink: {
        // width: "100%",
        // height: "100%",
    },
}));

const ButtonGhost = ({
                         height,
                         iconRight = null,
                         iconLeft = null,
                         text,
                         callback,
                         type,
                         disabled,
                         href,
                         minWidth,
                         ...props
                     }) => {
    const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });

    const classes = useStyles({height, disabled, iconLeft, iconRight, minWidth});
    return (
        <button
            className={classes.buttonGhost}
            onClick={callback}
            type={type}
            disabled={disabled}
            href={href}
            {...props}
        >
            <div className={classes.icon}>
                {!!iconLeft && iconLeft}
            </div>
            <div className={classes.text}>
                {t(text)}
            </div>
            <div className={classes.icon}>
                {!!iconRight && iconRight}
            </div>
        </button>
    );
};

export default ButtonGhost;
