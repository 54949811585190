export const USER_GROUPS = {
    a: 1,
    b: 2 ,
    c: 3
}

export const getUserGroupsArr = () => Object.values(USER_GROUPS);
export const LOCAL_STORAGE_PUB_USER_GROUP = 'pubUserGroup';


export const setUserGroup = () => {
    if (typeof window !== 'undefined') {
        if(!localStorage.getItem(LOCAL_STORAGE_PUB_USER_GROUP)){
            const groups = getUserGroupsArr();
            const group = groups[Math.floor(Math.random() * groups.length)]
            localStorage.setItem(LOCAL_STORAGE_PUB_USER_GROUP, group)
        }
    }
}

export const getUserGroup = () =>
    typeof window !== 'undefined' ?
        +localStorage.getItem(LOCAL_STORAGE_PUB_USER_GROUP) :
        null;

export const KNOWN_COOKIES = {
    mainDomain: '.morgis.com',
    authCookie: 'access_token',
}
