import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { LINK_ACCOUNT_MODEL, validationSchema } from './linkAccountModel'
import { yupResolver } from '@hookform/resolvers/yup'
import SuccessfulLink from './SuccessfulLink/SuccessfulLink'
import {getAvailableNamespaces} from "../../translations";
import {httpAttachFacebookUser, httpAttachGoogleUser} from "../../httpRequests/user.http";
import {handleApiError} from "../../utilities/helpers";
import {CloseIcon, EyeClosedIcon, EyeOpenIcon} from "../../assets/icons/icons";
import Spinner from "../../components/Spinner";
import InputText from "../../components/InputText";
import ButtonGhost from "../../components/ButtonGhost";
import {useViewportSize} from "../../hooks/useViewportSize";
import {getAppLink, redirectToExternalLink, remapURLSearchParams} from "../../utilities/utils";
import {APP_ROUTE_SIGNUP_LEADER_SOCIAL} from "../../utilities/constants/appRouteNames";
import {PARAM_USER_GROUP} from "../../utilities/constants";
import {getUserGroup} from "../../utilities/constants/user";
import Alert from "../../components/Alert";

const useStyles = createUseStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        padding: [0, 16],
        maxWidth: theme.sizing.maxFormWidth,
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing,
        textAlign: 'center',
        margin: [16, 0],
        '& h2': {
            padding: 0,
            margin: 0,
        },
        [theme.mUp]: {
            margin: [32, 0],
        },
    },
    title: {
        ...theme.pageStyle.title,
        fontWeight: 400,
    },
    subtitle: {
        ...theme.pageStyle.subtitle,
    },
    forgot: {
        display: 'flex',
        justifyContent: 'end',
        textDecoration: 'underline',
        '& div': {
            cursor: 'pointer',
        },
    },
    link: {
        color: theme.colors.darkPurple,
    },
    cta: {
        ...theme.pageStyle.mainCta,
    },
    formContent: {
        padding: [0, 0, theme.spacing * 4, 0],
    },
    buttonsWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 14,
    },
    banner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: 400,
        minHeight: 48,
        marginTop: theme.spacing * 2,
        borderRadius: 4,
        background: '#CFFFE2',
        fontSize: 14,
        fontWeight: 600,
        color: '#00955F',
        padding: [0, theme.spacing * 2],
        '& svg': {
            cursor: 'pointer',
        },
    },
    alert: {
        position: 'fixed',
        top: 92,
        zIndex: 1000,
        left: '50%',
        transform: 'translateX(-50%)',
        minWidth: '96%',
        [theme.mUp]: {
            top: 160,
            minWidth: 900
        }
    }
}))

const LinkAccounts = () => {
    const { vh } = useViewportSize()
    const location = useLocation()
    const classes = useStyles({ vh })
    const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false })
    const [isLoading, setIsLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [resetPasswordBanner, setResetPasswordBanner] = useState(false)
    const [isSuccessfulLink, setIsSuccessfulLink] = useState(false)
    const [authData, setAuthData] = useState(null)
    const [error, setError] = useState(null)

    const platform = location?.state?.platform
    const email = location?.state?.email
    const userID = location?.state?.userID

    const {
        register,
        handleSubmit,
        control,
        formState: { touched },
        errors,
    } = useForm({
        mode: 'all',
        reValidateMode: 'all',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })

    const passwordField = useWatch({
        control,
        name: LINK_ACCOUNT_MODEL.password,
    })

    const onSubmit = async (values) => {
        const dataToSend = {
            ...values,
            ...location?.state?.data,
        }
        try {
            setIsLoading(true)
            const { data } =
                platform === 'facebook'
                    ? await httpAttachFacebookUser(dataToSend, userID)
                    : await httpAttachGoogleUser(dataToSend, userID)
            setAuthData(data)
            setIsSuccessfulLink(true)
        } catch (error) {
            handleApiError({
                error: error,
                callbackOnGeneralError: setError,
            })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={classes.root}>
            {isSuccessfulLink ? (
                <SuccessfulLink authData={authData} platform={platform} />
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.formContent}>
                        {resetPasswordBanner && (
                            <div className={classes.banner}>
                                {t('signup.link_accounts.on_reset_password', {
                                    EMAIL: email,
                                })}
                                <CloseIcon
                                    onClick={() =>
                                        setResetPasswordBanner(false)
                                    }
                                    fill={'#00955F'}
                                />
                            </div>
                        )}
                        {isLoading && <Spinner />}
                        <div className={classes.titleWrapper}>
                            <h2 className={classes.title}>
                                {t('signup.link_accounts.title')}
                            </h2>
                            <p className={classes.subtitle}>
                                {t('signup.link_accounts.description', {
                                    EMAIL: email,
                                    PLATFORM: platform
                                })}
                            </p>
                        </div>
                        <InputText
                            type={showPassword ? 'text' : 'password'}
                            label={`${t('common:password')}*`}
                            ref={register}
                            name={LINK_ACCOUNT_MODEL.password}
                            errors={errors[LINK_ACCOUNT_MODEL.password]}
                            touched={touched[LINK_ACCOUNT_MODEL.password]}
                            s
                            icon={
                                showPassword ? (
                                    <EyeOpenIcon />
                                ) : (
                                    <EyeClosedIcon />
                                )
                            }
                            iconCallback={() => setShowPassword(!showPassword)}
                        />
                        <div className={classes.forgot}>
                            <div onClick={() =>
                                redirectToExternalLink(
                                    getAppLink(APP_ROUTE_SIGNUP_LEADER_SOCIAL, [
                                        ...remapURLSearchParams(location.search),
                                        {
                                            name: PARAM_USER_GROUP,
                                            value: getUserGroup(),
                                        },
                                    ])
                                )}>
                                {t('signup.link_accounts.reset_password')}
                            </div>
                        </div>
                    </div>
                    {/*<DevTool control={control} />*/}
                    <ButtonGhost
                        type={'submit'}
                        text={'signup.link_accounts.action'}
                        disabled={isLoading || !passwordField}
                    />
                </form>
            )}
            {
                error &&
                <Alert
                    visible={error}
                    message={error}
                    dismissTimeout={30000}
                    dismissCallback={() => setError(null)}
                    className={classes.alert}
                />
            }
        </div>
    )
}

export default LinkAccounts
