import App from './App';
import {BrowserRouter,} from 'react-router-dom';
import React, { Suspense } from 'react';
import { hydrate } from 'react-dom';
import DirectionManager from "./containers/DirectionManager";
import './i18n';
import Context from "./Context";
import {configureStore} from "@reduxjs/toolkit";
import {Provider} from "react-redux";
import storeConfig from "./store/storeConfig";
import {asyncInitFacebookSdk} from "./services/facebook-sdk";
import {asyncInitGoogleClient} from "./services/google-identity-service";
import Spinner from './components/Spinner';

let value = {};
if (window && window.initialData) {
    value = window.initialData;
}

const store = configureStore( {
    preloadedState: window.storeData,
    ...storeConfig
});


const startApp = () => {
    hydrate(
        <Suspense fallback={<Spinner />}>
            <Provider store={store}>
                <DirectionManager>
                    <BrowserRouter>
                        <Context.Provider value={value}>
                            <App />
                        </Context.Provider>
                    </BrowserRouter>
                </DirectionManager>
            </Provider>
        </Suspense>
        ,
        document.getElementById('root'),
        () => {
            const ssStyles = document.getElementById('server-side-styles')
            if(ssStyles){
                ssStyles?.parentNode?.removeChild(ssStyles)
            }
        }
    );
}

const initAuthServices = async () => {
    const authServices = [asyncInitFacebookSdk(), asyncInitGoogleClient()]
    try {
        const results = await Promise.allSettled(authServices)
        startApp()
    } catch (err) {
        console.log('Cannot load auth services', err)
    }
}

initAuthServices()

if (module.hot) {
  module.hot.accept();
}
