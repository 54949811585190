import googlePayLogo from '../../assets/images/google_pay_logo.png'
import pixPayLogo from '../../assets/images/pix-pay-logo.png'
import applePayIcon from '../../assets/images/applePay.png'
import billingsMethodsIcon from '../../assets/images/billingsMethods.png'

export const checkIfIncluded = (key, condition) => {
    const value = key.toLowerCase().includes(condition)
    return value
}
export const SocialPlatformType = {
    GOOGLE: 'google',
    PIX: 'pix',
    APPLE: 'apple',
    BOLETO: 'boleto',
    DIRECT_24_PAY_WALL: 'directa24paywall',
    CREDIT_CARD: 'credit_card'
}
export const CreditCardsName = {
    PRAXIS_CREDIT_CARD: 'praxis_credit_card',
    CCBIL_CREDIT_CARD: 'ccbill_credit_card',
    PRAXIS_GENERIC: 'praxis_generic'
}

//filter an Object, return links without 'praxis_credit_card', 'ccbill_credit_card';
// remap and renamed keys according constants SocialPlatformType
const isAPMLink = ([key, value]) => {
    if (key !== CreditCardsName.CCBIL_CREDIT_CARD)
        return { key: key, value: value }
}

export const remapAndFilterAPMLinks = (paymentMethodUrls = {}) => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document);

    const mappedPaymentMethods = Object.entries(paymentMethodUrls)
        .filter(isAPMLink)
        .map(([key, value]) => {
            switch (true) {
                case checkIfIncluded(key, SocialPlatformType.GOOGLE):
                    return !isIOS ? {
                        name: SocialPlatformType.GOOGLE, url: value,
                        icon: googlePayLogo
                    } : null
                case checkIfIncluded(key, SocialPlatformType.PIX):
                case checkIfIncluded(key, SocialPlatformType.DIRECT_24_PAY_WALL):
                    return {
                        name: SocialPlatformType.PIX, url: value,
                        icon: pixPayLogo

                    }
                case checkIfIncluded(key, SocialPlatformType.APPLE) && isIOS:
                    return {
                        name: SocialPlatformType.APPLE, url: value,
                        icon: applePayIcon
                    }
                case checkIfIncluded(key, SocialPlatformType.CREDIT_CARD):
                    return {
                        name: SocialPlatformType.CREDIT_CARD, url: value,
                        icon: applePayIcon
                    }
                default:
                    return {
                        name: key.split(' - ')[2], url: value,
                        icon: billingsMethodsIcon
                    }
            }
        })
        .filter(Boolean) // Type guard to filter out null values

        const creditCardEntry = mappedPaymentMethods.find(method => method?.name === SocialPlatformType.CREDIT_CARD);
        const filteredPaymentMethods = mappedPaymentMethods.filter(method => method?.name !== SocialPlatformType.CREDIT_CARD);

        // Add the CREDIT_CARD entry back to the beginning if it exists
        if (creditCardEntry) {
            filteredPaymentMethods.unshift(creditCardEntry)
            return filteredPaymentMethods;
        } else {
            return  mappedPaymentMethods
        }

}