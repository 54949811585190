import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import PlanOfSubscription from '../PlanOfSubscription/PlanOfSubscription';
import Spinner from '../../../../../components/Spinner';
import { getAvailableNamespaces } from '../../../../../translations';
import axios from '../../../../../utilities/axios';




// Styles for PlansList
const useStyles = createUseStyles({
    plansListHeader: {
        maxWidth: '252px',
        margin: '0 auto',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& span': {
            fontSize: '1rem',
            color: '#6a0dad',
            textAlign: 'center',
            fontWeight: '700',
            lineHeight: '24px'
        }
    },
    plansList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'stretch',
        overflowX: 'hidden',
        overflowY: 'auto',
        paddingBottom: '8px',
        "& > div": {
            borderBottom: '0.5px solid rgba(0, 6, 61, 0.45)',
            padding: '16px 8px',
        },
        '@media (min-width: 768px)': {
            height: '444px'
        }
    }
});

const PlansList = ({
    selectedPlan,
    onSelectPlanHandler,
    renderMegaphoneText,
    disabledByMinAmount = 0,
    columnNames = ['gift_rookie.price', 'gift_rookie.what_you_get'],
    rookieId
}) => {
    const classes = useStyles();
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const [packagesList, setPackagesList] = useState([])

    useEffect(() => {
        const fetch = async () => {
            if (rookieId) {
                const { data } = await axios.get(`/subscriptions/rookies/${rookieId}/packages`)
                setPackagesList(data)
            }
        }
        fetch()
    }, [rookieId])


    if (!packagesList?.length) return <Spinner />;
    const currencySymbol = '$'
    // remapCurrency[user?.currency || 'USD'];

    return (
        <>
            <div className={classes.plansListHeader}>
                {columnNames.map(name => (
                    <span key={name}>{t(name)}</span>
                ))}
            </div>
            <div className={classes.plansList}>
                {packagesList.map(plan => (
                    <PlanOfSubscription
                        key={plan.id}
                        plan={plan}
                        isSelected={plan.id === selectedPlan?.id}
                        onSelectPlanHandler={() => onSelectPlanHandler(plan)}
                        currencySymbol={currencySymbol}
                        renderMegaphoneText={renderMegaphoneText}
                        disabled={!!disabledByMinAmount && disabledByMinAmount > plan.amount}
                    />
                ))}
            </div>
        </>
    );
};

export default PlansList;
