import en from "./en_US"
import es_419 from "./es_419";
import bn from "./bn";
import de from "./de";
import el from "./el";
import es from "./es";
import gu_IN from "./gu_IN";
import fr from "./fr";
import hi from "./hi";
import it from "./it";
import ja from "./ja";
import ko from "./ko";
import ms from "./ms";
import no from "./no";
import pt_BR from "./pt_BR";
import ro from "./ro";
import ru from "./ru";
import th from "./th";
import tl_PH from "./tl_PH";
import tr from "./tr";
import uk from "./uk";
import zh_CN from "./zh_CN";
/*
*
* To add new language:
* 1. Create language directory - "en_US"
* 2. Clone index.js from another language directory inside the language directory
* 3. Add <namespace>.json files inside the language directory - "validation|common|pages.json"
* 4. Import the new language here in "resources"
*
*/

export const getAvailableNamespaces = (requestedNamespace) => {
    const namespaces = ['pages', 'validation', 'common'];
    const filteredNamespaces = Array.isArray(requestedNamespace) && namespaces.filter( namespace => requestedNamespace.includes(namespace))
    return filteredNamespaces.length > 0 ?
        filteredNamespaces :
        namespaces
}

const resources = {
    "en_US": en,
    "es_419": es_419,
    "pt_BR": pt_BR,
};

export default resources;
